import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/PrimaryButton";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    message,
} from "antd";

// Redux Actions
import {
    edit_device,
    clear_selected_dvid
} from "../../../services/redux/actions/devices";

const FormItem = Form.Item;

class EditDevice extends Component {
    state = {
        simCard: "+6",
        simSerial: "",
        deviceImei: "",
    }

    submitForm = () => {
        const {
            simCard,
            simSerial,
            deviceImei
        } = this.state

        try {

            if (!simCard) throw new Error(`Mobile Number cannot be blank`)


            const newDevice = {
                dvid: this.props.devices.selectedDVID,
                imei: deviceImei,
                simCard: simCard,
                simSerial: simSerial,
            }

            console.log({ newDevice })

            this.props.dispatch(edit_device(newDevice));

        } catch (err) {
            message.error(err.message || 'Something went wrong')
        }
    }

    componentDidMount = () => {
        this.setupFormDefaultValue()
    }

    setupFormDefaultValue = () => {

        const { devices } = this.props;

        if (devices.selectedDVID) {
            const selectedDevice = devices.byDVID[devices.selectedDVID];

            this.setState({
                deviceImei: selectedDevice.dvid.split("#")[1],
                simCard: selectedDevice.simCard,
                simSerial: selectedDevice.simSerial,
            })
        }
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props.devices) !== JSON.stringify(prevProps.devices)) {
            this.setupFormDefaultValue()
        }
    }

    componentWillUnmount = () => {
        this.props.dispatch(clear_selected_dvid());
    }

    render() {
        return (
            <div className="page-container">
                <Page title="Edit Device">
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                        <Form className="login-form">
                            <FormItem
                                label="Device Imei"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="IMEI Series No."
                                    value={this.state.deviceImei}
                                    disabled={true}
                                />
                            </FormItem>

                            <FormItem
                                label="Mobile Number"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="+60123456789"
                                    value={this.state.simCard}
                                    onChange={e => {
                                        if (e.target.value.length < 2) {
                                            this.setState({
                                                ...this.state,
                                                simCard: "+6",
                                            })
                                        }
                                        else {
                                            this.setState({
                                                ...this.state,
                                                simCard: e.target.value,
                                            })
                                        }
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                label=' Sim Serial Number'
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder='Enter device sim card serial number (optional)'
                                    value={this.state.simSerial}
                                    onChange={e =>
                                        this.setState({ simSerial: e.target.value })
                                    }
                                />
                            </FormItem>

                            <div style={{ position: "absolute", right: "100px" }}>
                                <Button
                                    type="danger"
                                    className="login-form-button"
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading={this.props.style.isLoadingSubmit}
                                    onClick={this.submitForm}
                                >
                                    Save
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.style,
    devices: state.devices,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(EditDevice));