import * as API from "../../api/assignmentLogs";
import * as ActionTypes from "../action-types/assignmentLogs";

import { message } from 'antd';
import {
    setLoadingSubmit,
    unsetLoadingSubmit,
} from "./style";

export const get_assignment_log = (vids = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.getAssignmentLog(vids, startTime, endTime)
    .then(data => {
        // console.log("Assignment Log Data:", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error('Failed To Get Assignment Log: ' + data.message);
            }
        }
        else {

            if (data.assignmentLogs.length < 1) {
                message.warning("No Logs Found Within This Timeframe.");
            }else{
                message.info('Successfully Retrieved Assignment Log.');
            }

            dispatch(get_assignment_log_success(data.assignmentLogs));
        }

        dispatch(unsetLoadingSubmit());
    })
}

const get_assignment_log_success = (assignmentLogs) => {
    return {
        type: ActionTypes.GET_ASSIGNMENT_LOG,
        assignmentLogs: assignmentLogs
    }
}

export const clear_assignment_log = () => {
    return {
        type: ActionTypes.CLEAR_ASSIGNMENT_LOG
    }
}