import * as ActionTypes from "../action-types/geofenceTemplates";

const defaultState = {
    byGTID: {},
    byName: {},
}

export const geofenceTemplates = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_GEOFENCE_TEMPLATES: {
            newState.byGTID = JSON.parse(JSON.stringify(defaultState.byGTID)); // Clear newState byGTID
            
            action.geofenceTemplates.forEach((currGT) => {
                newState.byGTID[currGT.gtid] = currGT
                newState.byName[currGT.templateName] = currGT
            });

            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Geofence Template Store:", defaultState);

            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}