import {
    closeWS,
    wsURL,
    wsClient,
    wsReconnectInterval
} from './config'

let wsc;

const wsContainer = (
    cb = () => {}
) => {

    if(!wsc || wsc.readyState > 1 ) wsc = new WebSocket(wsURL);

    // console.log(`[WebSoc] wsURL`, wsURL)

    wsClient(
        wsc,
        (data, timer) => {

            if(data === 'reconnect') {
                // console.log("current session state", wsc.readyState)
                setTimeout(wsContainer, wsReconnectInterval);
            }
        },
        (err , data) => {
            // console.log("[WebSoc]", data)
            cb(err, data)
        }
    )
}

const subscribeWS = (ids, uid) => {

    const subscribe_msg = {
        action:"subscribeTo",
        data: {
           ids: ids, 
           uid: uid
        }
    }
  
    window.subscribe_msg = subscribe_msg
    // console.log("websocket state", client.readyState)
  
    if(wsc.readyState === 0) {
  
        const retry = setInterval(
            () =>{
                if(wsc.readyState !== 0){
                    // console.log("Sending subscribe message in timeout",subscribe_msg);
                    wsc.send(JSON.stringify(subscribe_msg));
                    clearInterval(retry)
                }
            },
            3000
        )

    } else {
        wsc.send(JSON.stringify(subscribe_msg))
    }
  
}

export {
    closeWS,
    subscribeWS,
    wsContainer
}