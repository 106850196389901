import 
    React, 
    { 
        useState,
        useEffect,
        useRef
    } 
from "react";
import {
    useDispatch,
    useSelector
} from "react-redux";

import moment from "moment";

import { moveToPage } from "../../../navigation/navigationService";
import { EmptyIndicator } from '../../../components/EmptyIndicator';
import { LoadingIndicator } from "../../../components/custom-components/LoadingIndicator";
import { 
    DEFAULT_GEOFENCE_HEXCODE,
} from "../../../constants";
import {
    Col,
    List,
    Input,
    Button,
    // message,
    Collapse,
} from "antd";
import {
    LeftOutlined,
    EditOutlined,
} from '@ant-design/icons'

// Redux Actions
import {
    set_map_control,
    set_new_geofence_from_map,
} from '../../../services/redux/actions/mapControl';
import {
    // add_geofence,
    set_selected_geofence_geoid,
    clear_selected_geofence_geoid,
} from "../../../services/redux/actions/geofences";

import "./index.css";
import "../../../App.css";

const { Panel } = Collapse;

const GeofenceList = (props) => {

    const dispatch = useDispatch();

    // const user = useSelector(state => state.user)
    const style = useSelector(state => state.style)
    const geofences = useSelector(state => state.geofences)
    const geofenceTemplates = useSelector(state => state.geofenceTemplates)

    // const [file, setFile] = useState(null)
    const [filterString, setFilterString] = useState('')
    const setDataSourceObj = useState({})[1]

    const showExtraContent = useState(false)[0]

    const inputOnChange = (e) => setFilterString(e.target.value)

    const returnLatestGeofenceTemplateColorForGeoID = (geoID) => {
        let latestTemplate = {};

        Object.values(geofenceTemplates.byGTID)
            .filter((currTemplate) => currTemplate.geofences.includes(geoID)) // Only check geofence templates containing this geofence ID
            .forEach((currTemplate) => {
                if (!latestTemplate.createdAt || currTemplate.createdAt > latestTemplate.createdAt) {
                    latestTemplate = currTemplate;
                }
            })

        // console.log("Color:", latestTemplate.colorHexCode);

        return latestTemplate.colorHexCode ? latestTemplate.colorHexCode : DEFAULT_GEOFENCE_HEXCODE;
    }

    const setupDataSource = () => {

        let newDataSourceObj = {};

        const gtidList =
            Object.keys(geofenceTemplates.byGTID)
                .filter((currGeoID) => Object.keys(geofences.byGEOID).includes(currGeoID))
                .sort((a, b) => {
                    const vA = geofenceTemplates.byGEOID[a].templateName.toLowerCase();
                    const vB = geofenceTemplates.byGEOID[b].templateName.toLowerCase();

                    if (vA === "DEFAULT") {
                        return -1;
                    }
                    else if (vB === "DEFAULT") {
                        return 1;
                    }
                    else {
                        if (vA < vB) return -1;
                        if (vA > vB) return 1;
                    }

                    return 0;
                });

        gtidList.forEach((currGTID) => {
            newDataSourceObj[currGTID] = geofenceTemplates.byGTID[currGTID].geofences;

            newDataSourceObj[currGTID].sort((a, b) => {
                const vA = geofences.byGEOID[a].geofenceName.toLowerCase();
                const vB = geofences.byGEOID[b].geofenceName.toLowerCase();

                if (vA < vB) return -1;
                if (vA > vB) return 1;
                return 0;
            })
        });

        setDataSourceObj(newDataSourceObj)
    }

    const returnGeofenceTemplateCollapse = (currGT) => {

        // console.log("Current GT:", currGT);

        const params_geofences = currGT.geofences
            .map((geoid) => geofences.byGEOID[geoid])
            .filter((currGeofence) => currGeofence && (!filterString || currGeofence.geofenceName.toLowerCase().includes(filterString.toLowerCase())))
            .sort((a, b) => {
                const gA = a.geofenceName.toLowerCase();
                const gB = b.geofenceName.toLowerCase();
        
                if (gA < gB) return -1;
                if (gA > gB) return 1;
                
                return 0;
            })
            
        // console.log("Geofences:", geofences);

        return (
            <Panel key = {currGT.gtid} header = {<b>{currGT.templateName}</b>}>
                <List
                    style = {{ overflowY: "auto" }}
                    dataSource = {params_geofences}
                    renderItem = {(geofence) => {
                        return (
                            <List.Item
                                className = "geofence-list-item"
                                actions = {[
                                    <EditOutlined 
                                        onClick = {() => {
                                            dispatch(set_selected_geofence_geoid(geofence));
                                            dispatch(set_new_geofence_from_map(geofence));
                                            dispatch(set_map_control(geofence));

                                            switch (geofence.geoType) {
                                                case 'POI':
                                                    props.editPOI(geofence)
                                                    break
                                                case 'Polygon':
                                                    props.editPolygon(geofence)
                                                    break
                                                default:
                                                    break
                                            }
                                        }}
                                    />
                                ]}
                            >
                                <div
                                    className = "geofence-list-item-content-container"
                                    style = {{
                                        alignItems: showExtraContent ? "flex-start" : "center",
                                    }}
                                    onClick = {() => {
                                        dispatch(set_selected_geofence_geoid(geofence.geoid));
                                        dispatch(set_map_control(geofence.geoid));
                                    }}
                                >
                                    <div style = {{ display: 'flex', flexDirection: 'column' }}>
                                        <div 
                                            style = {{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Col
                                                span = {7}
                                                className = "POI"
                                                style = {{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",

                                                    backgroundColor: returnLatestGeofenceTemplateColorForGeoID(geofence.geoid)
                                                }}
                                            >
                                                {geofence.geofenceType}
                                            </Col>

                                            <Col span = {17}>
                                                <b style = {{ marginLeft: 5, color: "black" }}>
                                                    {geofence.geofenceName}
                                                </b>
                                            </Col>
                                        </div>

                                        <div className = "datetime">
                                            Created on {moment(geofence.createdAt).format("YYYY/MM/DD")}
                                        </div>

                                        <div className = "datetime">
                                            Remark: {geofence.geofenceComment && geofence.geofenceComment.replace(" ", "") ? geofence.geofenceComment : "-"}
                                        </div>
                                    </div>
                                </div>
                            </List.Item>
                        );
                    }}
                />
            </Panel>
        )
    }

    const mounted = useRef();

    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevGeofenceProps = usePrevious(geofences);
    const prevGeofenceTemplateProps = usePrevious(geofenceTemplates);

    useEffect(() => {
        if (!mounted.current) {
            /**
             * componentDidMount
             */
            mounted.current = true;
    
            setupDataSource()
        } else {
            /**
             * componentDidUpdate
             */
            if(
                JSON.stringify(prevGeofenceProps) !== JSON.stringify(geofences)
                ||
                JSON.stringify(prevGeofenceTemplateProps) !== JSON.stringify(geofenceTemplates)
            ) {
                setupDataSource();
            }
        }
    });

    const defaultGeofenceTemplate = geofenceTemplates.byName["DEFAULT"];

    return (
        <div className = "page-container">
            <div
                className = "page-header"
                style = {{
                    color: '#FFFFFF',
                    backgroundColor: style.pageHeaderColor,
                }}
            >
                <div className = "page-header-contents">
                    {/* back to dashboard */}
                    <button
                        className = "transparent-button"
                        onClick = {() => dispatch(moveToPage("/"))}
                    >
                        <LeftOutlined />
                    </button>

                    <div>Geofence Management</div>
                </div>
            </div>

            {/* Search Header */}
            <div
                className = "user-input"
                style = {{ padding: 20 }}
            >
                <Input
                    placeholder = "Search Geofence"
                    value = {filterString}
                    onChange = {(e) => {
                        // console.log("New Filter String:", e.target.value);

                        inputOnChange(e)
                    }}
                />

                <div style = {{ display: 'flex', flexDirection: 'row' }}>
                    <div style = {{ flex: 1 }}>
                        <Button
                            className = "button"
                            onClick = {() => {
                                dispatch(clear_selected_geofence_geoid());
                                props.createPOI();
                            }}
                        >
                            + POI
                        </Button>

                        <Button
                            className = "button"
                            onClick = {() => {
                                dispatch(clear_selected_geofence_geoid());
                                props.createPolygon();
                            }}
                        >
                            + Polygon
                        </Button>
                    </div>

                    {/* <div>
                        <Button
                            style = {{ marginTop: 10 }}
                            onClick = {() => dispatch(moveToPage(`/Geofence/AddGroup`))}
                        >
                            + Add Group
                        </Button>
                    </div> */}
                </div>
            </div>

            <div
                style = {{
                    backgroundColor: style.pageHeaderColor,
                    color: '#FFFFFF',
                    padding: "5px 0px 5px 10px"
                }}
            >
                Geofence List
            </div>

            {
                style.isLoadingSubmit ?
                    <LoadingIndicator /> :
                    !(Object.keys(geofenceTemplates.byGTID).length && defaultGeofenceTemplate.gtid) ?
                        <EmptyIndicator /> :
                        <Collapse defaultActiveKey = {defaultGeofenceTemplate && defaultGeofenceTemplate.gtid}>
                            {returnGeofenceTemplateCollapse(defaultGeofenceTemplate)}

                            {
                                Object.values(geofenceTemplates.byGTID)
                                    .filter((currGT) => currGT.gtid !== defaultGeofenceTemplate.gtid) // Remove default from this list as it's already an option
                                    .sort((a, b) => {
                                        const gA = a.templateName;
                                        const gB = b.templateName;
                                
                                        if (gA < gB) return -1;
                                        if (gA > gB) return 1;
                                        
                                        return 0;
                                    })
                                    .map((currGT) => returnGeofenceTemplateCollapse(currGT))
                            }
                        </Collapse>
            }
        </div>
    )
};

export default GeofenceList;
