import * as ActionTypes from "../action-types/mapControl";
import { 
    LOCATION,
    VEHICLE_STATUS,
    DISPLAY_RULE_TYPE 
} from "../../../constants";

const defaultState = {
    // toggleControl: {
    //     // info window
    //     showAllVehiclesInfoWindow: true,
    //     showAllGeofencesInfoWindow: true,

    //     // all geofence stuffs
    //     showAllGeofence: true,

    //     // not used for now
    //     showLiveTraffic: false,
    //     showVehicleTrail: true,
    // },

    polygonOnMapList: [], // To clear out polygons that are drawn by react-google-map's drawing manager

    geofence: {}, 
    poiCoded: null, /* */
    location: LOCATION, /* */
    zoom: 7.5, /* */
    selectedGeofenceTemplates: [],

    geofenceFilterString: "",

    selectedEVID: "",
    selectedTrID: "",
    selectedGEOID: "",
    playbackMarkerLocation: null,

    userZoom: null,
    userCenter: null,

    uiControl: {
        // Filter stuff
        durationMin: 0,
        selectedEventTypes: [
            DISPLAY_RULE_TYPE[0], 
            DISPLAY_RULE_TYPE[1], 
            DISPLAY_RULE_TYPE[2]
        ],
        selectedTransitTypes: [
            VEHICLE_STATUS.MOVING, 
            VEHICLE_STATUS.IDLING, 
            VEHICLE_STATUS.PARKING, 
            VEHICLE_STATUS.DISCONNECTED
        ],

        // info window
        showAllVehiclesInfoWindow: true,
        showAllGeofencesInfoWindow: true,

        // all geofence stuffs
        showAllGeofence: true,

        showInfoWindowEvents: [],
        showInfoWindowVehicles: [],
        showInfoWindowGeofences: [],

        // not used for now
        showLiveTraffic: false,
        showVehicleTrail: true,
    },

    // 0 = Default
    // 1 = Panning
    currAction: "",

    /**
     * 0 = user, e.g. user draqgged
     * 2 = map, e.g. default start position
     * geoid/dvid/etc =  props, e.g. in the process of zooming into a prop
     */
    mapControl: 2,
};

export const mapControl = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case ActionTypes.SET_MAP_STATUS: {
            newState.currAction = action.newAction;

            return newState;
        }

        case ActionTypes.SET_NEW_GEOFENCE: {
            newState.drawed = true;
            newState.geofence = action.geofence;

            return newState;
        }

        case ActionTypes.CLEAR_GEOFENCE: {
            newState.geofence = {};
            newState.poiCoded = null;

            return newState;
        }

        case ActionTypes.SET_MAP_CONTROL: {
            newState.mapControl = action.control

            return newState;
        }

        case ActionTypes.SET_MAP_SELECTED_EVID: {
            newState.selectedEVID = action.newselectedEVID;

            return newState;
        }
        
        case ActionTypes.CLEAR_MAP_SELECTED_EVID: {
            newState.selectedEVID = defaultState.selectedEVID;

            return newState;
        }
        
        case ActionTypes.SET_MAP_SELECTED_TRID: {
            newState.selectedTrID = action.newSelectedTrID;

            return newState;
        }
        
        case ActionTypes.CLEAR_MAP_SELECTED_TRID: {
            newState.selectedTrID = defaultState.selectedTrID;

            return newState;
        }
        
        case ActionTypes.SET_MAP_SELECTED_GEOID: {
            newState.selectedGEOID = action.newselectedGEOID;

            return newState;
        }
        
        case ActionTypes.CLEAR_MAP_SELECTED_GEOID: {
            newState.selectedGEOID = defaultState.selectedGEOID;

            return newState;
        }

        case ActionTypes.SET_MAP_PLAYBACK_MARKER_LOCATION: {
            newState.playbackMarkerLocation = action.location

            return newState
        }

        case ActionTypes.SET_USER_ZOOM_AND_CENTER: {
            newState.userZoom = action.userZoom
            newState.userCenter = action.userCenter

            return newState
        }

        case ActionTypes.SET_SELECTED_GEOFENCE_TEMPLATES: {
            newState.selectedGeofenceTemplates = action.geofenceTemplateList;

            return newState;
        }

        case ActionTypes.CLEAR_SELECTED_GEOFENCE_TEMPLATES: {
            newState.selectedGeofenceTemplates = [];

            return newState;
        }
        
        case ActionTypes.CLEAR_USER_ZOOM_AND_CENTER: {
            newState.userZoom = null
            newState.userCenter = null

            return newState
        }

        case ActionTypes.SET_MAP_FILTERS: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    durationMin: action.durationMin,
                    selectedEventTypes: action.selectedEventTypes,
                    selectedTransitTypes: action.selectedTransitTypes,
                }
            };
        }

        case ActionTypes.ADD_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowVehicles: !newState.uiControl.showInfoWindowVehicles.includes(action.enabledIdentifier) ?
                        [...newState.uiControl.showInfoWindowVehicles, action.enabledIdentifier] :
                        newState.uiControl.showInfoWindowVehicles,
                }
            };
        }

        case ActionTypes.REMOVE_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowVehicles: newState.uiControl.showInfoWindowVehicles.filter(id=>id!==action.enabledIdentifier)
                }
            };
        }

        case ActionTypes.CLEAR_ENABLED_VEHICLE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowVehicles: [],
                }
            };
        }

        case ActionTypes.ADD_ENABLED_GEOFENCE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowGeofences: !newState.uiControl.showInfoWindowGeofences.includes(action.enabledIdentifier) ?
                        [...newState.uiControl.showInfoWindowGeofences, action.enabledIdentifier] :
                        newState.uiControl.showInfoWindowGeofences,
                }
            };
        }

        case ActionTypes.CLEAR_ENABLED_GEOFENCE_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowGeofences: [],
                }
            };
        }

        case ActionTypes.ADD_ENABLED_EVENT_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowEvents: !newState.uiControl.showInfoWindowEvents.includes(action.enabledIdentifier) ?
                        [...newState.uiControl.showInfoWindowEvents, action.enabledIdentifier] :
                        newState.uiControl.showInfoWindowEvents,
                }
            };
        }

        case ActionTypes.REMOVE_ENABLED_EVENT_POP_UP: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowEvents: newState.uiControl.showInfoWindowEvents.filter(id=>id!==action.enabledIdentifier)
                }
            };
        }

        case ActionTypes.CLEAR_ENABLED_EVENT_POP_UPS: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showInfoWindowEvents: [],
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_VEHICLE_INFO: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllVehiclesInfoWindow: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_VEHICLE_INFO: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllVehiclesInfoWindow: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_GEOFENCE_INFO: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllGeofencesInfoWindow: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_GEOFENCE_INFO: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllGeofencesInfoWindow: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_GEOFENCES: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllGeofence: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_GEOFENCES: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showAllGeofence: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_LIVE_TRAFFIC: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showLiveTraffic: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_LIVE_TRAFFIC: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showLiveTraffic: false,
                }
            };
        }

        case ActionTypes.ENABLE_SHOW_VEHICLE_TRAIL: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showVehicleTrail: true,
                }
            };
        }

        case ActionTypes.DISABLE_SHOW_VEHICLE_TRAIL: {
            return {
                ...newState,
                uiControl: {
                    ...newState.uiControl,
                    showVehicleTrail: false,
                }
            };
        }

        case ActionTypes.RESET_UI_CONTROL: {
            return {
                ...newState,
                uiControl: defaultState.uiControl,
            };
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Map Control Store:", defaultState);
    
            return defaultState;
        }

        default: {
            return state;
        }
    }
}