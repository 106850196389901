import * as ActionTypes from "../action-types/events";

const defaultState = {
    byVID: {},
    byType: {
        "ALL": [],
        "INFO": [],
        "WARNING": [],
        "CRITICAL": [],
        "CONTROL": []
    },
    selectedEVID: null,
    selectedIndex: null,
}

export const events = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_EVENT_LOG: {
            newState = {
                ...newState,
                byId: {},
                byType: {
                    "ALL": [],
                    "INFO": [],
                    "WARNING": [],
                    "CRITICAL": [],
                    "CONTROL": []
                },
            }
    
            action.eventLogs.map((event) => {
    
                if (!newState.byVID.hasOwnProperty(event.vid)) {
                    newState.byVID[event.vid] = {};
                }
                newState.byVID[event.vid][event.evid] = event;
        
                if (!newState.byType["ALL"].includes(event.evid)) {
                    newState.byType["ALL"].push(event.evid);
                }
        
                if (!newState.byType.hasOwnProperty(event.eventType)) {
                    newState.byType[event.eventType] = []
                }
        
                if (!newState.byType[event.eventType].includes(event.evid)) {
                    newState.byType[event.eventType].push(event.evid)
                }
        
                return null
            })
    
            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Events Store:", defaultState);

            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}