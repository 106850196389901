import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/PrimaryButton";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message
} from "antd";

// Redux Actions
import {
    edit_vehicle
} from "../../../services/redux/actions/vehicles";

const FormItem = Form.Item;
const { Option } = Select;

class EditVehicle extends Component {
    state = {
        // Edited Vehicle Stuff
        editedVehicle: {
            dvid: "-",
            engineNumber: "",
            chassisNumber: "",
            vehicleModel: "",
        },
    }

    isNoSpecialChars = string => {
        if(!string) return false
        const regex = /^\w+$/
        return regex.test(string)
    }

    submitForm = () => {
        const {
            dvid,
            vehicleModel,
            engineNumber,
            chassisNumber,
        } = this.state.editedVehicle;

        try {
            if (!engineNumber) throw new Error(`Engine Number cannot be blank`)
            if (!chassisNumber) throw new Error(`Chassis Number cannot be blank`)
            if (!this.isNoSpecialChars(vehicleModel)) throw new Error(` Invalid Vehicle Model. (Cannot have any special characters)`)
            if(!dvid) throw new Error(`Please pair a device to the new vehicle`)

            const { editedVehicle } = this.state;
            
            // console.log({
            //     uid: this.props.user.uid,
            //     editedVehicle
            // })

            this.props.dispatch(edit_vehicle(this.props.user.uid, editedVehicle));
        }catch(err){
            message.error(err.message || 'Error')
        }

    }

    componentDidMount = () => {
        this.populateForm()
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.populateForm()
        }
    }

    populateForm = () => {
        const { vid } = this.props.router.location.state || {}
        const selectedVehicle = this.props.vehicles.byVID[vid];

        selectedVehicle && this.setState({
            editedVehicle: {
                ...selectedVehicle,
                dvid: selectedVehicle.dvid && selectedVehicle.dvid !== "-" ? selectedVehicle.dvid : "-",
            }
        })
    }

    render() {
        const { editedVehicle } = this.state;

        const {
            devices,
            vehicles,
        } = this.props;
        return (
            <div className="page-container">
                <Page title="Edit Vehicle">
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                        <Form className="login-form">
                            <FormItem
                                label="Engine Number"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="Enter an engine number"
                                    value={editedVehicle.engineNumber}
                                    onFocus={e => e.target.select()}
                                    onChange={e => this.setState({
                                        ...this.state,
                                        editedVehicle: {
                                            ...editedVehicle,
                                            engineNumber: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label="Chassis Number"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="Enter a chassis number"
                                    value={editedVehicle.chassisNumber}
                                    onFocus={e => e.target.select()}
                                    onChange={e => this.setState({
                                        ...this.state,
                                        editedVehicle: {
                                            ...editedVehicle,
                                            chassisNumber: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            {/* <FormItem
                                label = "Vehicle Model"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                            >
                                <Input
                                    placeholder = "eg. Perodua"
                                    value = {editedVehicle.vehicleModel}
                                    onFocus = {e => e.target.select()}
                                    onChange = {e => this.setState({
                                        ...this.state,
                                        editedVehicle: {
                                            ...editedVehicle,
                                            vehicleModel: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem> */}

                            <FormItem
                                label="Vehicle Model"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder={`e.g. AP5Z52`}
                                    value = {editedVehicle.vehicleModel}
                                    onChange={e => this.setState({
                                        ...this.state,
                                        editedVehicle: {
                                            ...editedVehicle,
                                            vehicleModel: e.target.value
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label="Device IMEI"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                {
                                    // Object.keys(devices.byDVID).filter((currDVID) => devices.byDVID[currDVID].assignedStatus !== 1).length < 1 ?
                                    Object.keys(devices.byDVID).length < 1 ?
                                        <Select
                                            disabled
                                            placeholder="No unallocated devices"
                                        /> :
                                        vehicles.byVID[vehicles.selectedVID]
                                            && vehicles.byVID[vehicles.selectedVID].dvid
                                            && vehicles.byVID[vehicles.selectedVID].dvid !== "-" ?
                                            <Select
                                                disabled
                                                value={editedVehicle.dvid.split("#")[1]}
                                            /> :
                                            <Select
                                                showSearch
                                                placeholder="Pair to a device"
                                                value={editedVehicle.dvid && editedVehicle.dvid !== "-" ? editedVehicle.dvid.split("#")[1] : undefined}
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }
                                                onChange={value => this.setState({
                                                    ...this.state,
                                                    editedVehicle: {
                                                        ...editedVehicle,
                                                        dvid: value,
                                                    }
                                                })}
                                            >
                                                {
                                                    Object.keys(devices.byDVID)
                                                        .sort((a, b) => {
                                                            if (a < b) return -1;
                                                            if (a > b) return 1;
                                                            return 0;
                                                        })
                                                        .map((currDVID) => {
                                                            // console.log(currDVID);

                                                            return (
                                                                <Option key={currDVID} value={currDVID}>{currDVID.split("#")[1]}</Option>
                                                            );
                                                        })
                                                }
                                            </Select>
                                }
                            </FormItem>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    type="danger"
                                    className="login-form-button"
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    onClick = {this.submitForm}
                                    loading = {this.props.style.isLoadingSubmit}
                                >
                                    Edit
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.user,
    style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(EditVehicle));