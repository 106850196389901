import * as actionType from "../action-types";

const initialState = {
  byVID: {},
  byTSID: {},

  selectedTSID: "",
  transitRecordIndex: null,
};

export const transitRecords = (state = initialState, action) => {

  switch (action.type) {

    case actionType.GET_TRANSIT_RECORD: {
      let newObject = JSON.parse(JSON.stringify(state))

      if (action.transitRecord && action.transitRecord.length) {
        action.transitRecord = action.transitRecord.reverse();

      action.transitRecord.map(t => {
        newObject.byTSID[t.tsid] = t
        return null;
      })

        // console.log("Transit Record Data:", action.transitRecord);

        let mileage = 0;
        let avg_speed = 0;
        let top_speed = 0;
        let total_fuel_use = 0;
        let duration_total = 0;
        let duration_moving = 0;
        let duration_idling = 0;
        let duration_parking = 0;
        let duration_total_idle = 0;

        let noOfMovingStatus = 0;

        // let fuel_plus = 0;
        // let fuel_minus = 0;

        action.transitRecord.forEach((record) => {
          // console.log("Current Transit Record", record);

          switch (record.transitStatus) {
            case 'MOVING': {              
              if (record.duration) {
                duration_moving += record.duration
                duration_total += record.duration
              }
              
              if (record.topSpeed > top_speed) {
                top_speed = record.topSpeed;
              }
              
              if (record.avgSpeed) {
                avg_speed += record.avgSpeed;
              }
  
              noOfMovingStatus ++;

              break;
            }

            case 'IDLING': {
              if (record.duration) {
                duration_idling += record.duration;
              }

              break;
            }

            case 'PARKING': {
              if (record.duration) {
                duration_parking += record.duration
              }

              break;
            }

            default: {
              break;
            }
          }

          if (record.totalMileage) {
            mileage += record.totalMileage
          }

          total_fuel_use += record.totalFuel;

          // if (record.totalFuel >= 0) {
          //   fuel_plus += record.totalFuel;
          // }
          // else {
          //   fuel_minus += record.totalFuel;
          // }

          // // console.log("Total Fuel Used 1:", total_fuel_use);
          // console.log("Fuel Plus 1:", fuel_plus);
          // console.log("Fuel Minus 1:", fuel_minus);

          // console.log("New mileage:", mileage, index);
        })

        // Divide average speed with number of moving statuses
        if(avg_speed > 0 && noOfMovingStatus > 0){
          avg_speed /= noOfMovingStatus
        }
        
        // Totaling up idle and parking duration
        duration_total_idle = duration_idling + duration_parking;

        // Remove duration if final point is parking or else total idle duration will be too long
        if (action.transitRecord[action.transitRecord.length - 1].transitStatus === "PARKING") {
          duration_total_idle -= action.transitRecord[action.transitRecord.length - 1].duration;
        }

        // console.log("Total duration:", duration);
        // console.log("Total mileage:", mileage);

        // console.log("Total Fuel Used 2:", total_fuel_use);

        // console.log("Fuel Plus 2:", fuel_plus);
        // console.log("Fuel Minus 2:", fuel_minus);

        // console.log("Trip Record in redux:", action.transitRecord[0])

        newObject.byVID[action.transitRecord[0].vid] = {
          "vid": action.transitRecord[0].vid, 
          "avg_speed": avg_speed,
          "top_speed": top_speed,
          "fuel_used": parseFloat(total_fuel_use.toFixed(2)),
          "total_mileage": mileage,
          "total_duration": duration_total,
          "moving_duration": duration_moving,
          "idling_duration": duration_idling,
          "parking_duration": duration_parking,
          "total_idle_duration": duration_total_idle,
          "transitRecord": action.transitRecord
            .sort((a, b) => {    
              if (a.startTime < b.startTime) return -1;
              if (a.startTime > b.startTime) return 1;
  
              return 0;
            }),
        }
      }

      return newObject;
    }

    case actionType.SET_SELECTED_TSID: {
      return {
        ...state, 
        selectedTSID: action.selectedTSID
      };
    }

    case actionType.CLEAR_SELECTED_TSID: {
      return {
        ...state, 
        selectedTSID: ""
      };
    }

    case actionType.SET_TRANSIT_RECORD_INDEX: {
      return {
        ...state, 
        transitRecordIndex: action.transitRecordIndex
      };
    }

    case actionType.CLEAR_TRANSIT_RECORD_INDEX: {
      return {
        ...state, 
        transitRecordIndex: null
      };
    }

    case actionType.SET_TRANSIT_RECORD_PDF_STATUS: {
      return {
        ...state, 
        PDFstatus: action.status
      }
    }

    default: {
      return state;
    }
  }
}