import React, { Component } from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import ParseTime from "../../components/ParseTime";
import ParseDuration from "../../components/ParseDuration";
import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";
// import { tableColumnSearchProps } from "../../components/TableColumnSearchProps";

// import { PROCESS_STATUS } from "../../constants";
import {
    Form,
    Table,
    Button,
    DatePicker,
    // AutoComplete,
    Input
} from 'antd';
import {
    SearchOutlined
} from '@ant-design/icons';
import { get_status_log } from '../../services/redux/actions/statusLogs';
import Highlighter from 'react-highlight-words';

class StatusLog extends Component {
    state = {
        startTime: moment().startOf('month'),
        endTime: moment().endOf('day'),

        dataSource: [],
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
        </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
        </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={(text && text.toString()) || ``}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    setUpDataSource = () => {
        // const {
        //     endTime,
        //     startTime,
        //     selectedVID,
        //     selectedArea,
        //     selectedStatus,
        // } = this.state;

        const {
            vehicles,
            statusLogs,
            // vehicleGroups,
        } = this.props;

        // console.log({
        //     selectedVID,
        //     selectedArea,
        //     selectedStatus,
        //     startTime,
        //     endTime,
        // })

        // console.log(statusLogs);

        const dataSource = Object.values(statusLogs.byLRID)
            // .filter((statusLog) => selectedVID === defaultFilter || (selectedVID && statusLog.vid === selectedVID))
            // .filter((statusLog) => selectedStatus === defaultFilter || (selectedStatus && statusLog.logisticalStatus === selectedStatus))
            // .filter((statusLog) => selectedArea === defaultFilter || (selectedArea && statusLog.vid && vehicles.byVID[statusLog.vid] && vehicles.byVID[statusLog.vid].area === selectedArea))
            // .filter(statusLog => statusLog.startTime >= startTime)
            // .filter(statusLog => statusLog.startTime <= endTime)
            // // .filter((statusLog) => statusLog.duration > 1000 || statusLog.logisticalStatus === PROCESS_STATUS.COMPLETED)
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((statusLog) => {
                const currVehicle = vehicles.byVID[statusLog.vid];

                return {
                    key: statusLog.lrid,
                    engineNumber: (currVehicle && currVehicle.engineNumber) || 'DELETED VEHICLE',
                    chassisNumber: (currVehicle && currVehicle.chassisNumber )|| 'DELETED VEHICLE',
                    status: statusLog.logisticalStatus,
                    duration: statusLog.duration,
                    startTime: statusLog.startTime,
                    endTime: statusLog.endTime,
                }
            })

        const columns = [
            {
                title: "Engine Number",
                dataIndex: "engineNumber",
                ...this.getColumnSearchProps('engineNumber'),
            },
            {
                title: "Chassis Number",
                dataIndex: "chassisNumber",
                ...this.getColumnSearchProps('chassisNumber'),
            },

            {
                title: "Status",
                dataIndex: "status",
                ...this.getColumnSearchProps('status'),
            },
            {
                title: "Duration (Hours)",
                dataIndex: "duration",
                sorter: (a, b) => b.duration - a.duration,
                render: duration => ParseDuration(duration)
            },

            {
                title: "Start Time",
                defaultSortOrder: 'descend',
                dataIndex: "startTime",
                sorter: (a, b) => b.startTime - a.startTime,
                render: time => ParseTime(time)
            },

            {
                title: "End Time",
                dataIndex: "endTime",
                sorter: (a, b) => b.startTime - a.startTime,
                render: time => ParseTime(time)
            },
        ];

        this.setState({
            dataSource,
            columns
        })
    }

    componentDidMount = () => {
        this.setUpDataSource();
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setUpDataSource();
        }
    }

    submitForm = () => {
        const startTimeEpoch = this.state.startTime && this.state.startTime.valueOf()
        const endTimeEpoch = this.state.endTime && this.state.endTime.valueOf()

        this.props.dispatch(get_status_log(
            null, null,
            null,
            startTimeEpoch,
            endTimeEpoch
        ))
    }

    render() {
        const {
            dataSource,
            columns = [],
        } = this.state;


        return (
            <div className='page-container'>
                <Page title="Vehicle Logistical Process Status Log">
                    <div
                        style={{
                            display: 'block',
                            minHeight: '95px',
                            padding: '10px 10px 10px 10px',
                        }}
                    >
                        <Form layout='inline'>

                            <Form.Item
                                label={`Start time`}
                            >
                                <DatePicker
                                    showTime
                                    defaultValue={this.state.startTime}
                                    onChange={value => this.setState({ startTime: value })}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={`End time`}
                            >
                                <DatePicker
                                    showTime
                                    defaultValue={this.state.endTime}
                                    onChange={value => this.setState({ endTime: value })}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type={'primary'}
                                    loading={this.props.style.isLoadingSubmit}
                                    onClick={() => this.submitForm()}
                                    style={{ marginLeft: '15px' }}
                                >
                                    Submit
                                </Button>

                                <ExportExcelButton
                                    disabled={dataSource.length === 0}
                                    filename={`Status Logs ${moment().format('DD-MM-YYYY')}`}
                                    sheetData={dataSource}
                                    sheetName={`Status Logs ${moment().format('DD-MM-YYYY')}`}
                                    sheetRow={
                                        columns
                                            .map(col => {
                                                switch (col.dataIndex) {
                                                    case 'duration':
                                                    case 'startTime':
                                                    case 'endTime':
                                                        return {
                                                            label: col.title,
                                                            formatter: value => col.render(value[col.dataIndex])
                                                        }
                                                    default:
                                                        return {
                                                            label: col.title,
                                                            formatter: value => value[col.dataIndex]
                                                        }
                                                }
                                            })
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </div>

                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{
                            x: columns && columns.length * 150,
                            y: window.innerHeight
                        }}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.style,
    vehicles: state.vehicles,
    statusLogs: state.statusLogs,
    vehicleGroups: state.vehicleGroups,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(StatusLog));