import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/PrimaryButton";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

// Redux Actions
import { add_vehicle } from "../../../services/redux/actions/vehicles";

const FormItem = Form.Item;
const { Option } = Select;

class AddVehicle_VehicleManagement extends Component {
    state = {
        // New Vehicle Stuff
        newVehicle: {
            activeStatus: 1,
            dvid: "",
            engineNumber: "",
            vehicleModel: "",
            chassisNumber: "",
        },
    }

    isNoSpecialChars = string => {
        if(!string) return false;

        const regex = /^\w+$/;
        
        return regex.test(string)
    }

    submitForm = () => {
        const {
            // dvid,
            vehicleModel,
            engineNumber,
            chassisNumber,
        } = this.state.newVehicle;

        try {
            if (!engineNumber) throw new Error(`Engine Number cannot be blank`)
            if (!chassisNumber) throw new Error(`Chassis Number cannot be blank`)
            if (!this.isNoSpecialChars(vehicleModel)) throw new Error(`Invalid Vehicle Model. (Cannot have any special characters)`)
            // if (!dvid) throw `Please pair a device to the new vehicle`

            const { newVehicle } = this.state;
            
            // console.log({
            //     uid: this.props.user.uid,
            //     newVehicle
            // })

            this.props.dispatch(add_vehicle(this.props.user.uid, newVehicle));
        }
        catch(err) {
            message.error(err.message || 'Error')
        }

    }

    render() {
        const { newVehicle } = this.state;

        const { devices } = this.props;

        return (
            <div className = "page-container">
                <Page title = "Add New Vehicle">
                    <div style = {{ padding: "30px 20px 0px 20px" }}>
                        <Form className = "login-form">
                            <FormItem
                                label = "Engine Number"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder = "Enter an engine number"
                                    value = {newVehicle.engineNumber}
                                    onFocus = {e => e.target.select()}
                                    onChange = {e => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            engineNumber: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label = "Chassis Number"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder = "Enter a chassis number"
                                    value = {newVehicle.chassisNumber}
                                    onFocus = {e => e.target.select()}
                                    onChange = {e => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            chassisNumber: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label = "Vehicle Model"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                required
                            >
                                <Input
                                placeholder={`e.g. AP5Z52`}
                                    onChange = {e => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            vehicleModel: e.target.value
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label = "Device IMEI"
                                labelCol = {{ span: 7 }}
                                wrapperCol = {{ span: 15 }}
                                // required
                            >
                                <Select
                                    showSearch
                                    optionFilterProp = "children"
                                    placeholder = "Pair to a device (Optional)"
                                    filterOption = {(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange = {value => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            dvid: value,
                                        }
                                    })}
                                >
                                    {/* <Option key = {'-'} value = {'-'}>{'None'}</Option> */}
                                    {

                                        Object.keys(devices.byDVID)
                                            .filter((dvid) => {
                                                return devices.byDVID[dvid].assignedStatus !== 1 && dvid
                                            })
                                            .sort((a, b) => {                            
                                                if (a < b) return -1;
                                                if (a > b) return 1;
                                                return 0;
                                            })
                                            .map((dvid) => {
                                                // console.log(dvid);

                                                return (
                                                    <Option key = {dvid} value = {dvid}>{dvid.split("#")[1]}</Option>
                                                );
                                            })
                                    }
                                </Select>
                            </FormItem>

                            <div
                                style = {{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    type = "danger"
                                    className = "login-form-button"
                                    onClick = {() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    onClick = {this.submitForm}
                                    loading = {this.props.style.isLoadingSubmit}
                                >
                                    Add
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(AddVehicle_VehicleManagement));