import React from "react";

import showSecondarySidebar from "../components/Layout/helpers/showSecondarySidebar";

// Maps
import URLMap from '../components/Maps/URLMap'
import MapDefault from "../components/Maps/MapDefault";
import MapEvent from "../components/Maps/MapEvent";
import MapTrail from "../components/Maps/GoogleMapsComponentTripRecord";
import MapGeofence from "../components/Maps/MapGeofence";
import MapDrawPOI from "../components/Maps/MapDrawPOI";
import MapDrawPolygon from "../components/Maps/MapDrawPolygon";

const PagesWithMaps = (props) => {
    const renderMap = pathname => {
        switch (pathname) {

            case '/EventLog': {
                return <MapEvent />
            }

            case '/RoutePlayback': {
                return <MapTrail />
            }

            case '/GeofenceManagement': {
                return <MapGeofence />
            }

            case '/GeofenceManagement/AddPOI': {
                return <MapDrawPOI />
            }

            case '/GeofenceManagement/AddPolygon': {
                return <MapDrawPolygon />
            }

            case '/GeofenceManagement/EditPOI': {
                return <MapDrawPOI />
            }

            case '/GeofenceManagement/EditPolygon': {
                return <MapDrawPolygon />
            }

            case '/' : {
                return <URLMap />
            }

            default: {
                return <MapDefault />
            }
        }
    }

    return (
        <div>{renderMap(props.location.pathname)}</div>
    );
}

export default showSecondarySidebar(true)(PagesWithMaps);
