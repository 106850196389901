import 
    React, 
    { 
        useState,
        // useEffect, 
        // useRef 
    } 
from 'react';
import {
    // useDispatch,
    useSelector,
} from 'react-redux';

import EventMarkers from "./components/EventMarkers";
import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import {
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
} from "../../constants";
import {
    // Marker,
    // Circle,
    GoogleMap,
    // OverlayView,
    // DrawingManager,
    // InfoWindow,
} from '@react-google-maps/api';

// Redux Actions
// import { set_map_action } from '../../services/redux/actions/mapControl';

import "./gmaps.css";

const  MapEvent = () => {
    // const dispatch = useDispatch();

    // const mapControl = useSelector(state => state.mapControl);
    // const mapDrawControl = useSelector(state => state.mapDrawControl);

    const [mapRef, setMapRef] = useState(null);
    // const [drawingManagerRef, setDrawingManagerRef] = useState(null);
    // const [thisGeofence, setThisGeofence] = useState(mapDrawControl.drawnGeofence);
    const mapControl = useSelector(state => state.mapControl);
    // const [showInfoWindow, setShowInfoWindow] = useState(true)

    /**
     * Deep Cloning useEffect()
     */
    // const useDeepEffect = (fn, deps) => {
    //     const isFirst = useRef(true);
    //     const prevDeps = useRef(deps);

    //     useEffect(() => {
    //         const isSame = prevDeps.current.every((obj, index) =>
    //             JSON.parse(JSON.stringify(obj)) === JSON.parse(JSON.stringify(deps[index]))
    //         );

    //         if (isFirst.current || !isSame) {
    //             fn();
    //         }

    //         isFirst.current = false;
    //         prevDeps.current = deps;
    //     },
    //         [fn, deps]
    //     );
    // }

    // useDeepEffect(() => {
    //     setThisGeofence(mapDrawControl.drawnGeofence);
    // }, [mapDrawControl.drawnGeofence])

    const getMapCenterAndZoom = () => {
        // console.log(mapControl)

        if (mapControl.userZoom && mapControl.userCenter) {
            return {
                zoom: mapControl.userZoom,
                center: mapControl.userCenter
            }
        }

        if (mapRef) {
            return {
                zoom: mapRef.getZoom(),
                center: mapRef.getCenter(),
            }
        }

        return {
            zoom: MAP_DEFAULT_ZOOM,
            center: MAP_DEFAULT_LOCATION,
        }
    }

    // const putMarkerComplete = (marker) => {
    //     const newMarker = {
    //         ...mapDrawControl.drawnGeofence,
    //         coordinates: [{
    //             lat: Number(marker.position.lat()),
    //             lng: Number(marker.position.lng()),
    //         }]
    //     }

    //     // dispatch(set_drawn_geofence(newMarker));

    //     // Revert drawingMode to null, ie drag
    //     if (drawingManagerRef) {
    //         drawingManagerRef.setDrawingMode(null);
    //     }

    //     // setShowInfoWindow(true);
    // }

    return (
        <GoogleMap
            id = 'example-map'
            zoom = {getMapCenterAndZoom().zoom}
            center = {getMapCenterAndZoom().center}
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            // onMarkerComplete = {marker => putMarkerComplete(marker)}
        >
            <EventMarkers mapRef = {mapRef}/>
        </GoogleMap>
    )
}

const MapComponent = (props) => GoogleMapsLoadScript(MapEvent, props);
export default MapComponent;