import React from "react";
import { connect } from "react-redux";

import {
    Form,
    Modal,
    Input,
    Select,
    message,
} from "antd";

// Redux Actions
import { assign_device_to_vehicle } from "../../../services/redux/actions/vehicles";

class PopUpPair extends React.Component {
    state = {
        deviceList: [],
        selectedDVID: "",
    }

    setUpDeviceList = () => {
        const { devices } = this.props;

        this.setState({
            deviceList: Object.keys(devices.byDVID).filter((currDVID) => devices.byDVID[currDVID].assignedStatus === 0 || devices.byDVID[currDVID].assignedStatus === '-')
        })
    }

    componentDidMount = () => {
        if (Object.keys(this.props.devices.byDVID).length) {
            this.setUpDeviceList();
        }
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(prevProps.devices) !== JSON.stringify(this.props.devices) && Object.keys(this.props.devices.byDVID).length) {
            this.setUpDeviceList();
        }

        if (prevProps.vehicles !== this.props.vehicles) {
            this.props.onCloseModal();
        }
    }

    render() {
        window.props = this.props
        return (
            <div>
                <Modal
                    title="Pair Devices"
                    visible={true}
                    closable={false}
                    okButtonProps={{ loading: this.props.style.isLoadingSubmit }}
                    cancelButtonProps={{ loading: this.props.style.isLoadingSubmit }}
                    onOk={() =>
                        this.state.selectedDVID ?
                            this.props.dispatch(assign_device_to_vehicle(this.props.user.uid, this.props.selectedVID, this.state.selectedDVID)) :
                            message.error("Please select a device to pair with this vehicle.")
                    }
                    onCancel={() => this.props.onCloseModal()}
                >
                    <Form>
                        <Form.Item
                            label="Engine Number"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Input
                                disabled={true}
                                placeholder="Vehicle not selected"
                                value={this.props.vehicles.byVID[this.props.selectedVID].engineNumber}
                            />
                        </Form.Item>

                        <Form.Item
                            label="Target Device"
                            labelCol={{ span: 7 }}
                            wrapperCol={{ span: 15 }}
                        >
                            <Select
                                onChange={value =>
                                    this.setState({
                                        ...this.state,
                                        selectedDVID: value,
                                    })
                                }
                                showSearch
                                placeholder="Select a device"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    Object.values(this.props.devices.byDVID)
                                        .map(device => {
                                            return <Select.Option
                                                key={device.dvid}
                                                disabled={device.assignedStatus === 1}
                                            >
                                                {device.dvid.split('#')[1]}
                                            </Select.Option>
                                        })
                                }
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
});

export default connect(mapStateToProps)(PopUpPair);