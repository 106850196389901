import * as API from "../../api/logisticalPipelines";
import * as ActionTypes from "../action-types/statusLogs";

import { message } from 'antd';
// import { goBackToPrev } from "../../../navigation/navigationService";
import {
    setLoadingSubmit, 
    unsetLoadingSubmit,
} from "./style";

// export const get_logistical_pipelines = (vidList) => (dispatch) => {
//     dispatch(setLoadingSubmit());

//     API.getLogisticalPipelines(vidList)
//     .then(data => {
//         // console.log("Get Logistical Pipelines data:", data);

//         if (data.status !== 200) {
//             if (data.message) {
//                 message.error('Failed To Get Logistical Pipelines: ' + data.message);
//             }
//         }
//         else {
//             message.info('Successfully Retrieved Logistical Pipelines.');

//             dispatch(get_logistical_pipelines_success(data.pipeLines));
//             // dispatch(goBackToPrev());
//         }

//         dispatch(unsetLoadingSubmit());
//     })
// }

// const get_logistical_pipelines_success = (newPipelines) => {
//     return {
//         type: ActionTypes.GET_LOGISTICAL_PIPELINES,
//         newPipelines: newPipelines,
//     };
// }

export const get_status_log = (vidList = null, vgidList = null, statusList = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.getStatusLog(vidList, vgidList, statusList, startTime, endTime)
    .then(data => {
        // console.log("Get Status Log data:", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error('Failed To Get Status Log: ' + data.message);
            }
        }
        else {
            // message.info('Successfully Retrieved Status Log.');

            dispatch(get_status_log_success(data.logisticalRecords));
            // dispatch(goBackToPrev());
        }

        if (data.logisticalRecords.length < 1) {
            message.info("No Logs Found Within This Timeframe.");
        }

        dispatch(unsetLoadingSubmit());
    })
}

const get_status_log_success = (newLogs) => {
    return {
        type: ActionTypes.GET_STATUS_LOG,
        newLogs: newLogs,
    };
}

export const clear_status_log = () => {
    return {
        type: ActionTypes.CLEAR_STATUS_LOG,
    };
}