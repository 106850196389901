import * as API from "../../api/deviceLogs";
import * as ActionTypes from "../action-types/deviceLogs";

import { message } from 'antd';
import {
    setLoadingSubmit,
    unsetLoadingSubmit,
} from "./style";

export const get_device_log = (dvidList = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.getDeviceLog(dvidList, startTime, endTime)
    .then(data => {
        // console.log("Device Log Data:", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error('Failed To Get Device Log: ' + data.message);
            }
        }
        else {

            message.info('Successfully Retrieved Device Log.');

            if (data.deviceLogs.length < 1) {
                message.info("No Device Logs Found Within This Timeframe.");
            }

            dispatch(get_device_log_success(data.deviceLogs));
        }

        dispatch(unsetLoadingSubmit());
    })
}

const get_device_log_success = (newDeviceLogs) => {
    return {
        type: ActionTypes.GET_DEVICE_LOG,
        newDeviceLogs: newDeviceLogs
    }
}

export const get_device_log_debug = (dvidList = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.getDeviceLog_Debug(dvidList, startTime, endTime)
    .then(data => {
        // console.log("Debug Device Log Data:", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error('Failed To Get Debug Device Log: ' + data.message);
            }
        }
        else {
            message.info('Successfully Retrieved Debug Device Log.');

            if (data.deviceLogs.length < 1) {
                message.info("No Logs Found Within This Timeframe.");
            }

            dispatch(get_device_log_debug_success(data.deviceLogs));
        }

        dispatch(unsetLoadingSubmit());
    })
}

const get_device_log_debug_success = (newDeviceLogs) => {
    return {
        type: ActionTypes.GET_DEVICE_LOG_DEBUG,
        newDeviceLogs: newDeviceLogs
    }
}

export const clear_device_log = () => {
    return {
        type: ActionTypes.CLEAR_DEVICE_LOG
    }
}

export const clear_device_log_debug = () => {
    return {
        type: ActionTypes.CLEAR_DEVICE_LOG_DEBUG
    }
}