import React from "react";
import { connect } from "react-redux";

import asyncComponent from "../components/AsyncComponent";
import {
  Route,
  Switch
} from "react-router";

const AsyncMapDashboardPage = asyncComponent(() => import("../pages/MapDashboard"));
const AsyncEventLogPage = asyncComponent(() => import("../pages/EventLog"));
const AsyncRoutePlayback = asyncComponent(() => import("../pages/RoutePlayback"));
const AsyncGeofenceManagementPage = asyncComponent(() => import("../pages/GeofenceManagement"));
const AsyncAddPOIPage = asyncComponent(() => import("../pages/GeofenceManagement/AddNewPOIPage"));
const AsyncAddPolygonPage = asyncComponent(() => import("../pages/GeofenceManagement/AddNewPolygonPage"));
const AsyncEditPOIPage = asyncComponent(() => import("../pages/GeofenceManagement/EditPOIPage"));
const AsyncEditPolygonPage = asyncComponent(() => import("../pages/GeofenceManagement/EditPolygonPage"));

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={AsyncMapDashboardPage} />
      <Route exact path="/EventLog" component={AsyncEventLogPage} />
      <Route exact path="/RoutePlayback" component={AsyncRoutePlayback} />
      <Route exact path="/GeofenceManagement" component={AsyncGeofenceManagementPage} />
      <Route exact path="/GeofenceManagement/AddPOI" component={AsyncAddPOIPage} />
      <Route exact path="/GeofenceManagement/AddPolygon" component={AsyncAddPolygonPage} />
      <Route exact path="/GeofenceManagement/EditPOI" component={AsyncEditPOIPage} />
      <Route exact path="/GeofenceManagement/EditPolygon" component={AsyncEditPolygonPage} />
    </Switch>
  )
}

export default connect(null)(AppRoutes);