import * as API from "../../api/vehicles";
import * as ActionTypes from "../action-types/vehicles";

import { message } from 'antd';
import { 
    moveToPage,
    goBackToPrev 
} from "../../../navigation/navigationService";
import {
    setLoadingSubmit,
    unsetLoadingSubmit,
} from "./style";

// Redux Actions
import { update_vehicle_groups_after_allocate } from './vehicleGroups';
import {
    set_assigned_vehicle,
    // clear_assigned_vehicle,
    set_device_assigned_status_to_true_by_dvid,
    set_device_assigned_status_to_false_by_dvid
} from "../actions/devices";

// Exported to user action
export const get_vehicles_success = (vehicles) => {
    return {
        type: ActionTypes.GET_VEHICLES,
        vehicles: vehicles,
    };
}

export const add_vehicle = (uid, newVehicle) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.addVehicle(uid, newVehicle)
        .then(data => {
            // console.log("addVehicle data:", data);

            if (data.status === 200) {
                message.info('Successfully Added Vehicle.');

                dispatch(add_vehicle_success(data));
                dispatch(set_assigned_vehicle(data.vehicle.dvid, data.vehicle.vid));

                if (data.vehicle.dvid && data.vehicle.dvid !== "-") {
                    dispatch(set_device_assigned_status_to_true_by_dvid(data.vehicle.dvid));
                }

                dispatch(moveToPage("/VehicleManagement"));
                window.location.reload();

                // console.log("Reloaded");
            }
            else if (data.status === 201) {
                message.info('Successfully Added Vehicle.');

                dispatch(add_vehicle_success(data));
                dispatch(set_assigned_vehicle(data.vehicle.dvid, data.vehicle.vid));

                if (data.vehicle.dvid && data.vehicle.dvid !== "-") {
                    dispatch(set_device_assigned_status_to_true_by_dvid(data.vehicle.dvid));
                }

                dispatch(moveToPage("/VehicleManagement"));
                window.location.reload();

                // console.log("Reloaded");
            }
            else {
                if (data.message) {
                    message.error('Failed To Add Vehicle: ' + data.message);
                }
            }

            dispatch(unsetLoadingSubmit());
        })
}

export const add_vehicle_success = (newVehicle) => {
    return {
        type: ActionTypes.ADD_VEHICLE,
        newVehicle: newVehicle,
    };
}

export const edit_vehicle = (uid, editedVehicle) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.editVehicle(uid, editedVehicle)
        .then(data => {
            // console.log("editVehicle data:", data);

            if (data.status !== 200) {
                if (data.message) {
                    message.error('Failed To Edit Vehicle: ' + data.message);
                }
            }
            else {
                message.info('Successfully Edited Vehicle.');

                dispatch(edit_vehicle_success(data.vehicle));
                dispatch(goBackToPrev());
            }

            dispatch(unsetLoadingSubmit());
        })
}

export const edit_vehicle_success = (editedVehicle) => {
    return {
        type: ActionTypes.EDIT_VEHICLE,
        editedVehicle: editedVehicle,
    };
}

export const allocate_vehicle = (uid, vid, outlet, area, allocatedDate) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.allocateVehicleToGroups(uid, vid, outlet, area, allocatedDate)
        .then(data => {
            // console.log("allocateVehicleToGroups data:", data);

            if (data.status !== 200) {
                // if (data.message) {
                //     message.error('Failed To Allocate Vehicle: ' + data.message);
                // }

                dispatch(update_allocate_progress(vid, false, data.message ? data.message : null));
            }
            else {
                message.info('Successfully Allocated Vehicle.');

                const vehicleGroups = [data.outlet, data.area]

                dispatch(update_allocate_progress(vid, true));
                dispatch(allocate_vehicle_success(vid, vehicleGroups));
                dispatch(update_vehicle_groups_after_allocate(vehicleGroups));
            }

            dispatch(unsetLoadingSubmit());
        })
}

export const allocate_vehicle_success = (selectedVID, vgList) => {
    return {
        type: ActionTypes.ALLOCATE_VEHICLE,
        selectedVID: selectedVID,
        vgList: vgList,
    };
}

export const assign_device_to_vehicle = (uid, vid, dvid) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.assignDeviceToVehicle(uid, vid, dvid)
        .then(data => {
            // console.log("assignDeviceToVehicle data:", data);

            if (data.status !== 200) {
                if (data.message) {
                    message.error('Failed To Pair Device To Vehicle: ' + data.message);
                }
            }
            else {
                message.info('Successfully Paired Device To Vehicle.');

                dispatch(assign_device_to_vehicle_success(data.vehicle));
                dispatch(set_device_assigned_status_to_true_by_dvid(dvid));
            }

            dispatch(unsetLoadingSubmit());
        })
}

export const assign_device_to_vehicle_success = (vehicle) => {
    return {
        type: ActionTypes.ASSIGN_DEVICE_TO_VEHICLE,
        vehicle: vehicle,
    };
}

export const unassign_devices_from_vehicles = (dvids, uid) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.unassignDevices(dvids, uid)
        .then(data => {
            // console.log("unassignDevices data:", data);

            if (data.status !== 200) {
                if (data.message) {
                    message.error('Failed To Unpair Devices: ' + data.message);
                }
            }
            else {
                message.info('Successfully Unpaired Devices From Vehicles.');

                dispatch(unassign_devices_from_vehicles_success(dvids));

                dvids.forEach((currDVID) => dispatch(set_device_assigned_status_to_false_by_dvid(currDVID)));
            }

            dispatch(unsetLoadingSubmit());
        })
}

const unassign_devices_from_vehicles_success = (dvids) => {
    return {
        type: ActionTypes.UNASSIGN_DEVICES,
        dvidList: dvids,
    };
}

export const set_selected_vid = (newVID) => {
    return {
        type: ActionTypes.SET_SELECTED_VID,
        newVID: newVID,
    };
}

export const clear_selected_vid = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_VID,
    };
}

export const set_up_allocate_progress = (selectedVID, vgList) => {
    return {
        type: ActionTypes.SET_UP_ALLOCATE_PROGRESS,
        selectedVID: selectedVID,
        vgList: vgList,
    };
}

const update_allocate_progress = (selectedVID, isSuccess, remarks = null) => {
    return {
        type: ActionTypes.UPDATE_ALLOCATE_PROGRESS,
        selectedVID: selectedVID,
        isSuccess: isSuccess,
        remarks: remarks,
    };
}

export const clear_allocate_progress = () => {
    return {
        type: ActionTypes.CLEAR_ALLOCATE_PROGRESS,
    };
}

export const clear_selected_vehicle = () => {
    return {
      type: ActionTypes.CLEAR_SELECTED_VEHICLE
    }
}