import * as API from "../../api/geofenceReports";
import * as ActionTypes from "../action-types/geofenceReports";

import { message } from 'antd';
import {
    setLoadingSubmit, 
    // unsetLoadingSubmit,
} from "./style";

export const get_geofence_record = (vidList = null, vgidList = null, geoidList = null, startTime = null, endTime = null) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.getGeofenceRecordByTimeframe(vidList, vgidList, geoidList, startTime, endTime)
    .then((data) => {
        // console.log("get_geofence_record_by_timeframe data:", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error('Failed To Get Geofence Reports: ' + data.message);
            }
        }
        else {            
            dispatch(get_geofence_record_by_timeframe_success(data.geofenceRecords));
        }

        // dispatch(unsetLoadingSubmit());
    })
}
  
const get_geofence_record_by_timeframe_success = (geofenceReports) => {
    return {
        type: ActionTypes.GET_GEOFENCE_REPORTS,
        geofenceReports: geofenceReports
    }
}