import React, { useState, useEffect, useRef } from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';

import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import {
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
} from "../../constants";
import {
    Marker,
    Circle,
    GoogleMap,
    // OverlayView,
    DrawingManager,
    InfoWindow,
} from '@react-google-maps/api';

// Redux Actions
import { set_drawn_geofence } from "../../services/redux/actions/mapDrawControl";

import "./gmaps.css";

const MapDrawPOI = () => {
    const dispatch = useDispatch();

    // const mapControl = useSelector(state => state.mapControl);
    const mapDrawControl = useSelector(state => state.mapDrawControl);

    const [mapRef, setMapRef] = useState(null);
    const [drawingManagerRef, setDrawingManagerRef] = useState(null);
    const [thisGeofence, setThisGeofence] = useState(mapDrawControl.drawnGeofence);
    const mapControl = useSelector(state => state.mapControl);
    const [showInfoWindow, setShowInfoWindow] = useState(true)

    /**
     * Deep Cloning useEffect()
     */
    const useDeepEffect = (fn, deps) => {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) =>
                JSON.parse(JSON.stringify(obj)) === JSON.parse(JSON.stringify(deps[index]))
            );

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        },
            [fn, deps]
        );
    }

    useDeepEffect(() => {
        setThisGeofence(mapDrawControl.drawnGeofence);
    }, [mapDrawControl.drawnGeofence])

    const getMapCenterAndZoom = () => {
        // console.log(mapControl)

        if (mapControl.userZoom && mapControl.userCenter) {
            return {
                zoom: mapControl.userZoom,
                center: mapControl.userCenter
            }
        }

        if (mapRef) {
            return {
                zoom: mapRef.getZoom(),
                center: mapRef.getCenter(),
            }
        }

        return {
            zoom: MAP_DEFAULT_ZOOM,
            center: MAP_DEFAULT_LOCATION,
        }
    }

    const putMarkerComplete = (marker) => {
        const newMarker = {
            ...mapDrawControl.drawnGeofence,
            coordinates: [{
                lat: Number(marker.position.lat()),
                lng: Number(marker.position.lng()),
            }]
        }

        dispatch(set_drawn_geofence(newMarker));

        // Revert drawingMode to null, ie drag
        if (drawingManagerRef) {
            drawingManagerRef.setDrawingMode(null);
        }
        setShowInfoWindow(true)
    }

    return (
        <GoogleMap
            id='example-map'
            zoom={getMapCenterAndZoom().zoom}
            center={getMapCenterAndZoom().center}
            mapContainerStyle={{
                height: '100vh'
            }}
            onLoad={ref => setMapRef(ref)}
        >
            {
                thisGeofence.coordinates
                && thisGeofence.coordinates.length === 1 &&
                <Marker position={thisGeofence.coordinates[0]}>
                    {/* Not working. Disabled. InfoWindow used instead */}
                    {/* <OverlayView
                        options={{ disableAutoPan: true }}
                        position={{
                            lat: thisGeofence.coordinates[0].lat,
                            lng: thisGeofence.coordinates[0].lng,
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        getPixelPositionOffset={(width, height) => ({
                            x: -(width / 2),
                            y: -height - 47,
                        })}
                    >
                        <div className="geofenceMarkerOverlayView">
                            <h3>{thisGeofence.geofenceName}</h3>

                            {thisGeofence.comment && <div className="geofenceMarkerOverlayViewComment">{thisGeofence.comment}</div>}
                        </div>
                    </OverlayView> */}
                    {
                        showInfoWindow && (
                            <InfoWindow
                                onCloseClick={() => {
                                    setShowInfoWindow(false)
                                }}
                                position={{
                                    lat: thisGeofence.coordinates[0].lat,
                                    lng: thisGeofence.coordinates[0].lng,
                                }}
                            >
                                <div className="geofenceMarkerOverlayView">
                                    <h3>{thisGeofence.geofenceName}</h3>

                                    {thisGeofence.comment && <div className="geofenceMarkerOverlayViewComment">{thisGeofence.comment}</div>}
                                </div>
                            </InfoWindow>
                        )
                    }
                </Marker>
            }

            {
                thisGeofence.coordinates
                && thisGeofence.coordinates.length === 1 &&
                <Circle
                    center={thisGeofence.coordinates[0]}
                    radius={thisGeofence.geofenceRadius}
                    options={{
                        strokeColor: "#ff0000",
                    }}
                    onLoad={(ref) => ref.getBounds() && mapRef.fitBounds(ref.getBounds())}
                />
            }

            {
                window.google &&
                <DrawingManager
                    drawingMode={window.google.maps.drawing.OverlayType.MARKER}
                    options={{
                        drawingControl: true,
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                window.google.maps.drawing.OverlayType.MARKER,
                            ],
                        },
                        markerOptions: {
                            visible: false,
                        },
                    }}
                    onLoad={ref => setDrawingManagerRef(ref)}
                    onMarkerComplete={marker => putMarkerComplete(marker)}
                />
            }
        </GoogleMap>
    )
}

const MapComponent = (props) => GoogleMapsLoadScript(MapDrawPOI, props);
export default MapComponent;