import React, { useState, useEffect } from "react";

/**
 * Allows component to be imported in an asynchronous way. Components imported in this way will be compiled into
 * a seperate chunk during build, which loads only when required.
 * @param {object} importComponent Component to import
 */
export default function asyncComponent(importComponent) {
    const AsyncComponent = (props) => {

        const [ component, setComponent ] = useState(null)

        const setInitialComponent = async() => {
            const { default: component } = await importComponent();
            // console.log("Component loaded", component)
            setComponent(() => component)
        }

        useEffect(() => {
            setInitialComponent()
        }, [])

        const C = component;

        return C && <C {...props} />;
    }

    return AsyncComponent;
}