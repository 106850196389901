import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../../components/Page";
// import ReactExport from "react-data-export";
import ExportExcelButton from "../../../components/ExportExcelButton"
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

// import { EmptyIndicator } from "../../../components/EmptyIndicator";
// import { goBackToPrev } from '../../../navigation/navigationService';
import {
    Form,
    Table,
    Button,
    DatePicker,
    // AutoComplete,
    Select,
    Input
} from 'antd';
import {
    SearchOutlined
} from '@ant-design/icons'

// React Actions
// import {
//     set_selected_dvid,
//     clear_selected_vid,
// } from "../../../services/redux/actions/vehicles";
import {
    get_assignment_log,
    // clear_assignment_log
} from "../../../services/redux/actions/assignmentLogs";
import parseTime from '../../../components/ParseTime';
import Highlighter from 'react-highlight-words';
// import { DEVICE_STATUS } from '../../../constants';

class AssignmentLogInspector extends React.Component {
    state = {
        selectedVIDFilterString: "",
        selectedVID: null,

        startTime: moment().startOf("month"),
        endTime: moment().add(1, "day").startOf("day"),

        dataSource: [],
    }
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
        </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
        </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={(text && text.toString()) || ``}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    onSubmit = () => {
        let {
            endTime,
            startTime,
            selectedVID,
        } = this.state;

        let vids = [selectedVID];
        startTime = startTime && startTime.valueOf();
        endTime = endTime && endTime.valueOf();

        // console.log({
        //     vids,
        //     startTime,
        //     endTime
        // });

        this.props.dispatch(get_assignment_log(vids, startTime, endTime));
    }

    setUpDatasource = () => {
        const {
            devices,
            vehicles,
            assignmentLogs,
        } = this.props

        // console.log(assignmentLogs);

        const newDataSource =
            (
                assignmentLogs.byVID[this.state.selectedVID] 
                &&
                assignmentLogs.byVID[this.state.selectedVID]
                    .sort((a, b) => {
                        if (a.createdAt < b.createdAt) return -1;
                        if (a.createdAt > b.createdAt) return 1;
                        return 0;
                    })
                    .map((log, index) => {
                        const vehicle = vehicles.byVID[log.vid]
                        const device = devices.byDVID[log.dvid]

                        return {
                            key: log.aid,
                            vehicle: (vehicle && vehicle.engineNumber) || '-',
                            device: (device && device.dvid && device.dvid.split('#')[1]) || '-',
                            status: log.status || '-',
                            createdAt: log.createdAt,
                        }

                    }) 
            )
            || 
            []

        this.setState({
            dataSource: newDataSource,
        })
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setUpDatasource()
        }
    }

    componentDidMount = () => {
        const { vid } = this.props.router.location.state || {}

        this.setState({
            selectedVID: vid
        })
    }


    render() {
        const {
            endTime,
            startTime,
            selectedVID,
            dataSource
        } = this.state;

        const {
            vehicles
        } = this.props;

        const columns = [
            {
                title: "Created At",
                dataIndex: "createdAt",
                key: 'createdAt',
                sorter: (a, b) => b.createdAt - a.createdAt
            },
            {
                title: "Vehicle",
                dataIndex: "vehicle",
                key: 'vehicle',
                ...this.getColumnSearchProps('vehicle')
            },
            {
                title: "Device Imei",
                dataIndex: "device",
                key: 'device',
                ...this.getColumnSearchProps('device')
            },
            {
                title: "Action",
                dataIndex: "status",
                key: 'status',
                filters: ['ASSIGN', 'UNASSIGN'].map(status=>({value: status, text: status})),
                onFilter: (value, record) => record.status.indexOf(value) === 0,
            },
        ]

        return (
            <div className="page-container">
                <Page title="Assignment Log Inspector Table">
                    <Form layout='inline' style={{ marginBottom: 15 }}>
                        <Form.Item
                            label={`Vehicle`}
                        >
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Select a vehicle"
                                optionFilterProp="children"
                                value={selectedVID}
                                onChange={(vid) => {
                                    this.setState({
                                        selectedVID: vid
                                    })
                                }}
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {
                                    Object.values(vehicles.byVID)
                                        .sort((a, b) => b.engineNumber - a.engineNumber)
                                        .map((vehicle) => {
                                            const { vid, engineNumber } = vehicle
                                            return <Select.Option key={vid}>{engineNumber}</Select.Option>
                                        })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={`Start Time`}
                        >
                            <DatePicker
                                showTime
                                defaultValue={startTime}
                                onChange={value => this.setState({ startTime: value })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={`End Time`}
                        >
                            <DatePicker
                                showTime
                                defaultValue={endTime}
                                onChange={value => this.setState({ endTime: value })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                disabled={!selectedVID}
                                onClick={this.onSubmit}
                                loading={this.props.style.isLoadingSubmit}
                                style={{
                                    marginLeft: '15px',
                                    marginRight: '10px'
                                }}
                            >
                                Submit
                            </Button>

                            <ExportExcelButton
                                disabled={dataSource.length === 0}
                                filename={`Vehicle Assignment Log (${parseTime(moment.now())})`}
                                sheetData={dataSource}
                                sheetName={`Vehicle Assignment Log (${parseTime(moment.now())})`}
                                sheetRow={
                                    columns
                                        .map(col => {
                                            switch (col.dataIndex) {
                                                case 'createdAt':
                                                    return {
                                                        label: col.title,
                                                        formatter: value => col.render(value[col.dataIndex])
                                                    }
                                                default:
                                                    return {
                                                        label: col.title,
                                                        formatter: value => value[col.dataIndex]
                                                    }
                                            }
                                        })
                                }
                            />

                        </Form.Item>
                    </Form>

                    <div >
                        <Table
                            columns={columns}
                            dataSource={dataSource}
                            loading={this.props.style.isLoadingSubmit}
                            pagination={{ pageSize: 25 }}
                            // onChange = {this.onChangeTable}
                            scroll={{
                                x: columns && columns.length * 150,
                                y: window.innerHeight
                            }}
                        />
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.user,
    style: state.style,
    devices: state.devices,
    assignmentLogs: state.assignmentLogs,
    vehicles: state.vehicles,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(AssignmentLogInspector));