import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/PrimaryButton";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    Select,
    message,
} from "antd";

import {
    PERODUA_VEHICLE_MODELS
} from '../../../constants'

// Redux Actions
import { add_vehicle } from "../../../services/redux/actions/vehicles";

const FormItem = Form.Item;
const { Option } = Select;

class AddVehicle_Dashboard extends Component {
    state = {
        // New Vehicle Stuff
        newVehicle: {
            activeStatus: 1,
            dvid: "-",
            engineNumber: "",
            vehicleModel: "",
            chassisNumber: "",
        },
    }

    submitForm = () => {
        const {
            // dvid,
            vehicleModel,
            engineNumber,
            chassisNumber,
        } = this.state.newVehicle;

        let isValid = true;

        if (!engineNumber) {
            message.error("Engine Number Cannot Be Blank");

            isValid = false;
        }

        if (!chassisNumber) {
            message.error("Chassis Number Cannot Be Blank");

            isValid = false;
        }

        if (!vehicleModel) {
            message.error("Vehicle Model Cannot Be Blank");

            isValid = false;
        }

        // if (!dvid) {
        //     message.error("Please Pair A Device To This Vehicle");

        //     isValid = false;
        // }

        if (isValid) {
            const { newVehicle } = this.state;

            // console.log({
            //     uid: this.props.user.uid,
            //     newVehicle
            // })

            this.props.dispatch(add_vehicle(this.props.user.uid, newVehicle));
        }
    }

    render() {
        const { newVehicle } = this.state;

        const { devices } = this.props;

        return (
            <div className="page-container">
                <Page title="Add New Vehicle">
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                        <Form className="login-form">
                            <FormItem
                                label="Engine Number"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="Enter an engine number"
                                    value={newVehicle.engineNumber}
                                    onFocus={e => e.target.select()}
                                    onChange={e => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            engineNumber: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label="Chassis Number"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder="Enter a chassis number"
                                    value={newVehicle.chassisNumber}
                                    onFocus={e => e.target.select()}
                                    onChange={e => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            chassisNumber: e.target.value,
                                        }
                                    })}
                                />
                            </FormItem>

                            <FormItem
                                label="Vehicle Model"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Choose a vehicle model"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={model => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            vehicleModel: model
                                        }
                                    })}
                                >
                                    {
                                        PERODUA_VEHICLE_MODELS
                                            .map((model) => {
                                                return (
                                                    <Option key={model} value={model}>{model}</Option>
                                                );
                                            })
                                    }
                                </Select>
                            </FormItem>

                            <FormItem
                                label="Device IMEI"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    placeholder="Pair to a device"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    onChange={value => this.setState({
                                        ...this.state,
                                        newVehicle: {
                                            ...newVehicle,
                                            dvid: value,
                                        }
                                    })}
                                >
                                    {/* <Option key={'-'} value={'-'}>{'None'}</Option> */}
                                    {

                                        Object.keys(devices.byDVID)
                                            .filter((dvid) => {
                                                return devices.byDVID[dvid].assignedStatus !== 1 && dvid
                                            })
                                            .sort((a, b) => {                            
                                                if (a < b) return -1;
                                                if (a > b) return 1;
                                                return 0;
                                            })
                                            .map((dvid) => {
                                                // console.log(dvid);

                                                return (
                                                    <Option key={dvid} value={dvid}>{dvid.split("#")[1]}</Option>
                                                );
                                            })
                                    }
                                </Select>

                            </FormItem>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",

                                    padding: 5,
                                    marginBottom: 10,
                                }}
                            >
                                <Button
                                    type="danger"
                                    className="login-form-button"
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton
                                    loading={this.props.style.isLoadingSubmit}
                                    onClick={this.submitForm}
                                >
                                    Add New
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(AddVehicle_Dashboard));