import
// React, 
{
    // useState, 
    useEffect,
    useCallback
}
    from 'react';
import { useDispatch, useSelector } from 'react-redux'

// import moment from 'moment';

// import { update_device } from '../../services/redux/actions/devices'
import { edit_vehicle_success } from '../../services/redux/actions/vehicles'

import {
    wsContainer
} from '../../services/websocket';


const BackgroundTask = (props) => {


    const vehicles = useSelector(state => state.vehicles);

    const dispatch = useDispatch()

    // const redux_update_device = useCallback((data) => dispatch(update_device(JSON.parse(data))), [dispatch])
    const redux_edit_vehicle_success = useCallback((vehicle) => dispatch(edit_vehicle_success(vehicle)), [dispatch])

    useEffect(() => {

        wsContainer(
            (err, data) => {
                // setMessageCount(messageCount + 1);
                // console.log('[BackgroundTask] WebSoc', data.mode)
                // if (data.id === 'GPS100#864893036802141' || data.id === 'vhc#a17bf9b0-3ce7-11ea-aaf7-ad2d2391ff77') {
                //     console.log('ws', data)
                //     console.log(moment(data.item.updatedAt).format('hh:mm:ss a'))
                // }

                if (!err) {
                    switch (data.mode) {
                        case 'vhc':
                            let vehicle = vehicles.byVID[data.item.vid]

                            if (vehicle) {
                                vehicle = {
                                    ...vehicle,
                                    ...data.item
                                }
    
                                dispatch(redux_edit_vehicle_success(vehicle))
                            }

                            break
                        default:
                            break

                    }
                }
            })

    })

    return null
}

export default BackgroundTask