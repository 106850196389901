import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import POIGeofences from "./components/POIGeofences";
import PolygonGeofences from "./components/PolygonGeofences";
import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { GoogleMap } from '@react-google-maps/api';
import {
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
} from "../../constants";

// Redux Actions
import {
    set_map_action,
    set_user_zoom_and_center,
} from '../../services/redux/actions/mapControl';

import "./gmaps.css";

const MapGeofence = () => {
    const dispatch = useDispatch();

    const [mapRef, setMapRef] = useState(null);
    const mapControl = useSelector(state => state.mapControl);

    const getMapCenterAndZoom = () => {
        // console.log(mapControl)

        if (mapControl.userZoom && mapControl.userCenter) {
            return {
                zoom: mapControl.userZoom,
                center: mapControl.userCenter
            }
        }

        if (mapRef) {
            return {
                zoom: mapRef.getZoom(),
                center: mapRef.getCenter(),
            }
        }

        return {
            zoom: MAP_DEFAULT_ZOOM,
            center: MAP_DEFAULT_LOCATION,
        }
    }

    return (
        <GoogleMap
            id = 'example-map'
            zoom = {getMapCenterAndZoom().zoom}
            center = {getMapCenterAndZoom().center}
            mapContainerStyle = {{
                height: '100vh'
            }}
            onLoad = {ref => setMapRef(ref)}
            onDragEnd = {() => {
                if (mapRef) {
                    const loc = {
                        lat: mapRef.getCenter().lat(),
                        lng: mapRef.getCenter().lng()
                    }

                    const zoom = mapRef.getZoom()

                    dispatch(set_user_zoom_and_center(zoom, loc))
                }
            }}
            onDragStart = {() => dispatch(set_map_action(0))}
        >
            <POIGeofences mapRef = {mapRef}/>
            <PolygonGeofences mapRef = {mapRef}/>

            {/* {mapControl.toggleControl.showLiveTraffic && <TrafficLayer/>} */}

            {/* <div className = "controlPanel">
                <MapControlPanel disableToggleVehicleInfo = {true} disableToggleLiveTraffic = {true}/>
            </div> */}
        </GoogleMap>
    )
}

const MapComponent = (props) => GoogleMapsLoadScript(MapGeofence, props);
export default MapComponent;

// export default MapGeofence