import * as API from "../../api/devices";
import * as ActionTypes from "../action-types/devices";

import { message } from 'antd';
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    setLoadingSubmit, 
    unsetLoadingSubmit,
} from "./style";

// Exported to user action
export const get_devices_success = (devices) => {
    return {
        type: ActionTypes.GET_DEVICES,
        devices: devices,
    };
}

export const add_device = (uid, newDevice) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.addDevice(uid, newDevice)
    .then((data) => {
        // console.log("add_device data:", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error('Failed To Add Device: ' + data.message);
            }
        }
        else {
            message.info('Successfully Added Device.');

            dispatch(add_device_success(data.device));
            dispatch(goBackToPrev());
        }

        dispatch(unsetLoadingSubmit());
    })
}

export const add_device_success = (newDevice) => {
    return {
        type: ActionTypes.ADD_DEVICE,
        newDevice: newDevice,
    };
}

export const edit_device = (editedDevice) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.editDevice(editedDevice)
    .then((data) => {
        // console.log("edit_device data:", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error('Failed To Edit Device: ' + data.message);
            }
        }
        else {
            message.info('Successfully Edited Device.');

            dispatch(edit_device_success(data.device));
            dispatch(goBackToPrev());
        }

        dispatch(unsetLoadingSubmit());
    })
}

const edit_device_success = (editedDevice) => {
    return {
        type: ActionTypes.EDIT_DEVICE,
        editedDevice: editedDevice,
    };
}

export const set_selected_dvid = (newDVID) => {
    return {
        type: ActionTypes.SET_SELECTED_DVID,
        newDVID: newDVID,
    };
}

export const clear_selected_dvid = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_DVID,
    };
}

// Exported to vehicles actions script
export const set_device_assigned_status_to_true_by_dvid = (selectedDVID) => {
    if (selectedDVID) {
        return {
            type: ActionTypes.SET_DEVICE_ASSIGNED_STATUS_TO_TRUE_BY_DVID,
            selectedDVID: selectedDVID
        };
    }
}

// Exported to vehicles actions script
export const set_device_assigned_status_to_false_by_dvid = (selectedDVID) => {
    return {
        type: ActionTypes.SET_DEVICE_ASSIGNED_STATUS_TO_FALSE_BY_DVID,
        selectedDVID: selectedDVID
    };
}

// Exported to vehicles actions script
export const set_assigned_vehicle = (selectedDVID, selectedVID) => {
    return {
        type: ActionTypes.SET_ASSIGNED_VEHICLE,
        selectedDVID: selectedDVID,
        selectedVID: selectedVID,
    };
}

// Exported to vehicles actions script
export const clear_assigned_vehicle = (selectedDVID) => {
    return {
        type: ActionTypes.CLEAR_ASSIGNED_VEHICLE,
        selectedDVID: selectedDVID,
    };
}