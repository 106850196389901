import React, { useState, useEffect, useRef } from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux'

import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { getLatLngCenter } from "./methods/getLatLngCenter";
import {
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
} from "../../constants";
import {
    Polygon,
    GoogleMap,
    // OverlayView,
    DrawingManager,
    InfoWindow,
} from '@react-google-maps/api';

// Redux Actions
// import { set_map_action } from '../../services/redux/actions/mapControl';
import { set_drawn_geofence } from '../../services/redux/actions/mapDrawControl';

import "./gmaps.css";

const MapDrawPolygon = () => {
    const dispatch = useDispatch();

    const mapDrawControl = useSelector(state => state.mapDrawControl);

    const [mapRef, setMapRef] = useState(null);
    const [drawingManagerRef, setDrawingManagerRef] = useState(null);
    const [thisGeofence, setThisGeofence] = useState(mapDrawControl.drawnGeofence);
    const mapControl = useSelector(state => state.mapControl);
    const [showInfoWindow, setShowInfoWindow] = useState(true)

    /**
     * Deep Cloning useEffect()
     */
    const useDeepEffect = (fn, deps) => {
        const isFirst = useRef(true);
        const prevDeps = useRef(deps);

        useEffect(() => {
            const isSame = prevDeps.current.every((obj, index) =>
                JSON.parse(JSON.stringify(obj)) === JSON.parse(JSON.stringify(deps[index]))
            );

            if (isFirst.current || !isSame) {
                fn();
            }

            isFirst.current = false;
            prevDeps.current = deps;
        },
            [fn, deps]
        );
    }

    useDeepEffect(() => {
        setThisGeofence(mapDrawControl.drawnGeofence)
    },
        [mapDrawControl.drawnGeofence]
    )

    const getMapCenterAndZoom = () => {
        // console.log(mapControl)

        if (mapControl.userZoom && mapControl.userCenter) {
            return {
                zoom: mapControl.userZoom,
                center: mapControl.userCenter
            }
        }

        if (mapRef) {
            return {
                zoom: mapRef.getZoom(),
                center: mapRef.getCenter(),
            }
        }

        return {
            zoom: MAP_DEFAULT_ZOOM,
            center: MAP_DEFAULT_LOCATION,
        }
    }

    const putPolygonComplete = polygon => {
        // remove previously drawn polygon
        polygon.setMap(null);

        const newMarker = {
            ...mapDrawControl.drawnGeofence,
            coordinates: polygon.getPath().getArray().map(coordinate => {
                return {
                    lat: coordinate.lat(),
                    lng: coordinate.lng(),
                }
            })
        }

        dispatch(set_drawn_geofence(newMarker));

        // revert drawingMode to null, ie drag
        if (drawingManagerRef) {
            drawingManagerRef.setDrawingMode(null);
        }
        setShowInfoWindow(true)
    }

    return (
        <GoogleMap
            id='example-map'
            center={getMapCenterAndZoom().center}
            zoom={getMapCenterAndZoom().zoom}
            mapContainerStyle={{
                height: '100vh'
            }}
            onLoad={ref => setMapRef(ref)}
        >

            {/* Polygon */}
            {
                thisGeofence && thisGeofence.coordinates &&
                <div>
                    <Polygon
                        path={thisGeofence.coordinates}
                        options={{
                            strokeColor: "#ff0000"
                        }}
                        onLoad={() => {
                            if (window.google) {
                                const bounds = new window.google.maps.LatLngBounds();

                                thisGeofence.coordinates.map(currCoor => bounds.extend(currCoor));
                                mapRef.fitBounds(bounds);
                            }
                        }}
                    />
                    {/* Not working. Disabled. InfoWindow used instead */}
                    {/* 
                        <OverlayView
                            options = {{ disableAutoPan: true }}
                            position = {getLatLngCenter(thisGeofence.coordinates)}
                            mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                            getPixelPositionOffset = {(width, height) => ({
                                x: -(width / 2),
                                y: -height - 3,
                            })}
                        >
                            <div className = "geofenceMarkerOverlayView">
                                <h3>{thisGeofence.geofenceName}</h3>

                                {thisGeofence.comment && <div className = "geofenceMarkerOverlayViewComment">{thisGeofence.comment}</div>}
                            </div>
                        </OverlayView> */}

                    {
                        showInfoWindow && (
                            <InfoWindow
                                onCloseClick={() => {
                                    setShowInfoWindow(false)
                                }}
                                position={getLatLngCenter(thisGeofence.coordinates)}
                            >
                                <div className="geofenceMarkerOverlayView">
                                    <h3>{thisGeofence.geofenceName}</h3>

                                    {thisGeofence.comment && <div className="geofenceMarkerOverlayViewComment">{thisGeofence.comment}</div>}
                                </div>
                            </InfoWindow>
                        )
                    }
                </div>
            }

            {
                window.google &&
                <DrawingManager
                    onLoad={ref => setDrawingManagerRef(ref)}
                    drawingMode={window.google.maps.drawing.OverlayType.POLYGON}
                    options={{
                        drawingControlOptions: {
                            position: window.google.maps.ControlPosition.TOP_CENTER,
                            drawingModes: [
                                window.google.maps.drawing.OverlayType.POLYGON,
                            ],
                        },
                        polygonOptions: {
                            strokeColor: "#ff0000",
                        }
                    }}
                    onPolygonComplete={polygon => putPolygonComplete(polygon)}
                />
            }
        </GoogleMap>
    )
}

const MapComponent = (props) => GoogleMapsLoadScript(MapDrawPolygon, props);
export default MapComponent;