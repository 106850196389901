import React from "react";
import { connect } from "react-redux";
import {
    Route,
    Switch,
} from "react-router";

import PagesWithMaps from "../navigation/PagesWithMaps";
import asyncComponent from "../components/AsyncComponent";

// Pages
import MapDashboard from "../pages/MapDashboard";
// import Dashboard from "../pages/_Dashboard";
import StatusLog from "../pages/StatusLog";
import StatusGraph from "../pages/StatusGraph";
import StatusReport from "../pages/StatusReport";
import DeviceManagement from "../pages/DeviceManagement";
import VehicleManagement from "../pages/VehicleManagement";
import GeofenceManagement from "../pages/GeofenceManagement";
import GeofenceReport from "../pages/GeofenceReport";
// import EventLog from "../pages/EventLog";
import Profile from "../pages/Profile";

import AddVehicleDashboard from "../pages/_Dashboard/AddVehicle";
import AddDevice from "../pages/DeviceManagement/AddDevice";
import EditDevice from "../pages/DeviceManagement/EditDevice";
import AddVehicleVehicleManagement from "../pages/VehicleManagement/AddVehicle";
import EditVehicle from "../pages/VehicleManagement/EditVehicle";

import DeviceLogInspector from "../pages/DeviceManagement/DeviceLogInspector";
import AssignmentLogInspector from "../pages/VehicleManagement/AssignmentLogInspector";

const AsyncNotFoundPage = asyncComponent(() => import("../pages/NotFoundPage"));

const pagesWithMaps = [
    "/",
    "/EventLog",
    "/RoutePlayback",
    "/GeofenceManagement",
    "/GeofenceManagement/AddPOI",
    "/GeofenceManagement/AddPolygon",
    "/GeofenceManagement/EditPOI",
    "/GeofenceManagement/EditPolygon",
]

const MainRoutes = props => {
    return (
        <Switch>
            {
                pagesWithMaps.map(mr =>
                    <Route
                        key={mr}
                        exact path={mr}
                        render={routeProps => <PagesWithMaps {...props} {...routeProps} />}
                    />
                )
            }

            <Route
                exact path={"/"}
                render={routeProps => (
                    <MapDashboard {...props} {...routeProps} />
                )}
            />


            {/* <Route
                exact path={"/"}
                render={routeProps => (
                    <Dashboard {...props} {...routeProps} />
                )}
            /> */}

            <Route
                exact path={"/StatusLog"}
                render={routeProps => (
                    <StatusLog {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/StatusGraph"}
                render={routeProps => (
                    <StatusGraph {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/StatusReport"}
                render={routeProps => (
                    <StatusReport {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/DeviceManagement"}
                render={routeProps => (
                    <DeviceManagement {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/VehicleManagement"}
                render={routeProps => (
                    <VehicleManagement {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/VehicleManagement/AssignmentLogInspector"}
                render={routeProps => (
                    <AssignmentLogInspector {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/GeofenceManagement"}
                render={routeProps => (
                    <GeofenceManagement {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/GeofenceReport"}
                render={routeProps => (
                    <GeofenceReport {...props} {...routeProps} />
                )}
            />

            {/* <Route
                exact path={"/EventLog"}
                render={routeProps => (
                    <EventLog {...props} {...routeProps} />
                )}
            /> */}

            <Route
                exact path={"/Profile"}
                render={routeProps => (
                    <Profile {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/AddVehicle"}
                render={routeProps => (
                    <AddVehicleDashboard {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/DeviceManagement/AddDevice"}
                render={routeProps => (
                    <AddDevice {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/DeviceManagement/EditDevice"}
                render={routeProps => (
                    <EditDevice {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/VehicleManagement/AddVehicle"}
                render={routeProps => (
                    <AddVehicleVehicleManagement {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/VehicleManagement/EditVehicle"}
                render={routeProps => (
                    <EditVehicle {...props} {...routeProps} />
                )}
            />

            <Route
                exact path={"/DeviceManagement/DeviceLogInspector"}
                render={routeProps => (
                    <DeviceLogInspector {...props} {...routeProps} />
                )}
            />

            <Route component={AsyncNotFoundPage} />
        </Switch>
    )
}

export default connect(null)(MainRoutes);