import * as COLOR from "./color";
import * as IMAGE_RESOURCE from "./img";

import { config } from '../config';

const TEMPLATE_URLS = {
    BULK_UPLOAD_VEHICLES_TEMPLATE: `https://lp-templates-123123.s3-ap-southeast-1.amazonaws.com/Bulk%2BUpload%2BVehicles%2BTemplate.xlsx`,
    BULK_ALLOCATE_VEHICLES_TEMPLATE: `https://lp-templates-123123.s3-ap-southeast-1.amazonaws.com/Bulk%2BAllocate%2BVehicles%2BTemplate.xlsx`,
    BULK_PAIR_VEHICLES_TEMPLATE: `https://lp-templates-123123.s3-ap-southeast-1.amazonaws.com/Bulk+Pairing+Vehicle+Template.xlsx`,
    BULK_UPLOAD_DEVICES_TEMPLATE: `https://lp-templates-123123.s3-ap-southeast-1.amazonaws.com/Bulk+Upload+Devices+Template.xlsx`,
}

const VEHICLE_STATUS = {
    ALL: "ALL",
    EVENT: "EVENT",
    MOVING: "MOVING",
    IDLING: "IDLING",
    PARKING: "PARKING",
    OFFLINE: "OFFLINE",
    INACTIVE: "INACTIVE",
    STOP: "ENGINE STARTED",
    DISCONNECTED: "DISCONNECTED",
    NULL: "NULL", // In case vehicle transit status does not return truthy
};

const TRIP_STATUS = {
    TRANSIT:`TRANSIT`,
    PARKING: `PARKING`,
}

const VEHICLE_PIPELINE_STATUS = {
    CREATED: 'CREATED',
    INITIATED: 'INITIATED',
    ACC: 'ACC',
    YARD: 'YARD',
    // ALLOCATED: 'ALLOCATED',
    STAGED: 'STAGED',
    LOADED: 'LOADED',
    OUTLET: 'OUTLET',
    COMPLETED: 'COMPLETED',
}


const DEVICE_STATUS = {
    CONNECTED: 'CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    TEMPERED: 'TEMPERED'
}

const PERODUA_VEHICLE_MODELS = ['Axia', 'Aruz', 'Myvi', 'Bezza', 'Alza', 'Others']

// const VEHICLE_MODELS = {
//     PERODUA_MYVI_EZI: "Perodua Myvi EZi",
//     PERODUA_BEZZA: "Perodua Bezza",
//     PERODUA_MYVI: "Perodua Myvi",
//     PERODUA_RUSA: "Perodua Rusa",
//     PERODUA_VIVA: "Perodua Viva",
//     PERODUA_ALZA: "Perodua Alza",
//     PERODUA_AXIA: "Perodua Axia",
//     PERODUA_KANCIL: "Perodua Kancil",
//     PERODUA_KELISA: "Perodua Kelisa",
//     PERODUA_KENARI: "Perodua Kenari",
// };

// const MAP = {
//     MARKER: 'marker',
//     CIRCLE: 'circle',
//     POLYGON: 'polygon',
//     MINZOOM: 3
// }

const MAP_DEFAULT_ZOOM = 17;

const MAP_DEFAULT_LOCATION = {
    lat: 3.37084089883934,
    lng: 101.58783271490563
};

const VEHICLE_COLOR = {
    [VEHICLE_STATUS.ALL]: "#C4C4C4",
    [VEHICLE_STATUS.STOP]: "#856B37", //old color: #2A2AFF
    [VEHICLE_STATUS.EVENT]: "#81201B", //old color: #2A2AFF
    [VEHICLE_STATUS.MOVING]: "#52C41A", //old color: #37c837
    [VEHICLE_STATUS.IDLING]: "#FFB517", //old color: #7F2AFF
    [VEHICLE_STATUS.PARKING]: "#0488DB", //old color: #D4FF2A
    [VEHICLE_STATUS.OFFLINE]: "#333333", //black
    [VEHICLE_STATUS.INACTIVE]: "#ff7f2a", //orange
    [VEHICLE_STATUS.DISCONNECTED]: "#81201B", 
    [VEHICLE_STATUS.NULL]: "#333333", //black
};

// const VEHICLE_TYPE = [
//     { label: "Bus", value: "Bus" },
//     { label: "Four Wheel Drive", value: "4x4Drive" },
//     { label: "Motocar", value: "Motocar" },
//     { label: "Motocycle", value: "Motocycle" },
//     { label: "Taxi", value: "Taxi" },
//     { label: "Truck", value: "Truck" },
//     { label: "Van", value: "Van" },
//     { label: "Other", value: "Other" },
// ];

// const STATUS_ICONS = {
//     [VEHICLE_STATUS.ALL]: "icon_all.svg",
//     [VEHICLE_STATUS.PARKING]: "icon_parking.svg",
//     [VEHICLE_STATUS.DISCONNECTED]: "icon_start.svg",
//     [VEHICLE_STATUS.MOVING]: "iconmonstr-navigation-1.svg",
//     [VEHICLE_STATUS.IDLING]: "iconmonstr-media-control-49.svg",
// }

const FEATURE = {
    // DEV: "Dev",
    // POI: "POI",
    // USERS: "Users",
    // ABOUT: "About",
    // CASES: "Cases",
    // REPORT: "Report",
    // PROFILE: "Profile",
    // GEOFENCE: "Geofence",
    // MANAGEMENT: "Management",
    // PREFERENCES: "references",
    // EVENTRECORD: "EventRecord",
    // FLEET_RECORD: "FleetRecord",
    // CALLED_REPORT: "CalledReport",
    // NOTIFICATIONS: "Notifications",
    // FLEET_TRACKING: "FleetTracking",
    // USER_MANAGEMENT: "UserManagement",
    // GEOFENCE_REPORT: "GeofenceReport",
    // VEHICLE_DISPLAY: "VehicleDisplay",
    // VEHICLE_ACTIVITY: "VehicleActivity",
    // OPERATOR_ACCOUNT: "OperatorAccount",
    // GOSUN_INSPECTOR: "GosunDataInspector",
    // DEVICE_MANAGEMENT: "DeviceManagement",
    // VEHICLE_MANAGEMENT: "VehicleManagement",
    // CONFIGURATION_MANAGEMENT: "Configuration",
    // FUEL_RECORD: "FuelRecord",
    // DEVICE_INSPECTOR: "DeviceInspector",

    REPORT: "Report",
    PROFILE: "Profile",
    EVENT_LOG: "EventLog",
    STATUS_LOG: "StatusLog",
    ROUTE_PLAYBACK: "RoutePlayback",
    STATUS_GRAPH: "StatusGraph",
    STATUS_REPORT: "StatusReport",
    GEOFENCE_REPORT: "GeofenceReport",
    DEVICE_MANAGEMENT: "DeviceManagement",
    VEHICLE_MANAGEMENT: "VehicleManagement",
    GEOFENCE_MANAGEMENT: "GeofenceManagement",
};

const COMPANY = {
    NAME: 'Celcom',
    ADDRESS: 'Not Available'
}

const SCREEN_LAYOUT = {
    FULL_SIDEBAR_WIDTH: 400, // window.innerWidth*0.2, //Usual 400px,
    EXPAND_SIDEBAR_WIDTH: 700 // window.innerWidth*0.3 //Usual 400px,
}

 const ROLE_TYPE = {
    SUPER: "super",
    SUPPORT: "support",
    FLEET_OPERATOR: "fleetOperator",
    FLEET_OWNER: "fleetOwner",
    DISTRIBUTOR: "distributor",
    FLEET_MANAGER: "fleetManager",
    UNKNOWN: "Unknown",
};

const ROLE_DEFINE = {
    SUPER: "Admin",
    DISTRIBUTOR: "Distributor",
    SUPPORT: "Support",
    FLEET_OWNER: "Fleet Owner",
    FLEET_MANAGER: "Fleet Manager",
    FLEET_OPERATOR: "Fleet Operator",
    UNKNOWN: "Unknown"
}

const FUEL_EVENT_TYPE = {
    REFUEL: "isRefuel",
    MOVING: "isMoving",
    IDLING: "isIdling",
    FUEL_LOSS: "isFuelLoss",
}

switch(config.project) {
    case 'gpsfleetMY' : {
        ROLE_TYPE.SUPER = 'super';
        ROLE_TYPE.DISTRIBUTOR = "distributor";
        ROLE_DEFINE.SUPER = "Admin";
        ROLE_DEFINE.DISTRIBUTOR = "Distributor";
        break;
    }
    case 'gpsfleetLAB' : {
        ROLE_TYPE.SUPER = 'super';
        ROLE_TYPE.DISTRIBUTOR = "distributor";
        ROLE_DEFINE.SUPER = "Admin";
        ROLE_DEFINE.DISTRIBUTOR = "Distributor";
        break;
    }
  
    default: {
        break;
    }
}

const DISPLAY_USER_ROLE = (userRole) => {
    if (userRole === ROLE_TYPE.FLEET_OPERATOR) {
        return ROLE_DEFINE.FLEET_OPERATOR;
    }
    else if (userRole === ROLE_TYPE.FLEET_MANAGER) {
        return ROLE_DEFINE.FLEET_MANAGER;
    }
    else if (userRole === ROLE_TYPE.FLEET_OWNER) {
        return ROLE_DEFINE.FLEET_OWNER;
    }
    else if (userRole === ROLE_TYPE.DISTRIBUTOR) {
        return ROLE_DEFINE.DISTRIBUTOR;
    }
    else if (userRole === ROLE_TYPE.SUPER) {
        return ROLE_DEFINE.SUPER;
    }
}

const RULE_TYPE = {
    INFO : "INFO",
    WARNING: "WARNING",
    CRITICAL: "CRITICAL",
    CONTROL: "CONTROL"
}

const VEHICLE_GROUP_TYPES = {
    AREA: "AREA",
    OUTLET: "OUTLET"
}

const PRODUCTION_STATUS = {
    ACC: "ACC",
    STAGED: "STAGED",
    LOADED: "LOADED",
    // ALLOCATED: "ALLOCATED",
}

const DISPLAY_RULE_TYPE = {
    0: "INFO",
    1: "WARNING",
    2: "CRITICAL",
}

const PROGRESS_TYPES = {
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
}

const PROCESS_STATUS = {
    CREATED: "CREATED",
    INITIATED: "INITIATED",
    ACC: "ACC",
    YARD: "YARD",
    // ALLOCATED: "ALLOCATED",
    STAGED: "STAGED",
    LOADED: "LOADED",
    OUTLET: "OUTLET",
    COMPLETED: "COMPLETED",
}

const EVENT_TYPES = {
    ALL: "ALL", 
    INFO: "INFO", 
    WARNING: "WARNING", 
    CRITICAL: "CRITICAL", 
    CONTROL: "CONTROL"
}

const EVENT_COLORS = {
    [EVENT_TYPES.ALL]: "#C4C4C4",
    [EVENT_TYPES.INFO]: "#52C41A",
    [EVENT_TYPES.CONTROL]: "#856B37",
    [EVENT_TYPES.WARNING]: "#FFB517",
    [EVENT_TYPES.CRITICAL]: "#0488DB",
    [undefined]: "black",
}

const TRANSIT_TYPES = {
    TRANSIT: "TRANSIT", 
    PARKING: "PARKING", 
    DISCONNECTED: "DISCONNECTED",
}

const TRANSIT_COLORS = {
    [TRANSIT_TYPES.TRANSIT]: "#52C41A",
    [TRANSIT_TYPES.PARKING]: "#0091D4",
    [TRANSIT_TYPES.DISCONNECTED]: "black",
    [undefined]: "black",
}

// console.log("App is running in mode 1")

const LOCATION = {
    lat: 4.527157,
    lng: 102.213207
}

const GEOFENCE_COLORS = [
    "#5C1A8E", // This is default and therefore first
    "#FF0000",
    "#00FF00",
    "#00FFE8",
    "#FF00FF",
    "#FFA500",
    "#FFFF00",
]

const DEFAULT_GEOFENCE_HEXCODE = GEOFENCE_COLORS[0];

export {
    // MAP,
    TEMPLATE_URLS,
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
    COLOR,
    COMPANY,
    FEATURE,
    ROLE_TYPE,
    RULE_TYPE,
    SCREEN_LAYOUT,
    // VEHICLE_TYPE,
    VEHICLE_COLOR,
    VEHICLE_STATUS,
    VEHICLE_PIPELINE_STATUS,
    DEVICE_STATUS,
    PERODUA_VEHICLE_MODELS,
    // STATUS_ICONS,
    IMAGE_RESOURCE,
    DISPLAY_USER_ROLE,
    FUEL_EVENT_TYPE,
    VEHICLE_GROUP_TYPES,
    DISPLAY_RULE_TYPE,
    PRODUCTION_STATUS,
    PROGRESS_TYPES,
    PROCESS_STATUS,
    EVENT_TYPES,
    EVENT_COLORS,
    TRANSIT_TYPES,
    TRANSIT_COLORS,
    TRIP_STATUS,
    LOCATION,
    // Geofence Colors
    GEOFENCE_COLORS,
    DEFAULT_GEOFENCE_HEXCODE,
}
