import 
  React, 
  { 
    useEffect
  } 
from 'react';
import { useDispatch, useSelector} from 'react-redux'

import firebase from "firebase/app";
import Layout from "./components/Layout";
import AppRoutes from "./navigation/AppRoutes";
import MainRoutes from "./navigation/MainRoutes";

// import { Menu } from "antd";
// import { moveToMenuSelection } from "./navigation/navigationService";
import {
  // ROLE_TYPE,
  SCREEN_LAYOUT
} from './constants';

// Redux Actions
import { user_sign_in_request } from './services/redux/actions/user';

import './App.css';
import './index.css';
import BackgroundTask from './components/BackgroundTask';

const App = (props) => {

  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const style = useSelector(state => state.style);

  useEffect(() => {
    dispatch(user_sign_in_request(firebase.auth().currentUser.uid));
  }, [dispatch])


  return (
    <div>
      <BackgroundTask />
      <Layout user = {(user && user.user) || {}}>
      <div className = "app-frame">
        { 
          style && style.hasOwnProperty('isSidebarCollapsed') && !style.isSidebarCollapsed && 
            <div
              className = "sidebar card animate-left"
              id = "SideBar"
              style = {{ width: SCREEN_LAYOUT.FULL_SIDEBAR_WIDTH }}
            >
              <div className = "sidebar-content-container">
                <AppRoutes/>
              </div>
            </div>
        }

        <div className = "app-content">
          <MainRoutes {...props}/>
        </div>
      </div>
    </Layout>
    </div>
  );
}

export default App;
