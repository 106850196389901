export const ENABLE_TRIP_RECORD = "ENABLE_TRIP_RECORD";
export const DISABLE_TRIP_RECORD = "DISABLE_TRIP_RECORD";

export const ENABLE_TRANSIT_RECORD = "ENABLE_TRANSIT_RECORD";
export const DISABLE_TRANSIT_RECORD = "DISABLE_TRANSIT_RECORD";

export const STORE_SEARCH_DETAILS = "STORE_SEARCH_DETAILS";
export const CLEAR_SEARCH_DETAILS = "CLEAR_SEARCH_DETAILS";

export const SET_SELECTED_TRIP_DETAILS = "SET_SELECTED_TRIP_DETAILS";
export const CLEAR_SELECTED_TRIP_DETAILS = "CLEAR_SELECTED_TRIP_DETAILS";

export const STORE_TRIP_TIMELINE_DATA = "STORE_TRIP_TIMELINE_DATA";
export const CLEAR_TRIP_TIMELINE_DATA = "CLEAR_TRIP_TIMELINE_DATA";

export const STORE_TRANSIT_TIMELINE_DATA = "STORE_TRANSIT_TIMELINE_DATA";
export const CLEAR_TRANSIT_TIMELINE_DATA = "CLEAR_TRANSIT_TIMELINE_DATA";

export const SET_SELECTED_TRID = "SET_SELECTED_TRID";
export const CLEAR_SELECTED_TRID = "CLEAR_SELECTED_TRID";

export const SET_SELECTED_TSID = "SET_SELECTED_TSID";
export const CLEAR_SELECTED_TSID = "CLEAR_SELECTED_TSID";