import React from "react";
import "../../../App.css";
import { connect } from "react-redux";

import {
    COLOR
} from "../../../constants";
import { Form, Input, Button } from "antd";
import {
    UserOutlined,
    LockOutlined
} from '@ant-design/icons'

class LoginForm extends React.Component {

    state = {
        email: "",
        password: ""
    };

    render() {

        return (
            <Form className="login-form">
            <Form.Item style={{ marginBottom: "15px" }}>
                <Input
                    prefix={
                        <UserOutlined 
                            style={{ color: "rgba(0,0,0,.25)" }}
                        />
                    }
                    placeholder="Email"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                />
            </Form.Item>
            <Form.Item style={{ marginBottom: "15px" }}>
                <Input
                    prefix={
                        <LockOutlined 
                            style={{ color: "rgba(0,0,0,.25)" }}
                        />
                    }
                    type="password"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={e =>
                        this.setState({ password: e.target.value })
                    }
                 />
             </Form.Item>
             <Form.Item>
                <Button
                    style={{
                        width: "100%",
                        backgroundColor: COLOR.BRAND_PRIMARY,
                        border: "none",
                        boxShadow: "2px 2px 5px #696969"
                    }}
                    type="primary"
                    className="login-form-button"
                    onClick={() =>
                        this.props.onLoginPress(
                            this.state.email,
                            this.state.password
                        )
                    }
                    loading={this.props.style.isLoadingSubmit}
                >
                        LOG IN
                </Button>
            </Form.Item>
            <Form.Item>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between"
                    }}
                >
                    {/* <div>
                        <div
                            className="link-button"
                            onClick={e => {
                                // this.props.dispatch(
                                //     moveToMenuSelection("SignUp")
                                // );
                            }}
                            style={{ color: "black" }}
                        >
                            Interested?
                        </div>
                    </div>

                    <div>
                        <div className="link-button" style={{ color: "black" }}>
                            Forgot Password?
                        </div>
                    </div> */}
                </div>
            </Form.Item>
        </Form>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.style,
});

export default connect(mapStateToProps)(LoginForm)