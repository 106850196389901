import * as ActionTypes from "../action-types/assignmentLogs";

const defaultState = {
    byVID: {},
}

export const assignmentLogs = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_ASSIGNMENT_LOG: {
            newState.byVID = {}

            action.assignmentLogs.forEach((log) => {
                if (!newState.byVID[log.vid]) {
                    newState.byVID[log.vid] = [];
                }

                newState.byVID[log.vid].push(log);
            })

            return newState;
        }

        case ActionTypes.CLEAR_ASSIGNMENT_LOG: {
            // newState.byVID = defaultState.byVID;
            newState.byVID = {}
            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Assignment Log Store:", defaultState);

            return defaultState;
        }

        default: {
            return state;
        }
    }
};
