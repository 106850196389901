import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const addVehicle = (uid, newVehicle) => (
    fetch(`${api}/vehicle/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid" : uid,
            "vehicle" : newVehicle,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const editVehicle = (uid, editedVehicle) => (
    fetch(`${api}/vehicle/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid" : uid,
            "vehicle" : editedVehicle,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const allocateVehicleToGroups = (uid, vid, outlet, area, allocatedDate) => (
    fetch(`${api}/vehicle/group/reassign`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid" : uid,
            "vid" : vid,
            "outlet": outlet,
            "area": area,
            "allocatedDate": allocatedDate,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const assignDeviceToVehicle = (uid, vid, dvid) => (
    fetch(`${api}/vehicle/assign/device`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid" : uid,
            "vid" : vid,
            "dvid" : dvid,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const unassignDevices = (dvids, uid) => (
    fetch(`${api}/device/unassign`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "dvids" : dvids,
            "uid" : uid,
        })
    })
    .then(res => res.json())
    .then(data => data)
)