import * as ActionTypes from "../action-types/geofences";

const defaultState = {
    byGEOID: {},

    selectedGEOID: "",
    selectedId: null
}

export const geofences = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_GEOFENCES: {
            newState.byGEOID = JSON.parse(JSON.stringify(defaultState.byGEOID)); // Clear newState byGEOID
            
            action.geofences.forEach((currGeofence) => newState.byGEOID[currGeofence.geoid] = currGeofence);

            return newState;
        }

        case ActionTypes.ADD_GEOFENCE: {       
            newState.byGEOID[action.newGeofence.geoid] = action.newGeofence;

            return newState;
        }

        case ActionTypes.EDIT_GEOFENCE: {       
            newState.byGEOID[action.editedGeofence.geoid] = action.editedGeofence;

            return newState;
        }

        case ActionTypes.SET_SELECTED_GEOID: {       
            newState.selectedGEOID = action.selectedGEOID;

            return newState;
        }

        case ActionTypes.CLEAR_SELECTED_GEOID: {
            newState.selectedGEOID = JSON.parse(JSON.stringify(defaultState.selectedGEOID)); // Clear newState selectedGEOID

            return newState;
        }

        case ActionTypes.SET_SELECTED_GEOFENCE_GEOID: {
            // console.log("select Id", action.dvid)
            return { ...state, selectedId: action.geoid }
        }

        case ActionTypes.CLEAR_SELECTED_GEOFENCE_GEOID: {
            let newState = JSON.parse(JSON.stringify(state));
            return { ...newState, selectedId: null }
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Geofence Store:", defaultState);

            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}