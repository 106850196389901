import * as ActionTypes from "../action-types/mapControl";

export const set_map_control = (control) => {
  // console.log('set_map_control: ', control)

  return {
    type: ActionTypes.SET_MAP_CONTROL,
    control: control
  }
}

export const set_map_action = (newAction) => {
  return {
    type: ActionTypes.SET_MAP_STATUS,
    newAction: newAction
  }
}

export const set_map_selected_evid = (newselectedEVID) => {  
  return {
    type: ActionTypes.SET_MAP_SELECTED_EVID,
    newselectedEVID: newselectedEVID
  }
}

export const clear_map_selected_evid = () => {  
  return {
    type: ActionTypes.CLEAR_MAP_SELECTED_EVID,
  }
}

export const set_map_playback_marker_location = location => {
  return {
    type: ActionTypes.SET_MAP_PLAYBACK_MARKER_LOCATION,
    location: location
  }
}

export const set_map_selected_trid = (newSelectedTrID) => {  
  return {
    type: ActionTypes.SET_MAP_SELECTED_TRID,
    newSelectedTrID: newSelectedTrID
  }
}

export const clear_map_selected_trid = () => {  
  return {
    type: ActionTypes.CLEAR_MAP_SELECTED_TRID,
  }
}

export const set_map_selected_geoid = (newselectedGEOID) => {  
  return {
    type: ActionTypes.SET_MAP_SELECTED_GEOID,
    newselectedGEOID: newselectedGEOID
  }
}


export const clear_map_selected_geoid = () => {  
  return {
    type: ActionTypes.CLEAR_MAP_SELECTED_GEOID,
  }
}

export const set_new_geofence_from_map = (geofence) => {
  // console.log("Geofence", geofence);

  return {
    type: ActionTypes.SET_NEW_GEOFENCE,
    geofence: geofence
  }
}

export const clear_new_geofence_from_map = () => {
  return {
    type:  ActionTypes.CLEAR_GEOFENCE
  }
}

export const set_selected_geofence_templates_for_map = (geofenceTemplateList) => {
  // console.log("Geofence Template List:", geofenceTemplateList);

  return {
    type: ActionTypes.SET_SELECTED_GEOFENCE_TEMPLATES,
    geofenceTemplateList,
  }
}

export const clear_selected_geofence_templates_for_map = () => {
  return {
    type: ActionTypes.CLEAR_SELECTED_GEOFENCE_TEMPLATES,
  }
}

export const set_user_zoom_and_center = (userZoom, userCenter) => {  
  return {
    type: ActionTypes.SET_USER_ZOOM_AND_CENTER,
    userCenter,
    userZoom
  }
}

export const clear_user_zoom_and_center = () => {  
  return {
    type: ActionTypes.CLEAR_USER_ZOOM_AND_CENTER,
  }
}
export const set_map_filters = (durationMin, selectedEventTypes, selectedTransitTypes) => {
  // console.log("Setting Map Filters:", durationMin, selectedEventTypes, selectedTransitTypes);

  return {
    type: ActionTypes.SET_MAP_FILTERS,
    durationMin: durationMin,
    selectedEventTypes: selectedEventTypes,
    selectedTransitTypes: selectedTransitTypes,
  }
}

export const add_enabled_vehicle_pop_up = (enabledIdentifier) => {
  return {
    type: ActionTypes.ADD_ENABLED_VEHICLE_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const remove_enabled_vehicle_pop_up = (enabledIdentifier) => {
  return {
    type: ActionTypes.REMOVE_ENABLED_VEHICLE_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const clear_enabled_vehicle_pop_ups = () => {
  return {
    type: ActionTypes.CLEAR_ENABLED_VEHICLE_POP_UP,
  }
}

export const add_enabled_event_pop_up = (enabledIdentifier) => {
  return {
    type: ActionTypes.ADD_ENABLED_EVENT_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const remove_enabled_event_pop_up = (enabledIdentifier) => {
  return {
    type: ActionTypes.REMOVE_ENABLED_EVENT_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const clear_enabled_event_pop_ups = () => {
  return {
    type: ActionTypes.CLEAR_ENABLED_EVENT_POP_UPS,
  }
}

export const add_enabled_geofence_pop_up = (enabledIdentifier) => {
  return {
    type: ActionTypes.ADD_ENABLED_GEOFENCE_POP_UP,
    enabledIdentifier: enabledIdentifier
  }
}

export const clear_enabled_geofence_pop_ups = () => {
  return {
    type: ActionTypes.CLEAR_ENABLED_GEOFENCE_POP_UP,
  }
}

export const enable_show_vehicle_info = () => {
  return {
    type: ActionTypes.ENABLE_SHOW_VEHICLE_INFO,
  }
}

export const disable_show_vehicle_info = () => {
  return {
    type: ActionTypes.DISABLE_SHOW_VEHICLE_INFO,
  }
}

export const enable_show_geofence_info = () => {
  return {
    type: ActionTypes.ENABLE_SHOW_GEOFENCE_INFO,
  }
}

export const disable_show_geofence_info = () => {
  return {
    type: ActionTypes.DISABLE_SHOW_GEOFENCE_INFO,
  }
}

export const enable_show_geofences = () => {
  return {
    type: ActionTypes.ENABLE_SHOW_GEOFENCES,
  }
}

export const disable_show_geofences = () => {
  return {
    type: ActionTypes.DISABLE_SHOW_GEOFENCES,
  }
}

export const enable_show_live_traffic = () => {
  return {
    type: ActionTypes.ENABLE_SHOW_LIVE_TRAFFIC,
  }
}

export const disable_show_live_traffic = () => {
  return {
    type: ActionTypes.DISABLE_SHOW_LIVE_TRAFFIC,
  }
}

export const enable_show_vehicle_trail = () => {
  return {
    type: ActionTypes.ENABLE_SHOW_VEHICLE_TRAIL,
  }
}

export const disable_show_vehicle_trail = () => {
  return {
    type: ActionTypes.DISABLE_SHOW_VEHICLE_TRAIL,
  }
}

export const reset_ui_control = () => {
  return {
    type: ActionTypes.RESET_UI_CONTROL,
  }
}