import React, { Component } from "react";
import { connect } from "react-redux";

import Page from "../../../components/Page";
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from "../../../components/PrimaryButton";
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    Form,
    Input,
    Button,
    message,
} from "antd";

// Redux Actions
import { add_device } from "../../../services/redux/actions/devices";

const FormItem = Form.Item;

class AddDevice extends Component {
    state = {
        simCard: "+6",
        simSerial: "",
        deviceImei: "",
    }

    isImeiExist = (imei) => {

        const isExist = Object.keys(this.props.devices.byDVID).find(dvid => dvid.includes(imei));

        if (isExist) {
            throw new Error(`Imei already exist`);
        }
    }

    submitForm = () => {
        const {
            simCard,
            deviceImei
        } = this.state

        try {

            if (!deviceImei) throw new Error(`Device Imei cannot be blank`)
            if (this.isImeiExist(deviceImei)) throw new Error(`Device Imei is already used. Must be unique`)
            if (!simCard) throw new Error(`Mobile Number cannot be blank`)

            const newDevice = {
                imei: this.state.deviceImei,
                simCard: this.state.simCard,
                simSerial: this.state.simSerial,
            }

            this.props.dispatch(add_device(this.props.user.uid, newDevice));

        } catch (err) {
            message.error(err.message || 'Something went wrong')
        }
    }

    // componentDidUpdate = (prevProps) => {
    //     // Leave page once add device API returns
    //     if (prevProps.devices !== this.props.devices) {
    //         this.props.dispatch(goBackToPrev());
    //     }
    // }

    render() {
        return (
            <div className="page-container">
                <Page title="Add New Device">
                    <div style={{ padding: "30px 20px 0px 20px" }}>
                        <Form className="login-form">
                            <FormItem
                                label="Device Imei"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder="IMEI Series No."
                                    value={this.state.deviceImei}
                                    onChange={e =>
                                        this.setState({
                                            ...this.state,
                                            deviceImei: e.target.value,
                                        })
                                    }
                                />
                            </FormItem>

                            <FormItem
                                label="Mobile Number"
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                                required
                            >
                                <Input
                                    placeholder="+60123456789"
                                    value={this.state.simCard}
                                    onChange={e => {
                                        if (e.target.value.length < 2) {
                                            this.setState({
                                                ...this.state,
                                                simCard: "+6",
                                            })
                                        }
                                        else {
                                            this.setState({
                                                ...this.state,
                                                simCard: e.target.value,
                                            })
                                        }
                                    }}
                                />
                            </FormItem>

                            <FormItem
                                label=' Sim Serial Number'
                                labelCol={{ span: 7 }}
                                wrapperCol={{ span: 15 }}
                            >
                                <Input
                                    placeholder='Enter device sim card serial number (optional)'
                                    value={this.state.simSerial}
                                    onChange={e =>
                                        this.setState({ simSerial: e.target.value })
                                    }
                                />
                            </FormItem>

                            <div style={{ position: "absolute", right: "100px" }}>
                                <Button
                                    type="danger"
                                    className="login-form-button"
                                    onClick={() => this.props.dispatch(goBackToPrev())}
                                >
                                    Cancel
                                </Button>

                                <PrimaryButton loading={this.props.style.isLoadingSubmit} onClick={this.submitForm}>
                                    {!this.props.style.isLoadingSubmit ? "Add New" : "Adding..."}
                                </PrimaryButton>
                            </div>
                        </Form>
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(AddDevice));