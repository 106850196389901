import * as ActionTypes from "../action-types/user";

const defaultState = {
    // uid: "",
    // userRole: "",
    // userName: "",
    // userEmail: "",
    // userAddress: "",
    // userContact: "",
    // createdAt: 0,
}

export const user = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.USER_SIGN_IN: {            
            return({
                ...newState,
                ...action.user
            });
        }

        // case ActionTypes.USER_SIGN_OUT: {
            
        // }
        
        default: {
            return newState;
        }
    }
}