import * as ActionTypes from "../action-types/mapDrawControl";

const defaultState = {
    // For Add/Edit Geofence
    drawnGeofence: {},
    polygonOnMapList: [], // To clear out polygons that are drawn by react-google-map's drawing manager
};

export const mapDrawControl = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch(action.type) {
        case ActionTypes.SET_DRAWN_GEOFENCE: {
            newState.drawnGeofence = action.newDrawnGeofence;

            return newState;
        }

        case ActionTypes.CLEAR_DRAWN_GEOFENCE: {
            newState.polygonOnMapList = []; // Clear array
            newState.drawnGeofence = defaultState.drawnGeofence;

            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Map Draw Control Store:", defaultState);
    
            return defaultState;
        }

        default: {
            return state;
        }
    }
}