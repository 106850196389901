import React, { Component } from "react";
import { connect } from "react-redux";

import Layout from "./Layout";
import PropTypes from "prop-types";

import { FEATURE } from "../../constants";
import { buildAbility } from "../../services/auth/accessRight";
import { moveToPage } from "../../navigation/navigationService";

import {
  ApiOutlined,
  CarOutlined,
  ReconciliationOutlined,
  LineChartOutlined,
  EnvironmentOutlined,
  UserOutlined,
  HistoryOutlined,
  PieChartOutlined,
  DatabaseOutlined,
  ContainerOutlined
} from '@ant-design/icons'

class LayoutContainer extends Component {
  static propTypes = {
    children: PropTypes.element
  };

  navigateTo = pageKey => this.props.dispatch(moveToPage(pageKey));

  render() {
    const { 
      user, 
      location 
    } = this.props;

    const sidebarItems = [
      
      {
        key: `/${FEATURE.VEHICLE_MANAGEMENT}`,
        icon: <CarOutlined style = {{ fontSize: 24 }}/>,
        label: "Vehicle Management"
      },

      {
        key: `/${FEATURE.DEVICE_MANAGEMENT}`,
        icon: <ApiOutlined style = {{ fontSize: 24 }}/>,
        label: "Device Management"
      },

      {
        key: `/${FEATURE.GEOFENCE_MANAGEMENT}`,
        icon: <EnvironmentOutlined style = {{ fontSize: 24 }}/>,
        label: "Geofence Management"
      },

      {
        key: `/${FEATURE.GEOFENCE_REPORT}`,
        icon: <ContainerOutlined style = {{ fontSize: 24 }}/>,
        label: "Geofence Report"
      },

      {
        key: `/${FEATURE.EVENT_LOG}`,
        icon: <PieChartOutlined style = {{ fontSize: 24 }}/>,
        label: "Event Log"
      },

      {
        key: `/${FEATURE.ROUTE_PLAYBACK}`,
        icon: <HistoryOutlined style = {{ fontSize: 24 }}/>,
        label: "Route Playback"
      },
      {
        key: `/${FEATURE.STATUS_LOG}`,
        icon: <ReconciliationOutlined style = {{ fontSize: 24 }}/>,
        label: "Vehicle Logistical Process Status Log"
      },

      {
        key: `/${FEATURE.STATUS_GRAPH}`,
        icon: <LineChartOutlined style = {{ fontSize: 24 }}/>,
        label: "Vehicle Logistical Process Status Graph"
      },

      {
        key: `/${FEATURE.STATUS_REPORT}`,
        icon: <DatabaseOutlined style = {{ fontSize: 24 }}/>,
        label: "Vehicle Logistical Process Status Report"
      },

      {
        key: `/${FEATURE.PROFILE}`,
        icon: <UserOutlined style = {{ fontSize: 24 }}/>,
        label: "Profile"
      },
    ];

    let items = sidebarItems.filter(listItem => {
      if(user && user.userRole)
        return buildAbility(user.userRole).can("read", listItem.key.slice(1))
      else
        return buildAbility('NONE').can("read", listItem.key.slice(1))
    });

    return (
      <Layout
        sidebarItems = {items}
        selectedKeys = {location ? location.pathname : ""}
        themeColor = {(this.props.style && this.props.style.sidebarColor)|| "#fff"}
        onClickSidebarItem = {sidebarItem => this.navigateTo(sidebarItem.key|| "/")}
      >
        {this.props.children}
      </Layout>
    );
  }
}

const mapStateToProps = (state)=> ({
  user: state.user,
  style: state.style,
});

export default connect(mapStateToProps)(LayoutContainer);