import 
    React, 
    { 
        useState, 
        useEffect,
        useCallback
    } 
from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';

// import MapSearchBar from '../MapSearchBar';
// import MapControlPanel from '../MapControlPanel';
// import POIGeofences from "./components/POIGeofences";
// import VehicleMarkers from "./components/VehicleMarkers";
// import PolygonGeofences from "./components/PolygonGeofences";
import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { 
    // message, 
    Descriptions 
} from "antd";
import {
    GoogleMap, 
    Marker, 
    Polygon, 
    InfoWindow, 
    // OverlayView,
    // TrafficLayer,
} from '@react-google-maps/api';
import {
    MAP_DEFAULT_ZOOM,
    MAP_DEFAULT_LOCATION,
    // VEHICLE_PIPELINE_STATUS,
    DEVICE_STATUS,
} from "../../constants";

import "./gmaps.css";
import { moveToPage } from '../../navigation/navigationService';
import { getLatLngCenter } from './methods/getLatLngCenter';
import parseTime from '../ParseTime';

/**
 * This map component handles what to show on map, where, based on router.location.state
 * 
 * router.location.state = {
 *      ...
 *      lat,    // lat of current map center
 *      lng,    // lng of current map center
 *      zoom,   // zoom of current map center
 *      vid,    //vehicle to show infowindow on map
 *      geoid,  //geofence to show infowindow on map
 * }
 * @param {} props 
 */

const INTERACT_MODES = {
    USER: 'USER',
    MAP: 'MAP'
}

const UrlMap = (props) => {
    const geofences = useSelector(state => state.geofences);
    const vehicles = useSelector(state => state.vehicles)
    const vehicleGroups = useSelector(state => state.vehicleGroups)
    const devices = useSelector(state => state.devices)
    const router = useSelector(state => state.router)
    // const state = useSelector(state => state)

    const dispatch = useDispatch();

    const [mapRef, setMapRef] = useState(null);
    const [interactMode, setInteractMode] = useState(INTERACT_MODES.MAP)

    /**
     * Util function
     * Add params to page without overwriting existing state
     */
    function moveToPageWithState(state) {
        const newState = {
            ...router.location.state || {},
            ...state
        }
        dispatch(moveToPage(`/`, newState))
    }

    const setUserUpdatedMapPosition = () => {
        if (!mapRef) return

        setInteractMode(INTERACT_MODES.USER)

        const mapRefCenter = mapRef.getCenter()
        const zoom = mapRef.getZoom()
        const lat = mapRefCenter.lat()
        const lng = mapRefCenter.lng()

        const state = {
            zoom, lat, lng
        }

        moveToPageWithState(state)
    }

    const setInitialMapPosition = useCallback(
        () => {
            let { lat, lng, zoom } = router.location.state || {}
            // console.log(`setInitialMapCenter: `, { lat, lng, zoom })
    
            lat = Number(lat)
            lng = Number(lng)
            zoom = Number(zoom)
            if (isNaN(lat)) return
            if (isNaN(lng)) return
            if (isNaN(zoom)) return
    
            const newCenter = { lat, lng }
    
            mapRef.setCenter(newCenter)
            mapRef.setZoom(zoom)
    
            // pass back control to user once initial map position is done
            setInteractMode(INTERACT_MODES.USER)
        },
        [mapRef, router.location.state]
    )

    /**
     * Used to update map centre upon first render.
     * Will align map to geofences/vehicles once API returns
     * Will not do so if
     * - map has not loaded
     * - user started interacting with map to avoid overriding user action
     */
    useEffect(() => {
        // console.table(router.location.state || {})

        if (!mapRef) return
        if (interactMode !== INTERACT_MODES.MAP) return

        setInitialMapPosition()

    }, [props, mapRef, interactMode, setInitialMapPosition, setInteractMode])



    /**Geofence infowindow */
    const getGeofenceInfoWindow = geoid => {
        const geofence = geofences.byGEOID[geoid]
        if (!geofence) return null

        const vehiclesInGeofence = getVehiclesInGeofence(geofence.geoid)

        return (
            <InfoWindow
                position={getLatLngCenter(geofence.coordinates)}
                onCloseClick={() => {
                    moveToPageWithState({ geoid: undefined })
                }}
            >
                <div>
                    <h3>{geofence.geofenceName}</h3>
                    <h3>Number of vehicles: {vehiclesInGeofence.length}</h3>

                    {geofence.comment && <div className="geofenceMarkerOverlayViewComment">{geofence.comment}</div>}
                </div>
            </InfoWindow>
        )

        function getVehiclesInGeofence(geoid) {
            if (!geoid) return []

            return Object.values(vehicles.byVID)
                .filter(vehicle => vehicle.profile[geoid] === 1)
        }
    }

    /**Vehicle infowindow*/
    const getVehicleInfoWindow = vid => {
        const vehicle = vehicles.byVID[vid]
        if (!vehicle) return null

        const geoid = Object.keys(vehicle.profile).find(geoid => vehicle.profile[geoid] === 1)
        const area = vehicleGroups.byVGID[vehicle.area]
        const outlet = vehicleGroups.byVGID[vehicle.outlet]
        const geofence = geofences.byGEOID[geoid]
        const device = devices.byDVID[vehicle.dvid]

        return (
            <InfoWindow
                position={vehicle.location}
                onCloseClick={() => {
                    moveToPageWithState({ vid: undefined })
                }}
            >
                <Descriptions size={`default`} column={1} style={{ width: 300 }}>
                    <Descriptions.Item label="Chassis Number">
                        {vehicle.chassisNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Engine Number">
                        {vehicle.engineNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="Geofence">
                        {(geofence && geofence.geofenceName )|| 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Device">
                        {(device && device.dvid && device.dvid.split('#')[1]) || 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label="Device Status">
                        {
                            (device && device.deviceStatus === 0 && <b style={{ color: 'red' }}>{DEVICE_STATUS.DISCONNECTED}</b>)
                            ||
                            (device && device.deviceStatus === 1 && <b style={{ color: 'green' }}>{DEVICE_STATUS.CONNECTED}</b>)
                            ||
                            (device && device.deviceStatus === 2 && <b style={{ color: 'black' }}>{DEVICE_STATUS.TEMPERED}</b>)
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label="LP Status ">
                        {vehicle.logisticalStatus}
                    </Descriptions.Item>
                    <Descriptions.Item label="Region">
                        {area && area.groupName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Outlet">
                        {outlet && outlet.groupName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Allocated At">
                        {vehicle.pipeLine && parseTime(vehicle.pipeLine.ALLOCATED_startTime)}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Vehicle last updated At: {parseTime(vehicle.updatedAt)}
                    </Descriptions.Item>
                    <Descriptions.Item>
                        Device last updated At: {(device && parseTime(device.updatedAt)) || 'N/A'}
                    </Descriptions.Item>
                </Descriptions>
            </InfoWindow>
        )
    }

    return (
        <GoogleMap
            id='example-map'
            onLoad={ref => {
                setMapRef(ref)
            }}
            zoom={MAP_DEFAULT_ZOOM}
            center={MAP_DEFAULT_LOCATION}
            mapContainerStyle={{
                height: '100vh'
            }}
            onZoomChanged={() => {
                setUserUpdatedMapPosition()
            }}
            onDragStart={() => {
                setUserUpdatedMapPosition()
            }}
        >

            {/* Vehicle circle markers */}
            {
                Object.values(vehicles.byVID).map(vehicle => {
                    const device = devices.byDVID[vehicle.dvid]
                    return (
                        <Marker
                            key={vehicle.vid}
                            position={vehicle.location}
                            onClick={() => {
                                moveToPageWithState({
                                    vid: vehicle.vid
                                })
                            }}
                            icon={{
                                path: window.google && window.google.maps && window.google.maps.SymbolPath.CIRCLE,
                                scale: 5,
                                strokeWeight: 2,
                                strokeColor: (device && device.deviceStatus) ? 'green' : 'red',
                                rotation: device && Number(device.heading),
                            }}
                        />
                    )
                })
            }
            
            {/* Geofence polygons */}
            {
                Object.values(geofences.byGEOID).map(geofence => {
                    return (
                        <Polygon
                            key={geofence.geoid}
                            path={geofence.coordinates}
                            onClick={() => {
                                moveToPageWithState({
                                    geoid: geofence.geoid
                                })
                            }}
                        />
                    )
                })
            }

            {
                router.location.state && router.location.state.geoid && (
                    getGeofenceInfoWindow(router.location.state.geoid)
                )
            }

            {
                router.location.state && router.location.state.vid && (
                    getVehicleInfoWindow(router.location.state.vid)
                )
            }

        </GoogleMap>
    )
}

const MapComponent = (props) => GoogleMapsLoadScript(UrlMap, props);
export default MapComponent;