import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import PopUpUnpair from "./PopUpUnpair";
import Page from "../../components/Page";
// import ParseTime from "../../components/ParseTime";
// import UploadDeviceExcelButton from "./UploadExcel";
import UploadExcelButton from '../../components/UploadExcelButton'
import ExportExcelButton from "../../components/ExportExcelButton";
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { DEVICE_STATUS } from '../../constants';
import { PrimaryButton } from '../../components/PrimaryButton';
import { moveToPage } from "../../navigation/navigationService";
// import { EmptyIndicator } from "../../components/EmptyIndicator";
// import { tableColumnSearchProps } from "../../components/TableColumnSearchProps";
import {
    Form,
    Table,
    Button,
    Tooltip,
    message,
    DatePicker,
    Input,
    // AutoComplete,
} from "antd";
import {
    SearchOutlined,
    MonitorOutlined,
    EditOutlined
} from '@ant-design/icons'

// Style
import './index.css'

// Redux Actions
import { set_selected_dvid } from "../../services/redux/actions/devices";
import { add_device_success } from '../../services/redux/actions/devices';
import * as API from '../../services/api/devices'
import {
    TEMPLATE_URLS,
} from '../../constants'
import parseTime from '../../components/ParseTime';
import Highlighter from 'react-highlight-words';

// const defaultFilter = "ALL";

class DevicePanel extends React.Component {
    state = {
        // startTime: moment().startOf("day"),
        // endTime: moment().startOf("day").add(1, "day"),
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
        </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
        </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={(text && text.toString() )|| ``}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    onCloseModal = () => {
        this.setState({
            isOpen: false,
            selectedDVIDsToUnpair: [],
        });
    }

    setUpDataSource = () => {
        const {
            endTime,
            startTime,
        } = this.state;

        const dataSource = Object.values(this.props.devices.byDVID)
            .filter(currDevice => {
                if (startTime && currDevice.createdAt < startTime) return false
                if (endTime && currDevice.createdAt > endTime) return false
                return true
            })
            .map((currDevice, i) => {

                const vehicle = Object.values(this.props.vehicles.byVID)
                    .find(vehicle => vehicle.dvid === currDevice.dvid)

                const geofence = vehicle && Object.values(this.props.geofences.byGEOID)
                    .find(geofence => vehicle.profile[geofence.geoid] === 1)

                return {
                    key: currDevice.dvid,
                    index: i + 1,
                    imei: currDevice && currDevice.dvid.includes("#") ? currDevice.dvid.split("#")[1] : "-",
                    simCard: currDevice.simCard || "-",
                    pairedVehicle: (vehicle && vehicle.engineNumber) || "-",
                    geofenceName: geofence && geofence.geofenceName,
                    status: (currDevice.deviceStatus === 0 && DEVICE_STATUS.DISCONNECTED)
                        || (currDevice.deviceStatus === 1 && DEVICE_STATUS.CONNECTED)
                        || (currDevice.deviceStatus === 2 && DEVICE_STATUS.TEMPERED),
                    updatedAt: currDevice.updatedAt || "-",
                }
            })

        const columns = [
            {
                title: "No",
                dataIndex: "index",
            },
            {
                title: "Device IMEI",
                dataIndex: "imei",
                ...this.getColumnSearchProps('imei')
            },

            {
                title: "Mobile Number",
                dataIndex: "simCard",
                ...this.getColumnSearchProps('simCard'),
            },

            {
                title: "Paired Vehicle",
                dataIndex: "pairedVehicle",
                render: (pairedVehicle, rowData) => (
                    <button
                        type="button"
                        className = "link-button"
                        onClick={() => {
                            const dvid = rowData.key
                            const vehicle = Object.values(this.props.vehicles.byVID).find(vehicle => vehicle.dvid === dvid)
                            // console.log({ dvid, vehicle })
                            if (!vehicle) return
                            const state = {
                                vid: vehicle.vid,
                                lat: vehicle.location.lat,
                                lng: vehicle.location.lng,
                                zoom: 25,
                            }
                            // console.log({ state })
                            this.props.dispatch(moveToPage('/', state))
                        }}
                    >
                        {pairedVehicle}
                    </button>
                ),
                ...this.getColumnSearchProps('pairedVehicle')
            },
            {
                title: "Device Status",
                dataIndex: "status",
                render: deviceStatus => {
                    switch (deviceStatus) {
                        case DEVICE_STATUS.CONNECTED:
                            return <b style={{ color: 'green' }}>{deviceStatus}</b>
                        case DEVICE_STATUS.DISCONNECTED:
                            return <b style={{ color: 'red' }}>{deviceStatus}</b>
                        case DEVICE_STATUS.TEMPERED:
                            return <b style={{ color: 'black' }}>{deviceStatus}</b>
                        default:
                            return <b>{deviceStatus}</b>
                    }
                },
                filters: Object.values(DEVICE_STATUS).map(status => ({ text: status, value: status })),
                onFilter: (value, record) => record.status.indexOf(value) === 0,
            },

            {
                title: "Last Updated",
                dataIndex: "updatedAt",
                render: time => parseTime(time),
                sorter: (a, b) => a.updatedAt - b.updatedAt,
            },

            {
                title: "Actions",
                fixed: 'right',
                render: (rowData) => (
                    <div>
                        <Tooltip title={"Device Log"} style={{ display: "flex", flexDirection: "row" }}>
                            <button className="transparent-button">
                                <MonitorOutlined 
                                    onClick={() => {
                                        this.props.dispatch(set_selected_dvid(rowData.key));
                                        this.props.dispatch(moveToPage(`/DeviceManagement/DeviceLogInspector`));
                                    }}
                                />
                            </button>
                        </Tooltip>

                        <Tooltip title={"Edit"} style={{ display: "flex", flexDirection: "row" }}>
                            <button className="transparent-button">
                                <EditOutlined 
                                    onClick={() => {
                                        this.props.dispatch(set_selected_dvid(rowData.key));
                                        this.props.dispatch(moveToPage(`/DeviceManagement/EditDevice`));
                                    }}
                                />
                            </button>
                        </Tooltip>
                    </div>
                )
            }
        ];

        this.setState({
            dataSource,
            columns
        })
    }

    componentDidMount = () => {
        this.setUpDataSource()
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setUpDataSource()
        }
    }

    render() {
        const {
            endTime,
            startTime,
            // areaFilter,
            dataSource = [],
            columns = []
        } = this.state;

        const {
            vehicles,
            // vehicleGroups,
        } = this.props;


        const rowSelection = {
            selectedRowKeys: this.state.selectedDVIDsToUnpair,
            onChange: (selectedRowKeys) => {
                // console.log(`selectedRowKeys: ${selectedRowKeys}`);

                this.setState({
                    selectedDVIDsToUnpair: selectedRowKeys,
                })
            },
            getCheckboxProps: (rowData) => ({
                // Column configuration not to be checked
                disabled: (
                    !this.props.devices.byDVID[rowData.key]
                    || !this.props.devices.byDVID[rowData.key].dvid
                    || !this.props.devices.byDVID[rowData.key].dvid === "-"
                ),
            }),
        };

        return (
            <div className='page-container'>
                <Page title="Device Management">
                    <Form layout='inline'>
                        {/* <Form.Item>
                            <span style = {{ marginLeft: '10px' }}>Area: </span>

                            <AutoComplete
                                placeholder = 'Region'
                                disabled = {this.state.areaFilterList.length < 1}
                                value = {areaFilter}
                                // onSearch = {areaFilterString => {
                                //     this.setState({ areaFilterString })
                                // }}
                                onSelect = {areaFilter => {
                                    this.setState({ areaFilter })
                                }}
                                style = {{ width: '250px' }}
                            >
                                {
                                    this.state.areaFilterList.length > 0
                                    && this.state.areaFilterList.map(currVGID => {
                                        // console.log("Current VGID:", currVGID);

                                        return (
                                            <AutoComplete.Option key = {currVGID}>
                                                {
                                                    currVGID === defaultFilter ?
                                                        currVGID :
                                                        vehicleGroups.byVGID[currVGID].groupName
                                                }
                                            </AutoComplete.Option>
                                        );
                                    })
                                }
                            </AutoComplete>
                        </Form.Item> */}

                        <Form.Item>
                            <span style={{ marginLeft: '30px' }}>Start time: </span>

                            <DatePicker
                                showTime
                                value={startTime}
                                onChange={(value) => this.setState({ startTime: value.startOf("day") })}
                            />

                            <span style={{ marginLeft: '10px' }}>End time: </span>

                            <DatePicker
                                showTime
                                value={endTime}
                                onChange={(value) => this.setState({ endTime: value.startOf("day") })}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                loading={this.props.style.isLoadingFleetRecord}
                                disabled={!(this.state.areaFilter || (this.state.startTime && this.state.endTime))}
                                onClick={() => this.setUpDataSource()}
                                style={{ marginLeft: '15px' }}
                            >
                                Submit
                            </Button>

                            <ExportExcelButton
                                filename={`Devices ${moment().format('DD-MM-YYYY')}`}
                                sheetName={`Devices ${moment().format('DD-MM-YYYY')}`}
                                sheetData={dataSource}
                                sheetRow={
                                    columns
                                        .filter(col => col.title !== 'Actions')
                                        .filter(col => col.title !== 'No')
                                        .map(col => {
                                            switch (col.dataIndex) {
                                                case 'createdAt':
                                                case 'updatedAt':
                                                    return {
                                                        label: col.title,
                                                        formatter: value => col.render(value[col.dataIndex])
                                                    }
                                                default:
                                                    return {
                                                        label: col.title,
                                                        formatter: value => value[col.dataIndex]
                                                    }
                                            }
                                        })
                                }
                            />
                        </Form.Item>
                    </Form>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",

                            padding: 5,
                            marginBottom: 10,
                        }}
                    >

                        <UploadExcelButton
                            title='Bulk upload device'
                            loading={this.props.style.isLoadingSubmit || Object.keys(this.props.devices.byDVID).length === 0}
                            buttonName={`Bulk Upload Devices`}
                            templateUrl={TEMPLATE_URLS.BULK_UPLOAD_DEVICES_TEMPLATE}
                            excelTemplateName={`Bulk Upload Devices Template`}
                            dataColumns={
                                {
                                    imei: {
                                        label: 'Device Imei',
                                        rule: (imei, allImeis) => {
                                            const duplicates = allImeis.filter(_imei => imei === _imei);
                                            const isExist = Object.keys(this.props.devices.byDVID).find(dvid => dvid.includes(imei));

                                            if (isExist) {
                                                throw new Error(`Imei already exist`);
                                            }

                                            if (duplicates.length > 1) {
                                                throw new Error(`Duplicated imei`);
                                            }
                                        },
                                    },
                                    simCard: {
                                        label: 'Mobile Number',
                                        rule: (value) => {

                                            /**Pass by default */
                                            // const regex = new RegExp(/^[0-9]/)
                                            // // const regex = new RegExp(/^(\+?6?01)[0-46-9][0-9]{7,8}$/)
                                            // const isPass = regex.test(value);

                                            // // console.log("Test:", value, value.toString().length);

                                            // if (!isPass) {
                                            //     throw "Mobile Number Has Invalid Characters";
                                            // }

                                            // if (value.toString().length < 10) {
                                            //     throw "Mobile Number Is Too Short";
                                            // }

                                            // if (value.toString().length > 15) {
                                            //     throw "Mobile Number Is Too Long";
                                            // }
                                        },
                                    },
                                    simSerial: {
                                        label: 'Sim Serial Number',
                                        rule: (value) => {
                                            /**Pass by default */
                                        },
                                    }
                                }
                            }
                            uploadLoop={async (excelData) => {
                                excelData.imei = excelData.imei && excelData.imei.toString()
                                excelData.simCard = excelData.simCard && excelData.simCard.toString()
                                excelData.simSerial = excelData.simSerial && excelData.simSerial.toString()

                                const { uid } = this.props.user;
                                const newDevice = {
                                    activeStatus: 1,
                                    imei: excelData.imei,
                                    simCard: excelData.simCard,
                                    simSerial: excelData.simSerial,
                                };

                                // console.log({ excelData, newDevice, uid });

                                // const data = await fakeAPI(newDevice, uid)
                                const data = await API.addDevice(uid, newDevice);

                                // console.log(data);

                                switch (data.status) {
                                    case 200:
                                        this.props.dispatch(add_device_success(data.device));
                                        return {
                                            uploadStatus: 'SUCCESS',
                                            message: ''
                                        }

                                    case 201:
                                        return {
                                            uploadStatus: 'WARNING',
                                            message: data.message
                                        }

                                    default:
                                        return {
                                            uploadStatus: 'FAILED',
                                            message: data.message
                                        }
                                }

                                // function fakeAPI() {
                                //     return new Promise((resolve, reject) => {
                                //         setTimeout(() => {
                                //             resolve({
                                //                 status: 200,
                                //                 device: {
                                //                     dvid: Math.random()
                                //                 }
                                //             })
                                //         }, 1 * 1000)
                                //     })
                                // }
                            }}

                        />

                        <PrimaryButton
                            onClick={() => {
                                if (this.state.selectedDVIDsToUnpair && this.state.selectedDVIDsToUnpair.length > 0) {
                                    if (this.state.selectedDVIDsToUnpair.filter((currDVID) => vehicles.byDVID[currDVID]).length > 0) {
                                        this.setState({
                                            isOpen: true,
                                        })
                                    }
                                    else {
                                        if (this.state.selectedDVIDsToUnpair.filter((currDVID) => !vehicles.byDVID[currDVID]).length > 1) {
                                            message.error("Selected vehicles have no paired device.");
                                        }
                                        else {
                                            message.error("Selected vehicle has no paired device.");
                                        }

                                        this.setState({
                                            selectedDVIDsToUnpair: [],
                                        })
                                    }
                                }
                                else {
                                    message.error("Please select devices to unpair.");
                                }
                            }}
                        >
                            Unpair Device
                        </PrimaryButton>

                        <PrimaryButton onClick={() => this.props.dispatch(moveToPage("/DeviceManagement/AddDevice"))}>
                            Add Device
                        </PrimaryButton>
                    </div>

                    <Table
                        loading={this.props.style.isLoadingSubmit || Object.keys(this.props.devices.byDVID).length === 0}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{ pageSize: 20 }}
                        rowSelection={rowSelection}
                        scroll={{
                            x: columns && columns.length * 100,
                            y: window.innerHeight
                        }}
                    />

                    {this.state.isOpen && <PopUpUnpair onCloseModal={() => this.onCloseModal()} selectedDVIDs={this.state.selectedDVIDsToUnpair} />}
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
    geofences: state.geofences,
    vehicleGroups: state.vehicleGroups,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(DevicePanel));