import * as actionTypes from "../action-types";

const tripRecordStore = {
    byVID: {},
    byTRID: {},
    
    selectedDate: "",
    selectedIndex: 0,
    
    selectedTRID: "",
};

export const tripRecords = (state = tripRecordStore, action) => {
    const newStore = JSON.parse(JSON.stringify(state))

    switch (action.type) {
        case actionTypes.GET_TRIP_RECORD: {
            // console.log("Trip Record Data", action.tripRecord);

            // Always reset byVID and byTRID array obj when getting data
            newStore.byVID = {};
            newStore.byTRID = {};

            // let fuel_total = 0;

            // console.log("tripRecord", action)

            action.tripRecord.forEach((currTrip) => {
                newStore.byTRID[currTrip.trid] = currTrip;

                // Define this VID key as an empty array first
                if (!newStore.byVID[currTrip.vid]) {
                    newStore.byVID[currTrip.vid] = [];
                }

                newStore.byVID[currTrip.vid].push(currTrip);
            })

            // console.log("Trip Record Fuel Total:", fuel_total);

            return newStore;
        }

        case actionTypes.SET_TRIP_RECORD_SELECTED_TRID: {
            // console.log("New Trip Record Selected TRID:", action.selectedTRID);

            newStore.selectedTRID = action.selectedTRID;

            return newStore;
        }

        case actionTypes.CLEAR_TRIP_RECORD_SELECTED_TRID: {
            // console.log("Clear Trip Record Selected TRID");

            newStore.selectedTRID = null;

            return newStore;
        }

        case actionTypes.SET_TRIP_RECORD_SELECTED_DATE_AND_INDEX: {
            // console.log("New Trip Record Selected Date:", action.selectedDate);

            newStore.selectedDate = action.selectedDate;
            newStore.selectedIndex = action.selectedIndex;

            return newStore;
        }

        case actionTypes.CLEAR_TRIP_RECORD_SELECTED_DATE_AND_INDEX: {
            // console.log("Clear Trip Record Selected Date");

            newStore.selectedDate = newStore.selectedIndex = null;

            return newStore;
        }
        default: {
            return state;
        }
    }
}