import * as ActionTypes from "../action-types/deviceGroups";

const defaultState = {
    byDGID: {},
}

export const deviceGroups = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_DEVICE_GROUPS: {
            newState.byDGID = JSON.parse(JSON.stringify(defaultState.byDGID)); // Clear newState byDGID
            
            action.deviceGroups.forEach((currDG) => newState.byDGID[currDG.dgid] = currDG);

            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Device Group Store:", defaultState);

            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}