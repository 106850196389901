import moment from 'moment';
import sanityCheckNumber from './SanityCheckNumber';

export const parseTime = time => {
    if (!time) return '-'
    return moment(Number(time)).format("YYYY/MM/DD HH:mm:ss");
}

export const parseDate = (millisec) => {
    if (millisec === null || isNaN(Number(millisec))) return '-';

    return moment(millisec).format('DD-MM-YYYY HH:mm:ss');
}

// export const parseDuration = (duration) => {
//     const momentDuration = moment.duration(duration);

//     const days = parseInt(momentDuration.asDays(), 10);
//     const hrs = momentDuration.hours();
//     const mins = momentDuration.minutes();
//     const secs = momentDuration.seconds();

//     return (
//         days ?
//             `${days} days ${hrs} hrs ${mins} mins` :
//             hrs ?
//                 `${hrs} hrs ${mins} mins` :
//                 mins ?
//                     `${mins} mins ${secs} secs` :
//                     `${secs} secs`
//     )
// }

export const parseDuration = (millisec) => {
    if (!sanityCheckNumber(millisec)) { return "-" }

    let seconds = (millisec / 1000).toFixed(0);
    let minutes = Math.floor(seconds / 60);
    let hours = 0;

    // console.log("milisec", millisec)
    // console.log("minute", minutes);

    if (minutes > 59) {
        hours = Math.floor(minutes / 60);
        minutes = minutes - hours * 60;
    }

    seconds = Math.floor(seconds % 60);

    if (Number.isNaN(millisec)) {
        return " Not Available "
    }
    else if(hours > 0) {
        return `${hours} ${hours > 1 ? "hrs" : "hr"} ${minutes} ${minutes > 1 ? "mins" : "min"}`.trim()
    }
    else return `${minutes} ${minutes > 1 ? "mins" : "min"} ${seconds} ${ seconds > 1 ? "secs" : "sec"}`.trim()
}