import React, { useState, useEffect } from 'react';
import {
    useDispatch,
    useSelector,
} from 'react-redux';

// import MapSearchBar from './components/MapSearchBar';
// import MapControlPanel from './components/MapControlPanel';
import POIGeofences from "./components/POIGeofences";
import PolygonGeofences from "./components/PolygonGeofences";
import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { parseTime, parseDuration } from '../../util/time';
import { LOCATION, EVENT_TYPES, VEHICLE_STATUS, VEHICLE_COLOR } from '../../constants';
import { moveToPage } from '../../navigation/navigationService';
import {
    GoogleMap,
    // InfoWindow,
    TrafficLayer,
    // Marker,
    // Polyline,
} from '@react-google-maps/api';
import {
    message,
    Select,
    // Form,
    Timeline,
} from "antd";
import {
    CloseOutlined
} from '@ant-design/icons'

import "./gmaps.css";
import info from '../../img/Trip Record/info.svg';
import warning from '../../img/Trip Record/warning.svg';
import critical from '../../img/Trip Record/critical.svg';
import TripRecordRoute from './components/TripRecordRoute';

// Redux Actions
import { set_map_control } from '../../services/redux/actions/mapControl';
import { clear_selected_vehicle } from "../../services/redux/actions/vehicles";
import { clear_selected_tsid } from "../../services/redux/actions/transitRecords";
import { clear_selected_geofence_geoid } from '../../services/redux/actions/geofences';
import {
    clear_enabled_vehicle_pop_ups,
    clear_enabled_geofence_pop_ups,
} from "../../services/redux/actions/mapControl";
import {
    set_selected_tsid
} from '../../services/redux/actions/tripRecordControl'


const FILTERABLE_EVENT_TYPES = [
    EVENT_TYPES.INFO,
    EVENT_TYPES.WARNING,
    EVENT_TYPES.CRITICAL
]

const FILTERABLE_TRANSIT_TYPES = [
    VEHICLE_STATUS.MOVING,
    VEHICLE_STATUS.IDLING,
    VEHICLE_STATUS.PARKING,
    VEHICLE_STATUS.DISCONNECTED,
]

const TRANSIT_AND_EVENT_DURATIONS = {
    ALL: {
        label: 'ALL',
        value: 0
    },
    ONE_MINUTE: {
        label: '> 1 min',
        value: 60 * 1000
    },
    TWO_MINUTES: {
        label: '> 2 mins',
        value: 2 * 60 * 1000
    },
    FIVE_MINUTES: {
        label: '> 5 mins',
        value: 5 * 60 * 1000
    },
    TEN_MINUTES: {
        label: '> 10 mins',
        value: 10 * 60 * 1000
    },
    FIFTEEN_MINUTES: {
        label: '> 15 mins',
        value: 15 * 60 * 1000
    },
    THIRTY_MINUTES: {
        label: '> 30mins',
        value: 30 * 60 * 1000
    }
}

const GoogleMapsComponent = (props) => {
    // State
    const [mapRef, setMapRef] = useState(null);
    const isExpanded = useState(true)[0]
    // const [components, setComponents] = useState([])
    const [eventTypeFilters, setEventTypeFilters] = useState(FILTERABLE_EVENT_TYPES)
    const [transitTypeFilters, setTransitTypeFilters] = useState(FILTERABLE_TRANSIT_TYPES)
    const [transitAndEventDurationFilterKey, setTransitAndEventDurationFilterKey] = useState('ONE_MINUTE')

    // Redux Store
    // const user = useSelector(state => state.v2.user);
    const router = useSelector(state => state.router);
    const style = useSelector(state => state.style);
    const events = useSelector(state => state.events);
    const vehicles = useSelector(state => state.vehicles);
    const mapControl = useSelector(state => state.mapControl);
    const tripRecordControl = useSelector(state => state.tripRecordControl);
    const transitRecord = useSelector(state => state.transitRecords);
    const tripRecord = useSelector(state => state.tripRecords);
    const geofences = useSelector(state => state.geofences);
    const devices = useSelector(state => state.devices)

    const dispatch = useDispatch()


    // Initial mount of component
    useEffect(() => {
        // console.log('new google maps component onMount');

        dispatch(clear_selected_vehicle());
        dispatch(clear_enabled_vehicle_pop_ups());
        dispatch(clear_selected_geofence_geoid());
        dispatch(clear_enabled_geofence_pop_ups());
    }, [dispatch])

    /**
     * If map did not set location to geofence/vehicles within 5 seconds, (a sign of a new account)
     * default location & zoom would be set instead
     */
    useEffect(() => {
        if (!mapRef) return

        setTimeout(() => {
            if (!mapRef.center) {
                // console.log(`[GoogleMapsComponent] Auto setting map location`)
                mapRef.panTo(LOCATION)
                mapRef.setZoom(7)
            }
        }, 5 * 1000)
    })

    /**
     * If map hasn't set a location yet,
     * Pan to all map item's circumference location
     * Else if map already has a location, do nothing
     */
    useEffect(() => {
        if (!mapRef) return
        if (mapRef.center) return
        if (style.isLoadingSubmit) return

        const vehicleLocations = Object.values(vehicles.byVID).map(vehicle => {
            const device = vehicle && devices.byDVID[vehicle.dvid]
            const location = device && device.location
            return location && {
                lat: parseFloat(location.lat),
                lng: parseFloat(location.lon)
            }
        })
            .filter(l => l)

        const geofenceLocations = Object.values(geofences.byGEOID).map(geofence => {
            return geofence.coordinates
        })
            .reduce((a, b) => [...a, ...b], [])
            .filter(l => l)

        const allLocations = [...vehicleLocations, ...geofenceLocations]

        if (allLocations.length) {
            const bounds = new window.google.maps.LatLngBounds();

            allLocations.map(c => {
                bounds.extend(c)
                return null;
            })

            // console.log(`[GoogleMapsComponent] Setting map location by vehicles & geofences`);

            mapRef.fitBounds(bounds, [10]);
        }

    }, [vehicles, geofences, devices, mapRef, style])

    /**
     * Render geofence, vehicles, etc
     */
    useEffect(() => {

        let zoom, location;

        /**
         * Pan to selected transit status
         */
        if (geofences.selectedId
            && geofences.byGEOID[geofences.selectedId]
            && geofences.byGEOID[geofences.selectedId].coordinates.length > 0
            && mapControl.mapControl === 1) {
            const selectedGeofence = geofences.byGEOID[geofences.selectedId];

            // console.log("Selected Transit:", selectedTransit);

            if (selectedGeofence.coordinates.length
                && selectedGeofence.coordinates[0]
                && selectedGeofence.coordinates[0].lat && selectedGeofence.coordinates[0].lng) {
                // console.log("Test 1");

                zoom = 20;

                location = {
                    lat: parseFloat(selectedGeofence.coordinates[0].lat),
                    lng: parseFloat(selectedGeofence.coordinates[0].lng),
                }

                if (mapRef) {
                    mapRef.setZoom(zoom);
                    mapRef.panTo(location);

                    dispatch(set_map_control(0));
                }
            }
            else {
                message.error("No location found for this transit status");

                dispatch(clear_selected_tsid());

                dispatch(set_map_control(0));
            }
        }
    })


    useEffect(() => {
        // console.log(router.location.state)
    }, [router])

    const getTripDetailsDrawer = () => {
        const POINT_TYPES = {
            TRANSIT_RECORD: {
                label: `TRANSIT_RECORD`,
                render: transitRecord => {

                    const isTransitRecordSelected = tripRecordControl.selectedTSID

                    return <Timeline.Item
                        key={transitRecord.tsid}
                        color={VEHICLE_COLOR[transitRecord.transitStatus]}
                        style={{
                            cursor: `pointer`,
                            boxShadow: isTransitRecordSelected && `5px 5px 5px #e3e3e3`
                        }}
                        onClick={() => {
                            const tsid = transitRecord.tsid

                            set_selected_tsid(tsid)
                        }}
                    >
                        <div
                            style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`
                            }}
                        >
                            <h4 style={{ fontWeight: 'bold' }}>
                                {
                                    transitRecord.transitStatus
                                }
                            </h4>
                            {
                                parseTime(transitRecord.createdAt)
                            }
                        </div>

                        <div>
                            <h4>
                                {
                                    transitRecord.duration && parseDuration(transitRecord.duration)
                                }
                            </h4>
                            <h4>
                                {transitRecord.startAddress}
                            </h4>
                        </div>
                    </Timeline.Item>
                }
            },
            EVENT_LOG: {
                label: `EVENT_LOG`,
                render: eventLog => {

                    const isEventLogSelected = router.location.state && router.location.state.eventCreatedAt === eventLog.eventCreatedAt

                    let dot

                    switch (eventLog.eventType) {
                        case EVENT_TYPES.CRITICAL:
                            dot = critical
                            break
                        case EVENT_TYPES.WARNING:
                            dot = warning
                            break

                        case EVENT_TYPES.INFO:
                        default:
                            dot = info
                            break
                    }

                    return <Timeline.Item
                        dot={<img
                            alt=""
                            src={dot}
                            style={{
                                width: 16,
                                height: 16,
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                            }}
                        />}
                        key={eventLog.eventCreatedAt}
                        //  color={VEHICLE_COLOR[eventLog.transitStatus]}
                        onClick={() => {

                            const newEventCreatedAt = router.location.state.eventCreatedAt === eventLog.eventCreatedAt ? undefined : eventLog.eventCreatedAt

                            dispatch(moveToPage(`TripRecord`, {
                                ...router.location.state,
                                eventCreatedAt: newEventCreatedAt
                            }))
                        }}
                        style={{
                            cursor: `pointer`,
                            boxShadow: isEventLogSelected && `5px 5px 5px #e3e3e3`
                        }}
                    >
                        <div
                            style={{
                                display: `flex`,
                                flexDirection: `row`,
                                justifyContent: `space-between`,
                            }}
                        >
                            <h4 style={{ fontWeight: 'bold' }}>
                                {
                                    eventLog.eventType
                                }
                            </h4>
                            {
                                parseTime(eventLog.createdAt)
                            }
                        </div>

                        <div>
                            <h4>
                                {eventLog.eventName}
                            </h4>
                        </div>
                    </Timeline.Item>
                }
            },
        }
        
        let trid = tripRecordControl.selectedTRID

        const tripRecords = tripRecord.byTRID[trid]
        if (!tripRecords) return null

        const { startTime, endTime, vid } = tripRecords


        const transitRecords = Object.values(transitRecord.byTSID)
            .filter(transitRecord => startTime <= transitRecord.startTime && endTime >= transitRecord.endTime)
            .filter(transitRecord => transitTypeFilters.includes(transitRecord.transitStatus))
            .filter(transitRecord => {
                const duration = TRANSIT_AND_EVENT_DURATIONS[transitAndEventDurationFilterKey]
                if (!duration) return true
                return transitRecord.duration >= duration.value
            })
            .map(transitRecord => {
                return {
                    ...transitRecord,
                    createdAt: transitRecord.startTime,
                    type: POINT_TYPES.TRANSIT_RECORD.label,
                }
            })

        const eventLogs =
            (
                events.byId[vid] && Object.values(events.byId[vid])
                    .filter(eventLog => startTime <= eventLog.createdAt && endTime >= eventLog.createdAt)
                    .filter(eventLog => eventTypeFilters.includes(eventLog.eventType))
                    .map(eventLog => {
                        return {
                            ...eventLog,
                            type: POINT_TYPES.EVENT_LOG.label,
                        }
                    })
            )
            ||
            []

        const allPoints = [...transitRecords, ...eventLogs]
            .sort((a, b) => a.createdAt - b.createdAt)

        return (
            <div
                style={{
                    position: 'absolute',
                    right: 0,
                    width: isExpanded ? 300 : 80,
                    // padding: 10,
                    height: '100%',
                    zIndex: 99,
                    opacity: 0.9,
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    resize: `horizontal`,
                    overflow: `auto`,
                }}
            >
                <div
                    style={{
                        flex: 1,
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: `space-between`,
                        }}
                    >
                        <h3>Trip Details</h3>
                        <CloseOutlined
                            onClick={() => {
                                dispatch(moveToPage(`TripRecord`, {
                                    ...router.location.state,
                                    trid: null
                                }))
                            }}
                        />
                    </div>

                    <div
                        style={{
                            paddingBottom: 10,
                        }}
                    >
                        <div style={styles.headers}>
                            Duration
                        </div>
                        <Select
                            style={{ width: '100%' }}
                            defaultValue={transitAndEventDurationFilterKey}
                            onChange={transitAndEventDurationFilterKey => {
                                setTransitAndEventDurationFilterKey(transitAndEventDurationFilterKey)
                            }}
                        >
                            {
                                Object.keys(TRANSIT_AND_EVENT_DURATIONS).map(key => {
                                    const duration = TRANSIT_AND_EVENT_DURATIONS[key]
                                    return <Select.Option
                                        key={key}
                                    >
                                        {duration.label}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </div>


                    <div
                        style={{
                            paddingBottom: 10,
                        }}
                    >
                        <div style={styles.headers}>
                            Event Types
                        </div>
                        <Select
                            mode='multiple'
                            defaultValue={eventTypeFilters}
                            onChange={eventTypes => {
                                setEventTypeFilters(eventTypes)
                            }}
                        >
                            {
                                FILTERABLE_EVENT_TYPES
                                    .map(eventType => {
                                        let dot

                                        switch (eventType) {
                                            case EVENT_TYPES.CRITICAL:
                                                dot = critical
                                                break
                                            case EVENT_TYPES.WARNING:
                                                dot = warning
                                                break

                                            case EVENT_TYPES.INFO:
                                            default:
                                                dot = info
                                                break
                                        }

                                        return <Select.Option
                                            key={eventType}
                                        >
                                            <img
                                                alt=""
                                                src={dot}
                                                style={{
                                                    marginRight: 10,
                                                    width: 16,
                                                    height: 16,
                                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                }}
                                            />
                                            {eventType}
                                        </Select.Option>
                                    })
                            }
                        </Select>
                    </div>


                    <div
                        style={{
                            paddingBottom: 10,
                        }}
                    >
                        <div style={styles.headers}>
                            Transit Types
                        </div>
                        <Select
                            mode='multiple'
                            defaultValue={transitTypeFilters}
                            onChange={transitTypes => {
                                setTransitTypeFilters(transitTypes)
                            }}
                        >
                            {
                                FILTERABLE_TRANSIT_TYPES.map(transitType => {
                                    return <Select.Option
                                        key={transitType}
                                    >
                                        {transitType}
                                    </Select.Option>
                                })
                            }
                        </Select>
                    </div>

                </div>
                <div
                    style={{
                        flex: 5,
                        overflow: 'scroll',
                        padding: 10,
                    }}
                >
                    <Timeline>
                        {
                            allPoints.map((point, i) => {
                                const render = POINT_TYPES[point.type] && POINT_TYPES[point.type].render
                                if (!render) return null
                                return (
                                    render(point)
                                )
                            })
                        }

                    </Timeline>

                </div>

            </div>
        )

    }

    return (
        <GoogleMap
            id='example-map'
            mapContainerStyle={{
                height: '100vh'
            }}
            onLoad={ref => setMapRef(ref)}
            onDragStart={() => {
                dispatch(set_map_control(0))
            }}
        >

            <POIGeofences mapRef={mapRef} />

            <PolygonGeofences mapRef={mapRef} />

            {mapControl.uiControl.showLiveTraffic && <TrafficLayer />}

            {/* <div className="searchBar">
                <MapSearchBar />
            </div>

            <div className="controlPanel">
                <MapControlPanel />
            </div> */}

            <TripRecordRoute mapRef={mapRef} />

            {
                getTripDetailsDrawer()
            }

        </GoogleMap>
    )
}

const styles = {
    headers: {
        color: 'white',
        backgroundColor: 'black',
        paddingLeft: 10,
    }
}

const GoogleMapsComponentWithLoadScript = (props) => GoogleMapsLoadScript(GoogleMapsComponent, props)

// export default GoogleMapsComponentWithLoadScript;
export default GoogleMapsComponentWithLoadScript;