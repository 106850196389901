import React, { useState, useEffect } from 'react'
import { Marker, InfoWindow, Polyline } from '@react-google-maps/api';
import { Descriptions, Timeline } from 'antd';
import {
    // useDispatch,
    useSelector,
} from 'react-redux';

import { parseTime, parseDuration } from '../../../util/time';
import { 
    // LOCATION, 
    EVENT_TYPES, 
    // VEHICLE_STATUS, 
    VEHICLE_COLOR 
} from '../../../constants';
// import { moveToPage } from '../../../navigation/navigationService';

import info from '../../../img/Trip Record/info.svg';
import warning from '../../../img/Trip Record/warning.svg';
import critical from '../../../img/Trip Record/critical.svg';

const TripRecordRoute = (props) => {
    const {
        mapRef
    } = props

    // Redux Store
    // const user = useSelector(state => state.user);
    const router = useSelector(state => state.router);
    const events = useSelector(state => state.events);
    // const vehicles = useSelector(state => state.vehicles);
    const mapControl = useSelector(state => state.mapControl);
    const tripRecordControl = useSelector(state => state.tripRecordControl);
    const transitRecord = useSelector(state => state.transitRecords);
    const tripRecord = useSelector(state => state.tripRecords);
    const vehicleLog = useSelector(state => state.vehicleLogs)

    const [thisEventLogs, setThisEventLogs] = useState([])
    const [thisVehicleLogs, setThisVehicleLogs] = useState([])
    const [playbackMarkerTrail, setPlaybackMarkerTrail] = useState(null)
    const [displayTrailInfoWindowTime, setDisplayTrailInfoWindowTime] = useState(null)
    const [displayEventLogInfoWindowTime, setDisplayEventLogInfoWindowTime] = useState(null)

    // const dispatch = useDispatch()

    useEffect(() => {

        const {
            selectedVID,
            selectedTRID,
            searchStartTime,
            searchEndTime
        } = tripRecordControl

        const thisTripRecord = (selectedTRID && tripRecord.byTRID[selectedTRID]) || null

        const params_thisVehicleLogs = 
            (
                vehicleLog.byVID && vehicleLog.byVID[selectedVID] && vehicleLog.byVID[selectedVID]
                .filter(log => log.vehicleTime >= searchStartTime && log.vehicleTime <= searchEndTime ) 
                .sort((a, b) => a.vehicleTime - b.vehicleTime)
                .map(log => {
                    // formatting log

                    let isHighlighted
                    if (!thisTripRecord) {
                        isHighlighted = false
                    } else {
                        isHighlighted = log.vehicleTime >= thisTripRecord.startTime && log.vehicleTime <= thisTripRecord.endTime
                    }

                    const lat = log.location && !isNaN(log.location.lat) && parseFloat(log.location.lat)
                    const lng = log.location && !isNaN(log.location.lon) && parseFloat(log.location.lon)

                    return {
                        ...log,
                        lat,
                        lng,
                        isHighlighted
                    }
                }) 
            )
            || []


        const params_thisEventLogs = 
            (
                params_thisVehicleLogs.length && events.byVID[selectedVID] && Object.values(events.byVID[selectedVID])
                .filter(eventLog => eventLog.createdAt >= searchStartTime && eventLog.createdAt <= searchEndTime)
                // .filter(eventLog => eventTypeFilters.includes(eventLog.eventType))
                .map(eventLog => {

                    const lat = eventLog.location && !isNaN(eventLog.location.lat) && parseFloat(eventLog.location.lat)
                    const lng = eventLog.location && !isNaN(eventLog.location.lon) && parseFloat(eventLog.location.lon)
                    return {
                        ...eventLog,
                        lat,
                        lng
                    }
                }) 
            )
            || 
            []

        const params_playbackMarkerTrail = params_thisVehicleLogs[vehicleLog.markerPlaybackIndex]

        if (mapRef && params_thisVehicleLogs.length && mapControl.mapControl !== 0) {

            const bounds = new window.google.maps.LatLngBounds();

            params_thisVehicleLogs.map(c => {
                bounds.extend(c)
                return null;
            })

            // console.log(`[GoogleMapsComponent] Setting map location by route`);

            mapRef.fitBounds(bounds, [10]);
        }

        setThisVehicleLogs(params_thisVehicleLogs)
        setThisEventLogs(params_thisEventLogs)
        setPlaybackMarkerTrail(params_playbackMarkerTrail)

    }, [router, tripRecord, vehicleLog, events, mapRef, mapControl.mapControl, tripRecordControl])


    const getTransitRecordInfoWindow = () => {

        const selectedTransitRecord = router.location.state && transitRecord.byTSID[router.location.state.tsid]

        if (!selectedTransitRecord) return null

        const position = selectedTransitRecord.startLocation && selectedTransitRecord.startLocation.lat && selectedTransitRecord.startLocation.lon
            && { lat: parseFloat(selectedTransitRecord.startLocation.lat), lng: parseFloat(selectedTransitRecord.startLocation.lon) }

        if (!position) return null

        return (
            <InfoWindow
                zIndex={1000}
                options={{
                    disableAutoPan: true,
                }}
                position={position}

            >
                <div
                    style={{
                        paddingTop: 10,
                    }}
                >
                    <Timeline>
                        <Timeline.Item
                            color={VEHICLE_COLOR[selectedTransitRecord.transitStatus]}
                        >
                            <div
                                style={{
                                    display: `flex`,
                                    flexDirection: `row`,
                                    justifyContent: `space-between`
                                }}
                            >
                                <h4 style={{ fontWeight: 'bold' }}>
                                    {
                                        selectedTransitRecord.transitStatus
                                    }
                                </h4>
                                {
                                    parseTime(selectedTransitRecord.startTime)
                                }
                            </div>

                            <div>
                                <h4>
                                    {
                                        selectedTransitRecord.duration && parseDuration(selectedTransitRecord.duration)
                                    }
                                </h4>
                                <h4>
                                    {selectedTransitRecord.startAddress}
                                </h4>

                                <a
                                    href={`https://www.google.com.my/maps/place/${position.lat},${position.lng}`}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                >
                                    {`${position.lat}, ${position.lng}`}
                                </a>
                            </div>
                        </Timeline.Item>
                    </Timeline>
                </div>
            </InfoWindow>
        )
    }

    const getEventLogMarkers = () => {
        return thisEventLogs.map(eventLog => {

            let dot
            switch (eventLog.eventType) {
                case EVENT_TYPES.CRITICAL:
                    dot = critical
                    break
                case EVENT_TYPES.WARNING:
                    dot = warning
                    break

                case EVENT_TYPES.INFO:
                default:
                    dot = info
                    break
            }

            const isShowEventInfoWindow = displayEventLogInfoWindowTime && displayEventLogInfoWindowTime === eventLog.eventCreatedAt


            return (
                eventLog.lat && eventLog.lng
                ?
                    (
                        <Marker
                            key={eventLog.eventCreatedAt}
                            position={{ lat: eventLog.lat, lng: eventLog.lng, }}
                            icon={dot}
                            onClick={() => {
                                // const thisTripRecord = Object.values(tripRecord.byTRID).find(tr => {
                                //     return tr.startTime <= eventLog.createdAt && tr.endTime >= eventLog.createdAt
                                // })
                                // const trid = thisTripRecord && thisTripRecord.trid.split('#').pop()

                                setDisplayEventLogInfoWindowTime(eventLog.eventCreatedAt)
                            }}
                        >

                            {
                                isShowEventInfoWindow && <InfoWindow
                                    zIndex={1000}
                                    options={{
                                        disableAutoPan: true,
                                    }}
                                    // mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                                    position={{ lat: eventLog.lat, lng: eventLog.lng, }}
                                >
                                    <div
                                        style={{
                                            paddingTop: 10,
                                        }}
                                    >
                                        <Timeline>
                                            <Timeline.Item
                                                dot={<img
                                                    alt=""
                                                    src={dot}
                                                    style={{
                                                        width: 16,
                                                        height: 16,
                                                        backgroundColor: "rgba(0, 0, 0, 0.04)",
                                                    }}
                                                />}
                                                key={eventLog.eventCreatedAt}
                                                onClick={() => {
                                                    // console.log(eventLog)
                                                }}
                                            >
                                                {
                                                    parseTime(eventLog.createdAt)
                                                }
                                                <h4 style={{ fontWeight: 'bold' }}>
                                                    {
                                                        eventLog.eventType
                                                    }
                                                </h4>

                                                <div>
                                                    <p>
                                                        {eventLog.eventName}
                                                    </p>
                                                </div>
                                            </Timeline.Item>
                                        </Timeline>
                                    </div>
                                </InfoWindow>
                            }
                        </Marker>
                    )
                :
                            null
            )

        })

    }

    const getTrail = () => {

        return thisVehicleLogs.map((log, i) => {
            const isShowDeviceLogInfoWindow = displayTrailInfoWindowTime && displayTrailInfoWindowTime === log.vehicleTime

            const nextLog = thisVehicleLogs[i + 1]

            if (!nextLog) return (

                <Marker
                    key={i}
                    position={{ lat: log.lat, lng: log.lng, }}
                >
                    <InfoWindow
                        zIndex={1000}
                        options={{
                            disableAutoPan: true,
                        }}
                        position={{ lat: log.lat, lng: log.lng, }}
                    >
                        <div
                        >
                            {`End Journey`}
                        </div>
                    </InfoWindow>
                </Marker>
            )

            const path = [{ lat: log.lat, lng: log.lng }, { lat: nextLog.lat, lng: nextLog.lng }]
            const isHighlighted = log.isHighlighted && nextLog.isHighlighted

            let options = !isHighlighted ? ({
                strokeColor: 'red',
                strokeOpacity: 0.8,
                fillColor: 'red',
                fillOpacity: 0.8
            }) : ({
                strokeColor: 'blue',
                strokeOpacity: 0.8,
                fillColor: 'blue',
                fillOpacity: 0.5,
            })

            return <div
                key={i}
            >
                {
                    i === 0 && (
                        <Marker
                            position={{ lat: log.lat, lng: log.lng, }}
                        >
                            <InfoWindow
                                zIndex={1000}
                                options={{
                                    disableAutoPan: true,
                                }}
                                position={{ lat: log.lat, lng: log.lng, }}
                            >
                                <div
                                >
                                    {`Start Journey`}
                                </div>
                            </InfoWindow>
                        </Marker>
                    )
                }

                <Polyline
                    key={i}
                    path={path}
                    onClick={() => {
                        // console.log(nextLog)
                        setDisplayTrailInfoWindowTime(nextLog.vehicleTime)
                    }}
                    options={{
                        ...options,
                        strokeWeight: 2,
                        fillOpacity: 0.35,
                        icons: [{
                            icon: { path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                            offset: '100%'
                        }],
                        zIndex: 99
                    }}
                    style={{
                        cursor: `pointer`,
                    }}
                />
                {
                    isShowDeviceLogInfoWindow && (
                        <InfoWindow
                            zIndex={1000}
                            options={{
                                disableAutoPan: true,
                            }}
                            // mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                            position={{ lat: log.lat, lng: log.lng, }}
                        >
                            <div
                                style={{
                                    paddingTop: 10,
                                    height: 200,
                                    overflow: 'scroll',
                                    // resize: 'both'
                                }}
                            >
                                <Descriptions
                                    bordered
                                    size={'small'}
                                    column={1}
                                >
                                    <Descriptions.Item
                                        label={`Created At`}
                                    >
                                        {parseTime(log.createdAt)}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`DeviceMessage`}
                                    >
                                        {log.deviceMessage}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Location`}
                                    >
                                        <a
                                            href={`https://www.google.com.my/maps/place/${parseFloat(log.location.lat)},${parseFloat(log.location.lon)}`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            {`${log.location.lat}, ${log.location.lon}`}
                                        </a>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Speed`}
                                    >
                                        {log.speed || 0} km/h
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Altitude`}
                                    >
                                        {log.altitude}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Heading`}
                                    >
                                        {log.heading} degree
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Fuel`}
                                    >
                                        {log.fuel}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Device Time`}
                                    >
                                        {parseTime(log.deviceTime)}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Package Time`}
                                    >
                                        {log.packageTime}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`GPS Status`}
                                    >
                                        {log.gpsStatus}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Engine Status`}
                                    >
                                        {log.engineStatus}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Is Alive`}
                                    >
                                        {log.isAlive}
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={`Device Package`}
                                    >
                                        {log.devicePackage}
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        </InfoWindow>
                    )
                }
            </div>
        })
    }

    return (
        <div>

            {
                playbackMarkerTrail && (
                    <Marker
                        position={{ lat: playbackMarkerTrail.lat, lng: playbackMarkerTrail.lng, }}
                    >
                    </Marker>
                )
            }

            {
                getTransitRecordInfoWindow()
            }
            {
                getEventLogMarkers()
            }
            {
                getTrail()
            }
        </div>

    )
}


export default TripRecordRoute