import * as ActionTypes from "../action-types/vehicles";

import moment from "moment";
import ParseTime from "../../../components/ParseTime";

import { 
    PROCESS_STATUS,
    PROGRESS_TYPES,
    VEHICLE_GROUP_TYPES 
} from "../../../constants";


const defaultState = {
    byVID: {},
    byDVID: {},
    byLogisticalStatus: {
        "ALL": [] ,
        ...Object.values(PROCESS_STATUS).reduce((json, value) => { json[value] = []; return json; }, {})
    }, 
    selectedVID: "",
    allIds: [],
    listIds: [],
    // Used to display allocation progress eg. Dashboard
    // allocateProgress contains 
    // {
    //     [vid]: {
    //         key: "", // For tables
    //         engineNumber: "",
    //         area: "-",
    //         outlet: "-",
    //         allocatedTime: "-", // Either "-" or time in miliseconds
    //         status: "PENDING",
    //         remarks: "", // Can be left blank
    //     }
    // }
    allocateProgress: {}
}

export const vehicles = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_VEHICLES: {
            newState.byVID = JSON.parse(JSON.stringify(defaultState.byVID)); // Clear newState byVID
            
            action.vehicles.forEach((currVehicle) => {
                newState.byVID[currVehicle.vid] = currVehicle;
                newState.byDVID[currVehicle.dvid] = currVehicle.vid;

                currVehicle.logisticalStatus && newState.byLogisticalStatus[currVehicle.logisticalStatus].push(currVehicle.vid)

                if(!newState.allIds.includes(currVehicle.vid)) {
                    newState.allIds.push(currVehicle.vid);
                    newState.listIds.push(currVehicle.vid);
                }
            });

            newState.byLogisticalStatus["ALL"] = newState.allIds;

            return newState;
        }

        case ActionTypes.ADD_VEHICLE: {  
            newState.byDVID[action.newVehicle.vehicle.dvid] = action.newVehicle.vehicle.vid;
            newState.byVID[action.newVehicle.vehicle.vid] = {
                ...action.newVehicle.vehicle,
                logisticalStatus: "CREATED",
                pipeline: action.newVehicle.pipeline,
                profile: {},
            };

            return newState;
        }

        case ActionTypes.EDIT_VEHICLE: {  
            newState.byVID[action.editedVehicle.vid] = action.editedVehicle;

            return newState;
        }
        
        case ActionTypes.ALLOCATE_VEHICLE: {
            action.vgList.forEach((currVG) => {
                newState.byVID[action.selectedVID].logisticalStatus = "ALLOCATED";

                if (currVG.type === VEHICLE_GROUP_TYPES.AREA) {
                    newState.byVID[action.selectedVID].area = currVG.vgid;
                }
                else if (currVG.type === VEHICLE_GROUP_TYPES.OUTLET) {
                    newState.byVID[action.selectedVID].outlet = currVG.vgid;
                }
            })

            return newState;
        }
        
        case ActionTypes.ASSIGN_DEVICE_TO_VEHICLE: {
            newState.byVID[action.vehicle.vid] = {
                ...newState.byVID[action.vehicle.vid],
                ...action.vehicle
            };

            newState.byDVID[action.vehicle.dvid] = action.vehicle.vid;

            return newState;
        }
        
        case ActionTypes.UNASSIGN_DEVICES: {
            action.dvidList.forEach((currDVID) => {
                newState.byVID[newState.byDVID[currDVID]].dvid = "-";
                newState.byVID[newState.byDVID[currDVID]].logisticalStatus = "COMPLETED";

                delete newState.byDVID[currDVID];
            })

            return newState;
        }
        
        case ActionTypes.SET_SELECTED_VID: {
            return {
                ...newState,
                selectedVID: action.newVID
            };
        }
        
        case ActionTypes.CLEAR_SELECTED_VID: {
            newState.selectedVID = JSON.parse(JSON.stringify(defaultState.selectedVID)); // Clear newState selectedVID

            return newState;
        }
        
        case ActionTypes.SET_UP_ALLOCATE_PROGRESS: {
            newState.allocateProgress = {
                ...newState.allocateProgress, // Only add to the allocatedProgress
                [action.selectedVID]: {
                    key: action.selectedVID, // For tables
                    engineNumber: newState.byVID[action.selectedVID].engineNumber,
                    area: "-", // Default value in case area not set
                    outlet: "-", // Default value in case outlet not set
                    allocatedTime: "-",
                    status: PROGRESS_TYPES.PENDING,
                    remarks: "-",
                }
            }

            action.vgList.forEach((currVG) => {
                if (currVG.type === VEHICLE_GROUP_TYPES.AREA) {
                    newState.allocateProgress[action.selectedVID].area = currVG.groupName;
                }
                else if (currVG.type === VEHICLE_GROUP_TYPES.OUTLET) {
                    newState.allocateProgress[action.selectedVID].outlet = currVG.groupName;
                }
            })

            // console.log("New Allocate Progress:", newState.allocateProgress);
            
            return newState;
        }
        
        case ActionTypes.UPDATE_ALLOCATE_PROGRESS: {
            newState.allocateProgress[action.selectedVID] = {
                ...newState.allocateProgress[action.selectedVID],
                allocatedTime: ParseTime(moment.now()),
                status: action.isSuccess ? PROGRESS_TYPES.SUCCESS : PROGRESS_TYPES.FAILED,
                remarks: action.remarks,
            }

            // console.log("Updated Allocate Progress:", newState.allocateProgress);
            
            return newState;
        }

        case ActionTypes.CLEAR_ALLOCATE_PROGRESS: {
            newState.allocateProgress = JSON.parse(JSON.stringify(defaultState.allocateProgress)); // Clear newState allocateProgress

            return newState;
        }

        case ActionTypes.CLEAR_SELECTED_VEHICLE: {
            return {
              ...newState, 
              selectedId: null
            };
          }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Vehicle Store:", defaultState);

            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}