import * as ActionTypes from "../action-types/devices";

const defaultState = {
    byDVID: {},

    selectedDVID: "",
}

export const devices = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_DEVICES: {
            newState.byDVID = JSON.parse(JSON.stringify(defaultState.byDVID)); // Clear newState byDVID
            
            action.devices.forEach((currDevice) => newState.byDVID[currDevice.dvid] = currDevice);

            return newState;
        }

        case ActionTypes.ADD_DEVICE: {
            newState.byDVID[action.newDevice.dvid] = action.newDevice;

            return newState;
        }
        
        case ActionTypes.EDIT_DEVICE: {
            newState.byDVID[action.editedDevice.dvid] = action.editedDevice;

            return newState;
        }
        
        case ActionTypes.SET_SELECTED_DVID: {
            return {
                ...newState,
                selectedDVID: action.newDVID
            };
        }
        
        case ActionTypes.CLEAR_SELECTED_DVID: {
            newState.selectedDVID = JSON.parse(JSON.stringify(defaultState.selectedDVID)); // Clear newState selectedDVID

            return newState;
        }
        
        case ActionTypes.SET_DEVICE_ASSIGNED_STATUS_TO_TRUE_BY_DVID: {
            newState.byDVID[action.selectedDVID].assignedStatus = 1;

            return newState;
        }
        
        case ActionTypes.SET_DEVICE_ASSIGNED_STATUS_TO_FALSE_BY_DVID: {
            newState.byDVID[action.selectedDVID].assignedStatus = 0;

            return newState;
        }
        
        case ActionTypes.SET_ASSIGNED_VEHICLE: {
            if (action.selectedDVID && action.selectedDVID !== "-") {
                newState.byDVID[action.selectedDVID].vid = action.selectedVID;
            }

            return newState;
        }
        
        case ActionTypes.CLEAR_ASSIGNED_VEHICLE: {
            if (action.selectedDVID && action.selectedDVID !== "-") {
                newState.byDVID[action.selectedDVID].vid = "-";
            }

            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Device Store:", defaultState);

            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}