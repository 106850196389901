import React, { useEffect, useState } from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';

import { message } from "antd";
import {
    Marker,
    Circle,
    InfoWindow,
    // OverlayView,
} from '@react-google-maps/api';

// Redux Actions
import {
    set_map_action,
    set_map_selected_geoid,
    clear_map_selected_geoid,
} from '../../../services/redux/actions/mapControl';

import "../gmaps.css";

const POIGeofences = (props) => {
    const { mapRef } = props;

    const dispatch = useDispatch();

    // Redux Stores
    const vehicles = useSelector(state => state.vehicles);
    const geofences = useSelector(state => state.geofences);
    const mapControl = useSelector(state => state.mapControl);

    // State
    const [circleRefs, setCircleRefs] = useState([]);
    const [showInfoWindowGeoIds, setShowInfoWindowGeoIds] = useState({});

    useEffect(() => {
        if (mapControl.selectedGEOID
            && geofences.byGEOID[mapControl.selectedGEOID]
            && geofences.byGEOID[mapControl.selectedGEOID].geofenceType === 'POI'
            && mapControl.currAction === 1) 
        {
            const selectedGeofence = geofences.byGEOID[mapControl.selectedGEOID];

            if (selectedGeofence && selectedGeofence.coordinates.length === 1) {
                if (mapRef) {
                    mapRef.fitBounds(circleRefs.find(currRef => currRef.id === mapControl.selectedGEOID).circle.getBounds());

                    dispatch(set_map_action(0));
                }
            }
            else {
                message.error("Invalid coordinates found for this POI");

                dispatch(clear_map_selected_geoid());
                dispatch(set_map_action(0)); // Must be inside this condition or map would not pan properly
            }
        }
    }, [mapControl.selectedGEOID, dispatch, circleRefs, mapRef, mapControl.currAction, geofences.byGEOID])

    /**Default open all info window, at first */
    // useEffect(() => {
    //     const geoids = Object.keys(geofences.byGEOID)

    //     if (Object.keys(showInfoWindowGeoIds).length === 0) {
    //         geoids.map(geoid => {
    //             showInfoWindowGeoIds[geoid] = 1
    //         })
    //     }
    // }, 
    //     [geofences]
    // )

    const getCircle = (currGeofence) => {
        // console.log("POI to be rendered:", currGeofence);

        const currCoor = {
            lat: currGeofence.coordinates[0].lat,
            lng: currGeofence.coordinates[0].lng,
        }

        const circle = (
            <div key = {currGeofence.geoid}>
                <Circle
                    center = {currCoor}
                    radius = {currGeofence.geofenceRadius}
                    options = {{
                        strokeColor: '#ff0000'
                    }}
                    onLoad = {(ref) => {
                        circleRefs.push({
                            id: currGeofence.geoid,
                            circle: ref
                        });

                        setCircleRefs(circleRefs);
                    }}
                    onClick = {() => {
                        // console.log('circle clicked', currGeofence.geoid);

                        dispatch(set_map_selected_geoid(currGeofence.geoid));
                        dispatch(set_map_action(1));
                    }}
                />

                <Marker
                    key = {currGeofence.geoid}
                    title = {currGeofence.geofenceName}
                    position = {currCoor}
                    onMouseOver = {() => {
                        // console.log("Hovering over POI:", currGeofence.geoid);

                        setShowInfoWindowGeoIds({
                            ...showInfoWindowGeoIds,
                            [currGeofence.geoid]: 1,
                        })
                    }}
                    onMouseOut = {() => {
                        // console.log("Leaving POI:", currGeofence.geoid);

                        setShowInfoWindowGeoIds({
                            ...showInfoWindowGeoIds,
                            [currGeofence.geoid]: 0,
                        })
                    }}
                    onClick = {() => {
                        // console.log('marker clicked', currGeofence.geoid);

                        dispatch(set_map_selected_geoid(currGeofence.geoid));
                        dispatch(set_map_action(1));
                    }}
                >
                    {
                        // open or close all infowindow from mapControl
                        ((mapControl.toggleControl && mapControl.toggleControl.showAllGeofencesInfoWindow))
                        || 
                        (
                            showInfoWindowGeoIds[currGeofence.geoid]
                            && (
                                <InfoWindow
                                    position = {currCoor}
                                    options = {{
                                        pixelOffset: {
                                            height: -45
                                        }
                                    }}
                                >
                                    {/* <div className = "geofenceMarkerOverlayView"> */}
                                    <div>
                                        <h3>{currGeofence.geofenceName}</h3>

                                        {
                                            currGeofence.comment && 
                                                <div className = "geofenceMarkerOverlayViewComment">
                                                    {currGeofence.comment}
                                                </div>
                                        }
                                    </div>
                                </InfoWindow>
                            )
                        )
                    }
                </Marker>
            </div>
        )

        return circle;
    }

    return (
        vehicles &&
            Object.keys(geofences.byGEOID)
            .filter(geoid => {
                return geofences.byGEOID[geoid].geofenceType === 'POI';
            })
            .map(geoid => {
                return getCircle(geofences.byGEOID[geoid]);
            })
    )
}

export default POIGeofences