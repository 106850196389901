import * as ActionTypes from "../action-types/mapDrawControl";

export const set_drawn_geofence = (newDrawnGeofence) => {
    // console.log("New Drawn Geofence:", newDrawnGeofence);

    return {
        type: ActionTypes.SET_DRAWN_GEOFENCE,
        newDrawnGeofence: newDrawnGeofence
    }
}

export const clear_drawn_geofence = () => {
    return {
        type: ActionTypes.CLEAR_DRAWN_GEOFENCE,
    }
}