import React, { useState, useEffect } from 'react';
import { 
    useDispatch, 
    useSelector,
} from 'react-redux';

// import MapSearchBar from '../MapSearchBar';
// import MapControlPanel from '../MapControlPanel';
// import POIGeofences from "./components/POIGeofences";
// import VehicleMarkers from "./components/VehicleMarkers";
// import PolygonGeofences from "./components/PolygonGeofences";
import GoogleMapsLoadScript from "./components/GoogleMapsLoadScript";

import { message } from "antd";
import {
    GoogleMap,
    // TrafficLayer,
} from '@react-google-maps/api';
// import {
//     MAP_DEFAULT_ZOOM,
//     MAP_DEFAULT_LOCATION,
// } from "../../constants";

// Redux Actions
import { set_map_action } from '../../services/redux/actions/mapControl';
// import { clear_selected_device } from "../../services/redux/actions/devices.js";
// import { clear_selected_tsid } from "../../services/redux/actions/transitRecord.js";
// import { clear_selected_geoid } from '../../services/redux/actions/geofences';
// import {
//     clear_enabled_vehicle_pop_ups,
//     clear_enabled_geofence_pop_ups,
// } from "../../services/redux/actions/mapControl";

import "./gmaps.css";

const  MapDefault = (props) => {
    const geofences = useSelector(state => state.geofences);
    const mapControl = useSelector(state => state.mapControl);

    const dispatch = useDispatch();

    const mapRef = useState(null)[0];

    // const getMapCenterAndZoom = () => {
    //     if (mapRef) {
    //         return {
    //             zoom: mapRef.getZoom(),
    //             center: mapRef.getCenter(),
    //         }
    //     }

    //     return {
    //         zoom: MAP_DEFAULT_ZOOM,
    //         center: MAP_DEFAULT_LOCATION,
    //     }
    // }

    // Initial mount of component
    useEffect(() => {
        // console.log('new google maps component onMount');

        // dispatch(clear_selected_device());
        // dispatch(clear_enabled_vehicle_pop_ups());
        // dispatch(clear_selected_geoid());
        // dispatch(clear_enabled_geofence_pop_ups());
    }, 
        [dispatch]
    )

    // ComponentDidUpdate()
    useEffect(() => {
        let zoom, location;

        /**
         * Pan to selected transit status
         */
        if (geofences.selectedId
            && geofences.byGEOID[geofences.selectedId]
            && geofences.byGEOID[geofences.selectedId].coordinates.length > 0
            && mapControl.currAction === 1) 
        {
            const selectedGeofence = geofences.byGEOID[geofences.selectedId];
            
            // console.log("Selected Transit:", selectedTransit);

            if (selectedGeofence.coordinates[0] && selectedGeofence.coordinates[0].lat && selectedGeofence.coordinates[0].lng) {
                // console.log("Test 1");

                zoom = 20;
    
                location = {
                    lat: parseFloat(selectedGeofence.coordinates[0].lat),
                    lng: parseFloat(selectedGeofence.coordinates[0].lng),
                }
    
                if (mapRef) {
                    mapRef.setZoom(zoom);
                    mapRef.panTo(location);

                    // Do not set map action to 0 here or panning won't finish
                }
            }
            else {
                message.error("No location found for this transit status");

                // dispatch(clear_selected_tsid());
                
                dispatch(set_map_action(0));
            }
        }
    })

    return (
        <GoogleMap
            id = 'example-map'
            // center = {getMapCenterAndZoom().center}
            // zoom = {getMapCenterAndZoom().zoom}
            mapContainerStyle = {{
                height: '100vh'
            }}
            // onLoad = {ref => setMapRef(ref)}
            // onDragStart = {() => {
            //     dispatch(set_map_action(0))
            // }}
        >
            {/* <POIGeofences mapRef = {mapRef}/>
            <VehicleMarkers mapRef = {mapRef}/>
            <PolygonGeofences mapRef = {mapRef}/> */}

            {/* {mapControl.toggleControl.showLiveTraffic && <TrafficLayer/>} */}

            {/* <div className = "searchBar">
                <MapSearchBar/>
            </div> */}

            {/* <div className = "controlPanel">
                <MapControlPanel/>
            </div> */}
        </GoogleMap>
    )
}

const MapComponent = (props) => GoogleMapsLoadScript(MapDefault, props);
export default MapComponent;