import React from "react";
import { connect } from "react-redux";

import {
    Modal, 
    Table,
} from "antd";

// Redux Actions
import { unassign_devices_from_vehicles } from "../../../services/redux/actions/vehicles";

class PopUpUnpair extends React.Component {
    state = {
        dvidList: [],
        dataSource: [],
    }

    componentDidMount = () => {
        // console.log(this.props.selectedVID);

        if (this.props.selectedVID) {
            const { 
                vehicles, 
                selectedVID, 
            } = this.props;

            this.setState({
                dvidList: [vehicles.byVID[selectedVID].dvid], // Need to use an array for unassign API
                dataSource: [{
                    key: selectedVID,
                    dvid: vehicles.byVID[selectedVID].dvid,
                    engineNumber: vehicles.byVID[selectedVID].engineNumber,
                }]
            },
                // console.log("New Data Source:", this.state.dataSource)
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.vehicles !== this.props.vehicles) {
            this.props.onCloseModal();
        }
    }

    render() {
        const columns = [
            {
                title: "Engine Number",
                dataIndex: "engineNumber",
            },
    
            {
                title: "Device ID",
                dataIndex: "dvid",
            },
        ]

        return (
            <div>
                <Modal
                    title = "Unpair Device"
                    visible = {true}
                    closable = {false}
                    okButtonProps = {{ loading: this.props.style.isLoadingSubmit }}
                    cancelButtonProps = {{ loading: this.props.style.isLoadingSubmit }}
                    onOk = {() => this.props.dispatch(unassign_devices_from_vehicles(this.state.dvidList, this.props.user.uid))}
                    onCancel = {() => this.props.onCloseModal()}
                >
                    <b 
                        style = {{
                            display: "flex",
                            justifyContent: "center",

                            marginBottom: 20,
                        }}
                    >
                        Are you sure you want to unpair this device?
                    </b>
                    
                    <Table 
                        columns = {columns}
                        dataSource = {this.state.dataSource}
                        pagination = {true}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
});

export default connect(mapStateToProps)(PopUpUnpair);