import React, { useEffect } from 'react';
import {
    useSelector,
    useDispatch,
} from 'react-redux';

import { message } from "antd";
import { getLatLngCenter } from "../methods/getLatLngCenter";
import {
    Polygon,
    InfoWindow,
} from '@react-google-maps/api';

// Redux Actions
import {
    set_map_action,
    clear_map_selected_geoid,
} from '../../../services/redux/actions/mapControl';

import "../gmaps.css";

const PolygonGeofences = (props) => {
    const { mapRef } = props;

    const dispatch = useDispatch();

    // Redux Stores
    const geofences = useSelector(state => state.geofences);
    const mapControl = useSelector(state => state.mapControl);

    useEffect(() => {
        if (mapControl.selectedGEOID
            && geofences.byGEOID[mapControl.selectedGEOID]
            && geofences.byGEOID[mapControl.selectedGEOID].geofenceType === 'Polygon'
            && mapControl.currAction === 1) 
        {
            const selectedGeofence = geofences.byGEOID[mapControl.selectedGEOID];

            if (selectedGeofence && selectedGeofence.coordinates.length > 2) {
                if (mapRef && window.google) {
                    const bounds = new window.google.maps.LatLngBounds();

                    selectedGeofence.coordinates.map(currCoor => bounds.extend(currCoor));
                    mapRef.fitBounds(bounds);

                    dispatch(set_map_action(0));
                }
            }
            else {
                message.error("Invalid coordinates found for this polygon");

                dispatch(clear_map_selected_geoid());
                dispatch(set_map_action(0)); // Must be inside this condition or map would not pan properly
            }
        }
    },
        [mapControl.selectedGEOID, dispatch, geofences.byGEOID, mapControl.currAction, mapRef]
    )

    const getPolygon = (currGeofence) => {
        const polygon = (
            <div key = {currGeofence.geoid}>
                <Polygon path = {currGeofence.coordinates}/>

                {
                    // open or close all infowindow from mapControl
                    <InfoWindow position = {getLatLngCenter(currGeofence.coordinates)}>
                        <div>
                            <h3>{currGeofence.geofenceName}</h3>

                            {currGeofence.comment && <div className = "geofenceMarkerOverlayViewComment">{currGeofence.comment}</div>}
                        </div>
                    </InfoWindow>
                }
            </div>
        )

        return polygon;
    }

    return (
        geofences &&
        Object.keys(geofences.byGEOID)
            .filter(geoid => {
                return geofences.byGEOID[geoid].geofenceType === 'Polygon';
            })
            .map(geoid => {
                return getPolygon(geofences.byGEOID[geoid]);
            })
    )
}


export default PolygonGeofences