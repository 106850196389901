import React, { useEffect } from 'react';
import { 
    useDispatch, 
    useSelector,
} from 'react-redux';

import ParseTime from "../../../components/ParseTime";

import { message } from "antd";
import {
    Marker,
    OverlayView,
} from '@react-google-maps/api';
import {
    RULE_TYPE,
    EVENT_COLORS,
    DISPLAY_RULE_TYPE,
} from "../../../constants";

// Redux Actions
import { 
    set_map_action,
    set_map_selected_evid,
    clear_map_selected_evid
} from "../../../services/redux/actions/mapControl";

import "../gmaps.css";

const EventMarkers = (props) => {
    const { mapRef } = props;

    const dispatch = useDispatch();

    const events = useSelector(state => state.events);
    const mapControl = useSelector(state => state.mapControl);

    const getEventMarker = (currEvID) => {
        const currEvent = events.byVID[currEvID];

        // console.log("Current Event:", currEvent);

        if (currEvent) {
            const currCoor = currEvent.location ? 
                {
                    lat: parseFloat(currEvent.location.lat),
                    lng: parseFloat(currEvent.location.lng)
                } : 
                {
                    lat: 0,
                    lng: 0
                };

            // Some currEventPoint don't have start locations and thus doesn't have tooltips
            function getEventMarkerIcon(eventType) {
                switch (eventType) {
                    case DISPLAY_RULE_TYPE[RULE_TYPE.WARNING]: {
                        return require('../../../img/Trip Record/warning.svg')                            
                    }
                        
                    case DISPLAY_RULE_TYPE[RULE_TYPE.CRITICAL]: {
                        return require('../../../img/Trip Record/critical.svg')
                    }
                        
                    case DISPLAY_RULE_TYPE[RULE_TYPE.INFO]:
                    default: {
                        return require('../../../img/Trip Record/info.svg')
                    }
                }
            }

            return (
                currEvent.location
                && currEvent.location.lat
                && currEvent.location.lng &&
                    <Marker
                        key = {currEvID}
                        icon = {{
                            url: getEventMarkerIcon(currEvent.eventType),
                            anchor: new window.google.maps.Point(25 / 2, 25 / 2), // 25 is sprite width and height. Make sure to match future anchors accordingly
                        }}
                        position = {currCoor}
                        onClick = {() => {
                            // console.log('Event Marker Clicked');

                            dispatch(set_map_action(1));
                            dispatch(set_map_selected_evid(currEvID));
                        }}
                    >
                        {
                            mapControl.selectedEVID === currEvID &&
                                <OverlayView
                                    options = {{ maxWidth: 250 }}
                                    position = {currCoor}
                                    mapPaneName = {OverlayView.OVERLAY_MOUSE_TARGET}
                                    getPixelPositionOffset = {(width, height) => ({
                                        x: -(width / 2),
                                        y: -height - 15,
                                    })}
                                    style = {{
                                        marginBottom: 10,
                                    }}
                                    // onCloseClick = {() => dispatch(clear_map_selected_evid())}
                                >
                                    <div
                                        style = {{
                                            background: "white",
                                          
                                            borderRadius: 10,
                                            border: "1px solid #ccc",
                                          
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                            paddingTop: 8,
                                            paddingBottom: 10,
                                        }}
                                    >
                                        <h3>{ParseTime(currEvent.createdAt)}</h3>

                                        <h4
                                            style = {{
                                                fontWeight: 'bold',
                                                color: EVENT_COLORS[currEvent.eventType],
                                            }}
                                        >
                                            <b>{currEvent.eventType}</b>
                                        </h4>

                                        <span style = {{ marginRight: 3 }}>
                                            {currEvent.eventName}
                                        </span>

                                        {/* <br /> */}

                                        {/* <span 
                                            onClick = {
                                                () => {
                                                    dispatch(set_new_geofence_from_map({ coordinates: [currCoor] }));

                                                    dispatch(moveToPage('/Geofence/AddNewPOI'));
                                                }
                                            }
                                        >
                                            <div className = 'link-button'>Add as POI</div>
                                        </span> */}
                                    </div>
                                </OverlayView>
                        }
                    </Marker>
            );
        }
    }

    // ComponentDidUpdate()
    useEffect(() => {
        if (mapControl.selectedEVID && events.byVID[mapControl.selectedEVID] && mapControl.currAction === 1) {
            const selectedEvent = events.byVID[mapControl.selectedEVID];

            // console.log("Selected Event:", selectedEvent);

            if (selectedEvent.location && selectedEvent.location.lat && selectedEvent.location.lng) {   
                // console.log("Panning to event node:", selectedEvent.location.lat, selectedEvent.location.lng);
                
                if (mapRef) {
                    // console.log("Test");

                    mapRef.setZoom(20);
                    mapRef.panTo({
                        lat: parseFloat(selectedEvent.location.lat),
                        lng: parseFloat(selectedEvent.location.lng),
                    });

                    // Do not set map action to 0 here or panning won't finish
                    dispatch(set_map_action(0));
                }
            }
            else {
                message.error("No location found for this event");

                dispatch(clear_map_selected_evid());
                dispatch(set_map_action(0)); // Must be inside this condition or map would not pan properly
            }
        }
    })

    return (
        Object.keys(events.byVID).map(currEvID => {
            return getEventMarker(currEvID);
        })
    )
}

export default EventMarkers