import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getUserInfoByUID = (uid) => (
    fetch(`${api}/user/getinfo`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid" : uid,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const getUserDevicesAndDeviceGroupsByDGIDs = (dgidList) => (
    fetch(`${api}/device/group/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "dgids" : dgidList,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const getUserVehiclesAndVehicleGroupsByVGIDs = (vgidList) => (
    fetch(`${api}/vehicle/group/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "vgids" : vgidList,
        })
    })
    .then(res => res.json())
    .then(data => data)
)

export const getUserGeofencesAndGeofenceTemplatesByGTIDs = (gtidList) => (
    fetch(`${api}/geofence/template/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "gtids" : gtidList,
        })
    })
    .then(res => res.json())
    .then(data => data)
)