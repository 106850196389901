import * as ActionTypes from "../action-types/vehicleGroups";
import { VEHICLE_GROUP_TYPES } from "../../../constants";

const defaultState = {
    byVGID: {},
    areas: [],
    outlets: [],
}

export const vehicleGroups = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_VEHICLE_GROUPS: {
            newState.byVGID = JSON.parse(JSON.stringify(defaultState.byVGID)); // Clear newState byVGID
            newState.areas = JSON.parse(JSON.stringify(defaultState.areas)); // Clear newState areas
            newState.outlets = JSON.parse(JSON.stringify(defaultState.outlets)); // Clear newState outlets
            
            action.vehicleGroups.forEach((currVG) => {
                newState.byVGID[currVG.vgid] = currVG;

                if (currVG.type === VEHICLE_GROUP_TYPES.AREA) {
                    newState.areas.push(currVG.vgid);
                }
                else if (currVG.type === VEHICLE_GROUP_TYPES.OUTLET) {
                    newState.outlets.push(currVG.vgid);
                }
            });

            return newState;
        }

        case ActionTypes.UPDATE_VEHICLE_GROUPS_AFTER_ALLOCATE: {            
            action.updatedVGs.forEach((currVG) => newState.byVGID[currVG.vgid] = currVG);

            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Vehicle Group Store:", defaultState);

            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}