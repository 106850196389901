import React from "react";
import { connect } from "react-redux";

import {
    Modal, 
    Table,
} from "antd";

// Redux Actions
import { unassign_devices_from_vehicles } from "../../../services/redux/actions/vehicles";

class PopUp_Pair extends React.Component {
    state = {
        dvidList: [],
        vehicleList: [],
    }

    componentDidMount = () => {
        // console.log(this.props.selectedDVIDs);

        if (this.props.selectedDVIDs.length > 0) {
            const { vehicles } = this.props;

            this.setState({
                dvidList: this.props.selectedDVIDs.filter((currDVID) => vehicles.byDVID[currDVID]),
                vehicleList: this.props.selectedDVIDs
                    .sort((a, b) => {
                        const vA = vehicles.byVID[vehicles.byDVID[a]].engineNumber.toLowerCase();
                        const vB = vehicles.byVID[vehicles.byDVID[b]].engineNumber.toLowerCase();

                        if (vA < vB) return -1;
                        if (vA > vB) return 1;
                        return 0;
                    })
                    .map((currDVID) => {
                        const selectedVehicle = vehicles.byVID[vehicles.byDVID[currDVID]];

                        return {
                            key: selectedVehicle.vid,
                            dvid: currDVID,
                            engineNumber: selectedVehicle.engineNumber,
                        }
                    })
            },
                // console.log("New Vehicle List:", this.state.vehicleList)
            )
        }
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.vehicles !== this.props.vehicles) {
            this.props.onCloseModal();
        }
    }

    unassignDevices = () => {

        const dvids = this.state.dvidList
        const uid = this.props.user.uid

        // console.log({
        //     dvids,
        //     uid
        // })

        this.props.dispatch(unassign_devices_from_vehicles(dvids, uid))
    }

    render() {
        const columns = [
            {
                title: "Engine Number",
                dataIndex: "engineNumber",
            },
    
            {
                title: "Device ID",
                dataIndex: "dvid",
            },
        ]

        return (
            <div>
                <Modal
                    title = "Unpair Device"
                    visible = {true}
                    closable = {false}
                    okButtonProps = {{ loading: this.props.style.isLoadingSubmit }}
                    cancelButtonProps = {{ loading: this.props.style.isLoadingSubmit }}
                    onOk = {() => this.unassignDevices()}
                    onCancel = {() => this.props.onCloseModal()}
                >
                    <b 
                        style = {{
                            display: "flex",
                            justifyContent: "center",

                            marginBottom: 20,
                        }}
                    >
                        {
                            this.state.dvidList.length > 1 ? 
                                "Are you sure you want to unpair these devices?" : 
                                "Are you sure you want to unpair this device?"
                        }
                    </b>
                    
                    <Table 
                        columns = {columns}
                        dataSource = {this.state.vehicleList}
                        pagination = {true}
                    />
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
});

export default connect(mapStateToProps)(PopUp_Pair);