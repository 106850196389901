export const GET_DEVICES = "GET_DEVICES";

export const ADD_DEVICE = "ADD_DEVICE";
export const EDIT_DEVICE = "EDIT_DEVICE";

export const SET_SELECTED_DVID = "SET_SELECTED_DVID";
export const CLEAR_SELECTED_DVID = "CLEAR_SELECTED_DVID";

export const SET_DEVICE_ASSIGNED_STATUS_TO_TRUE_BY_DVID = "SET_DEVICE_ASSIGNED_STATUS_TO_TRUE_BY_DVID";
export const SET_DEVICE_ASSIGNED_STATUS_TO_FALSE_BY_DVID = "SET_DEVICE_ASSIGNED_STATUS_TO_FALSE_BY_DVID";

export const SET_ASSIGNED_VEHICLE = "SET_ASSIGNED_VEHICLE";
export const CLEAR_ASSIGNED_VEHICLE = "CLEAR_ASSIGNED_VEHICLE";