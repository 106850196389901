// import * as API from "../../api/vehicleGroups";
import * as ActionTypes from "../action-types/vehicleGroups";

// Exported to user action
export const get_vehicle_groups_success = (vehicleGroups) => {
    return {
        type: ActionTypes.GET_VEHICLE_GROUPS,
        vehicleGroups: vehicleGroups,
    };
}
        
// Exported to vehicles action script
export const update_vehicle_groups_after_allocate = (updatedVGs) => {
    return {
        type: ActionTypes.UPDATE_VEHICLE_GROUPS_AFTER_ALLOCATE,
        updatedVGs: updatedVGs,
    };
}