import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getGeofenceRecordByTimeframe = (vidList, vgidList, geoidList, startTime, endTime) => (
    fetch(`${api}/geofencerecord/get`,{
        method: 'POST',
        headers,
        body: JSON.stringify({
            "vgids": vgidList,
            "vids": vidList,
            "geoids": geoidList,
            "startTime": Number(startTime),
            "endTime": Number(endTime)
        })
    })  
    .then(res => res.json())
)