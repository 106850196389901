import * as ActionTypes from "../action-types/statusBarControl";

const defaultState = {
    selectedStatus: "ALL", 
    selectedEventType: "ALL",
}

export const statusBarControl = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.SELECT_STATUS: {
            return {
                ...newState, 
                selectedStatus: action.status
            }
        }

        case ActionTypes.SELECT_EVENT_TYPE: {
            return {
                ...newState, 
                selectedEventType: action.eventType
            }
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Status Bar Control Store:", defaultState);

            return defaultState;
        }

        default: {
            return newState;
        }
    }
}
