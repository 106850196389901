import * as ActionTypes from "../action-types/geofenceReports";

const defaultState =  {
    byGEOID: {},
    selectedTime: { startTime: null, endTime: null },
}

export const geofenceReports = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_GEOFENCE_REPORTS: {
            newState.byGEOID = {};

            action.geofenceReports.map(record => newState.byGEOID[record.geoCreatedAt] = record);

            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Geofence Report Store:", defaultState);

            return defaultState;
        }

        default: {
            return state;
        }
    }
}
