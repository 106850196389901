import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const addGeofence = (uid, geofence, gtidList) => (
    fetch(`${api}/geofence/add`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "uid": uid,
            "geofence": geofence,
            "gtids": gtidList,
        })
    })  
    .then(res => res.json())
)

export const editGeofence = (editedGeofence) => (
    fetch(`${api}/geofence/update`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "geofence": editedGeofence,
        })
    })  
    .then(res => res.json())
)