import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../components/Page";
import ParseTime from "../../components/ParseTime";
import UploadExcelButton from '../../components/UploadExcelButton'
import ExportExcelButton from "../../components/ExportExcelButton"
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from '../../components/PrimaryButton';
import { moveToPage } from "../../navigation/navigationService";
// import { EmptyIndicator } from "../../components/EmptyIndicator";
import {
    Form,
    Menu,
    Input,
    Table,
    Button,
    Tooltip,
    Dropdown,
    DatePicker
} from "antd";
import {
    EyeOutlined,
    SearchOutlined,
    MonitorOutlined,
    EditOutlined,
    MoreOutlined,
    SwapOutlined,
    ApiOutlined,
    UploadOutlined
} from '@ant-design/icons'

import {
    DEVICE_STATUS,
    TEMPLATE_URLS,
    VEHICLE_PIPELINE_STATUS,
} from "../../constants";

// Redux Actions
import {
    set_selected_vid,
    add_vehicle_success,
    clear_allocate_progress,
    allocate_vehicle_success,
    assign_device_to_vehicle_success,
} from "../../services/redux/actions/vehicles";
import { set_selected_dvid } from "../../services/redux/actions/devices";

import * as API from '../../services/api/vehicles'
import PopUpAllocate from './PopUpAllocate';
import PopUpPair from './PopUpPair';
import PopUpUnpair from './PopUpUnpair';
import parseDuration from '../../components/ParseDuration';
import Highlighter from 'react-highlight-words';

const POP_UP_ALLOCATE = "ALLOCATE";
const POP_UP_PAIR = "PAIR";
const POP_UP_UNPAIR = "UNPAIR";


class VehicleManagement extends React.Component {
    state = {
        startTime: moment().startOf("month"),
        endTime: moment().add(1, 'day').startOf('day'),
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
        </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
        </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={(text && text.toString()) || ``}
                />
            ) : (
                    text
                ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    setUpDataSource = () => {
        const {
            endTime,
            startTime,
        } = this.state;

        const {
            vehicles,
        } = this.props;


        const dataSource =
            Object.values(vehicles.byVID)
                .filter(vehicle => {
                    try {

                        if (startTime && startTime.valueOf() > vehicle.createdAt) throw new Error(`startTime`)
                        if (endTime && endTime.valueOf() < vehicle.createdAt) throw new Error(`endTime`)
                        return true
                    } catch (err) {
                        return false
                    }
                })
                .sort((a, b) => b.createdAt - a.createdAt)
                .map((vehicle) => {
                    const device = this.props.devices.byDVID[vehicle.dvid]
                    const area = this.props.vehicleGroups.byVGID[vehicle.area]
                    const outlet = this.props.vehicleGroups.byVGID[vehicle.outlet]
                    const geofence = Object.values(this.props.geofence.byGEOID)
                        .find(geofence => vehicle.profile && vehicle.profile[geofence.geoid] === 1)

                    const deltaTime = moment.now() - vehicle.serverTime
                    // console.log(`Delta Time: ${deltaTime}`);
                    const duration = (moment.now() - vehicle.logisticalFrom) + deltaTime

                    return {
                        key: vehicle.vid,
                        engineNumber: vehicle.engineNumber,
                        chassisNumber: vehicle.chassisNumber,
                        vehicleModel: vehicle.vehicleModel,
                        geofenceName: geofence && geofence.geofenceName,
                        area: area && area.groupName,
                        outlet: outlet && outlet.groupName,
                        imei: device && device.dvid.split('#')[1],
                        deviceStatus: (device && device.deviceStatus === 0 && DEVICE_STATUS.DISCONNECTED)
                            || (device && device.deviceStatus === 1 && DEVICE_STATUS.CONNECTED)
                            || (device && device.deviceStatus === 2 && DEVICE_STATUS.TEMPERED),
                        logisticalStatus: vehicle.logisticalStatus,
                        duration: duration,
                        lastStatusUpdated: vehicle.logisticalFrom,
                        updatedAt: vehicle.updatedAt,
                        createdAt: vehicle.createdAt,
                    }
                })

        const columns = [
            {
                title: "Engine Number",
                dataIndex: "engineNumber",
                fixed: 'left',
                ...this.getColumnSearchProps('engineNumber')
            },

            {
                title: "Chassis Number",
                dataIndex: "chassisNumber",
                ...this.getColumnSearchProps('engineNumber')
            },

            {
                title: "Vehicle Model",
                dataIndex: "vehicleModel",
                ...this.getColumnSearchProps('vehicleModel')
            },

            {
                title: 'Region',
                dataIndex: "area",
                ...this.getColumnSearchProps('area')
            },

            {
                title: 'Outlet',
                dataIndex: "outlet",
                ...this.getColumnSearchProps('outlet')
            },

            {
                title: "Device IMEI",
                dataIndex: "imei",
                ...this.getColumnSearchProps('imei')

            },

            {
                title: "Device Status",
                dataIndex: "deviceStatus",
                render: deviceStatus => {
                    switch (deviceStatus) {
                        case DEVICE_STATUS.CONNECTED:
                            return <b style={{ color: 'green' }}>{deviceStatus}</b>
                        case DEVICE_STATUS.DISCONNECTED:
                            return <b style={{ color: 'red' }}>{deviceStatus}</b>
                        case DEVICE_STATUS.TEMPERED:
                            return <b style={{ color: 'black' }}>{deviceStatus}</b>
                        default:
                            return <b>{deviceStatus}</b>
                    }
                },
                filters: Object.values(DEVICE_STATUS).map(status => ({ text: status, value: status })),
                onFilter: (value, record) => record.deviceStatus.indexOf(value) === 0,
            },
            {
                title: "Geofence",
                dataIndex: "geofenceName",
                ...this.getColumnSearchProps('geofenceName')
            },
            {
                title: "LP Status",
                dataIndex: "logisticalStatus",
                ...this.getColumnSearchProps('logisticalStatus')

            },
            {
                title: "Lead Time",
                dataIndex: "duration",
                sorter: (a, b) => a.duration - b.duration,
                render: time => parseDuration(time)
            },

            {
                title: "Last Status Updated",
                dataIndex: "lastStatusUpdated",
                sorter: (a, b) => a.lastStatusUpdated - b.lastStatusUpdated,
                render: time => ParseTime(time)
            },
            {
                title: " Last Device Update at",
                dataIndex: "updatedAt",
                sorter: (a, b) => b.updatedAt - a.updatedAt,
                render: time => ParseTime(time)
            },

            {
                title: "Created At",
                dataIndex: "createdAt",
                sorter: (a, b) => b.creationDate - a.creationDate,
                render: time => ParseTime(time)
            },
            {
                title: "Actions",
                fixed: 'right',
                render: (rowData) => {
                    const vehicle = vehicles.byVID[rowData.key]
                    if (!vehicle) return
                    const isCompleted = vehicle.status === VEHICLE_PIPELINE_STATUS.COMPLETED
                    // const hasYard = Object.keys(vehicle.pipeLine || {}).find(status => status.includes(VEHICLE_PIPELINE_STATUS.YARD))
                    const isPaired = vehicle.dvid && vehicle.dvid !== "-"

                    const MORE_OPTIONS = [
                        {
                            label: 'Allocate',
                            isDisabled: false,
                            onClick: () => {
                                this.setState({
                                    currPopUp: POP_UP_ALLOCATE,
                                    selectedVIDList: [vehicle.vid]
                                })
                            }
                        },
                        {
                            label: 'Pair',
                            isDisabled: isCompleted || isPaired,
                            onClick: () => {
                                this.setState({
                                    currPopUp: POP_UP_PAIR,
                                    selectedVID: vehicle.vid
                                })
                            }
                        },
                        {
                            label: 'Unpair',
                            isDisabled: isCompleted || !isPaired,
                            onClick: () => {

                                this.setState({
                                    currPopUp: POP_UP_UNPAIR,
                                    selectedVID: vehicle.vid
                                })
                            }
                        }
                    ]
                    return (
                        <div>
                            <Tooltip title={"View on Map"} style={{ display: "flex", flexDirection: "row" }}>
                                <button className="transparent-button">
                                    <EyeOutlined 
                                         onClick={() => {
                                            const state = {
                                                vid: vehicle.vid,
                                                lat: vehicle.location.lat,
                                                lng: vehicle.location.lng,
                                                zoom: 25,
                                            }
                                            // console.log({ state })
                                            this.props.dispatch(moveToPage('/', state))
                                        }}
                                    />
                                </button>
                            </Tooltip>
                            {
                                vehicle.dvid && vehicle.dvid !== "-"
                                &&
                                (
                                    <Tooltip title={"Device Inspector Log"} style={{ display: "flex", flexDirection: "row" }}>
                                        <button className="transparent-button">
                                            <MonitorOutlined 
                                                onClick={() => {
                                                    this.props.dispatch(set_selected_vid(vehicle.key));
                                                    this.props.dispatch(set_selected_dvid(vehicle.dvid));
                                                    this.props.dispatch(moveToPage(`/DeviceManagement/DeviceLogInspector`));
                                                }}
                                            />
                                        </button>
                                    </Tooltip>
                                )
                            }
                            <Tooltip title={"Assignment Log"} style={{ display: "flex", flexDirection: "row" }}>
                                <button className="transparent-button">
                                    <MonitorOutlined 
                                        onClick={() => {
                                            this.props.dispatch(set_selected_vid(vehicle.key));
                                            this.props.dispatch(moveToPage(`/VehicleManagement/AssignmentLogInspector`, { vid: vehicle.vid }));
                                        }}
                                    />
                                </button>
                            </Tooltip>
                            <Tooltip title={"Edit vehicle"} style={{ display: "flex", flexDirection: "row" }}>
                                <button className="transparent-button">
                                    <EditOutlined 
                                        onClick={() => {
                                            this.props.dispatch(set_selected_vid(vehicle.key));
                                            this.props.dispatch(moveToPage(`/VehicleManagement/EditVehicle`, { vid: vehicle.vid }));
                                        }}
                                    />
                                </button>
                            </Tooltip>
                            <Tooltip title={"More"} style={{ display: "flex", flexDirection: "row" }} >
                                <Dropdown
                                    overlay={
                                        <Menu>
                                            {
                                                MORE_OPTIONS.map(option => {
                                                    return (
                                                        <Menu.Item
                                                            disabled={option.isDisabled}
                                                            key={option.label}
                                                            onClick={option.onClick}
                                                        >
                                                            {option.label}
                                                        </Menu.Item>
                                                    )
                                                })
                                            }
                                        </Menu>
                                    }
                                    trigger={[`click`]}
                                >
                                    <MoreOutlined />
                                </Dropdown>
                            </Tooltip>
                        </div>
                    )
                }
            }
        ];

        this.setState({
            columns,
            dataSource
        })
    }

    onCloseModal = () => {
        this.props.dispatch(clear_allocate_progress());

        this.setState({
            currPopUp: "",
            selectedVID: "",
            selectedVIDList: [],
        })
    }

    componentDidMount = () => {
        this.setUpDataSource()
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setUpDataSource()
        }
    }

    render() {
        const {
            dataSource = [],
            columns = []
        } = this.state;

        return (
            <div className='page-container'>
                <Page title="Vehicle Management">
                    <div style={{ display: "flex", }}>

                         <Form layout='inline'>
                            <Form.Item
                                label={`Start time`}
                            >
                                <DatePicker
                                    showTime
                                    defaultValue={this.state.startTime}
                                    onChange={value => this.setState({ startTime: value })}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={`End time`}
                            >
                                <DatePicker
                                    showTime
                                    defaultValue={this.state.endTime}
                                    onChange={value => this.setState({ endTime: value })}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <PrimaryButton
                                    style={{ marginLeft: '15px' }}
                                    onClick={this.setUpDataSource}
                                    disabled={!((this.state.startTime && this.state.endTime))}
                                >
                                    Submit
                                </PrimaryButton>
                            </Form.Item>
                        </Form>

                        <UploadExcelButton
                            icon={<SwapOutlined />}
                            title='Bulk allocate vehicles'
                            buttonName={`Bulk Allocate Vehicles`}
                            loading={this.props.style.isLoadingSubmit || Object.keys(this.props.vehicleGroups.byVGID).length === 0}
                            templateUrl={TEMPLATE_URLS.BULK_ALLOCATE_VEHICLES_TEMPLATE}
                            excelTemplateName={`Bulk Allocate Vehicle Template`}
                            dataColumns={
                                {
                                    engineNumber: {
                                        label: 'Engine Number',
                                        rule: (engineNumber, allEngineNumbers) => {
                                            const isExist = Object.values(this.props.vehicles.byVID).find(vehicle => {
                                                return (engineNumber && engineNumber.toString()) === vehicle.engineNumber
                                            })
                                            if (!isExist) {
                                                throw new Error(`Engine Number does not exist`)
                                            }
                                        },
                                    },
                                    region: {
                                        optional: true,
                                        label: 'Region',
                                        rule: (region, allRegions) => {
                                            const areas = this.props.vehicleGroups.areas
                                                .map(vgid => this.props.vehicleGroups.byVGID[vgid])
                                                .map(vg => vg.groupName)

                                            const isExist = areas.find(groupName => groupName === region)

                                            if (!isExist) {
                                                throw new Error(`Region does not exist. Available regions: ${areas.join(',')}`)
                                            }
                                        },
                                    },
                                    outlet: {
                                        optional: true,
                                        label: 'Outlet',
                                        rule: (outlet, allOutlets) => {
                                            const outlets = this.props.vehicleGroups.outlets
                                                .map(vgid => this.props.vehicleGroups.byVGID[vgid])
                                                .map(vg => vg.groupName)

                                            const isExist = outlets.find(groupName => groupName === outlet)

                                            if (!isExist) {
                                                throw new Error(`Outlet does not exist. Available outlets: ${outlets.join(',')}`)
                                            }
                                        },
                                    },
                                    allocatedAt: {
                                        label: 'Allocated Date (DD/MM/YYYY hh:mm:ss)',
                                        rule: (allocatedAt, allAllocatedAts) => {

                                            allocatedAt = moment(allocatedAt).valueOf()

                                            if (isNaN(allocatedAt)) throw new Error(`Invalid Allocated Date`)
                                        }
                                    }
                                }
                            }
                            uploadLoop={async (excelData) => {
                                // formatting excel Data
                                const areaVehicleGroup = this.props.vehicleGroups.areas
                                    .map(vgid => this.props.vehicleGroups.byVGID[vgid])
                                    .find(vg => vg.groupName === excelData.region)

                                const outletVehicleGroup = this.props.vehicleGroups.outlets
                                    .map(vgid => this.props.vehicleGroups.byVGID[vgid])
                                    .find(vg => vg.groupName === excelData.outlet)

                                const vehicle = Object.values(this.props.vehicles.byVID)
                                    .find(vehicle => vehicle.engineNumber === excelData.engineNumber)

                                const allocatedAt = moment(excelData.allocatedAt).valueOf()

                                const vid = (vehicle && vehicle.vid) || '-'
                                const area = areaVehicleGroup && areaVehicleGroup.vgid
                                const outlet = outletVehicleGroup && outletVehicleGroup.vgid
                                const uid = this.props.user.uid

                                // console.log({ uid, vid, outlet, area, allocatedAt })

                                const data = await API.allocateVehicleToGroups(uid, vid, outlet, area, allocatedAt)
                                // const data = await fakeAPI(uid, vid, outlet, area, allocatedAt)

                                // console.log(data);

                                switch (data.status) {
                                    case 200:
                                        this.props.dispatch(allocate_vehicle_success(vid, [areaVehicleGroup, outletVehicleGroup]));
                                        return {
                                            uploadStatus: 'SUCCESS',
                                            message: ''
                                        }

                                    case 201:
                                        this.props.dispatch(allocate_vehicle_success(vid, [areaVehicleGroup, outletVehicleGroup]));
                                        return {
                                            uploadStatus: 'WARNING',
                                            message: data.message
                                        }

                                    default:
                                        return {
                                            uploadStatus: 'FAILED',
                                            message: data.message
                                        }
                                }
                            }}

                        />

                        <UploadExcelButton
                            icon={<ApiOutlined />}
                            title='Bulk pair vehicles'
                            loading={this.props.style.isLoadingSubmit || Object.keys(this.props.vehicleGroups.byVGID).length === 0}
                            buttonName={`Bulk Pair Vehicles`}
                            templateUrl={TEMPLATE_URLS.BULK_PAIR_VEHICLES_TEMPLATE}
                            excelTemplateName={`Bulk Pairing Vehicle Template`}
                            dataColumns={
                                {
                                    engineNumber: {
                                        label: 'Engine Number',
                                        rule: (engineNumber, allEngineNumbers) => {
                                            const isExist = Object.values(this.props.vehicles.byVID).find(vehicle => {
                                                return (engineNumber && engineNumber.toString()) === vehicle.engineNumber
                                            })
                                            if (!isExist) {
                                                throw new Error(`Engine Number does not exist`)
                                            }
                                        },
                                    },
                                    imei: {
                                        label: 'Imei',
                                        rule: (imei, allImeis) => {
                                            const isExist = Object.keys(this.props.devices.byDVID).find(dvid => dvid.includes(imei))
                                            if (!isExist) {
                                                throw  Error(`Device does not exist`)
                                            }

                                            const duplicates = allImeis.filter(_imei => imei === _imei)
                                            if (duplicates.length > 1) {
                                                throw  Error(`Duplicated imei`)
                                            }
                                        },
                                    },
                                }
                            }
                            uploadLoop={async (excelData) => {
                                // formatting excel Data
                                excelData.engineNumber = excelData.engineNumber.toString()
                                excelData.imei = excelData.imei.toString()


                                const vehicle = Object.values(this.props.vehicles.byVID)
                                    .find(vehicle => vehicle.engineNumber === excelData.engineNumber)
                                // const device = Object.keys(this.props.devices.byDVID).includes("GOS100#" + excelData.imei);

                                const newDVID = "GOS100#" + excelData.imei;

                                const vid = (vehicle && vehicle.vid) || '-'
                                const dvid = (Object.keys(this.props.devices.byDVID).includes(newDVID) && newDVID) || "-";
                                const uid = this.props.user.uid

                                // console.log({ excelData, vid, dvid, uid });

                                // const data = await fakeAPI(uid, vid, dvid)
                                const data = await API.assignDeviceToVehicle(uid, vid, dvid);

                                // console.log(data);

                                switch (data.status) {
                                    case 200:
                                        this.props.dispatch(assign_device_to_vehicle_success(data.vehicle));
                                        return {
                                            uploadStatus: 'SUCCESS',
                                            message: ''
                                        }

                                    case 201:
                                        this.props.dispatch(assign_device_to_vehicle_success(data.vehicle));
                                        return {
                                            uploadStatus: 'WARNING',
                                            message: data.message
                                        }

                                    default:
                                        return {
                                            uploadStatus: 'FAILED',
                                            message: data.message
                                        }
                                }

                                // function fakeAPI() {
                                //     return new Promise((resolve, reject) => {
                                //         setTimeout(() => {
                                //             resolve({
                                //                 status: 200,
                                //                 vehicle: {
                                //                     vid: Math.random().toString()
                                //                 }
                                //             })
                                //         }, 1 * 1000)
                                //     })
                                // }
                            }}

                        />
                        <UploadExcelButton
                            title='Bulk upload vehicle'
                            icon={<UploadOutlined />}
                            loading={this.props.style.isLoadingSubmit || Object.keys(this.props.vehicleGroups.byVGID).length === 0}
                            buttonName={`Bulk Upload Vehicles`}
                            templateUrl={TEMPLATE_URLS.BULK_UPLOAD_VEHICLES_TEMPLATE}
                            excelTemplateName={`Bulk Upload Vehicles Template`}
                            dataColumns={
                                {
                                    imei: {
                                        label: 'Imei',
                                        optional: true,
                                        rule: (imei, allImeis) => {
                                            const isExist = Object.keys(this.props.devices.byDVID).find(dvid => dvid.includes(imei))
                                            if (!isExist) {
                                                throw new Error(`Device does not exist`)
                                            }

                                            const duplicates = allImeis.filter(_imei => imei === _imei)
                                            if (duplicates.length > 1) {
                                                throw new Error(`Duplicated imei`)
                                            }
                                        },
                                    },
                                    engineNumber: {
                                        label: 'Engine Number',
                                        rule: (engineNumber, allEngineNumbers) => {
                                            // const duplicates = allEngineNumbers.filter(_engineNumber => engineNumber === _engineNumber)
                                            // if (duplicates.length > 1) {
                                            //     throw `Duplicated Engine Number`
                                            // }
                                        },
                                    },
                                    chassisNumber: {
                                        label: 'Chassis Number',
                                        rule: (chassisNumber, allChassisNumbers) => {
                                            // const duplicates = allChassisNumbers.filter(_chassisNumber => chassisNumber === _chassisNumber)
                                            // if (duplicates.length > 1) {
                                            //     throw `Duplicated Chassis Number`
                                            // }
                                        },
                                    },
                                    vehicleModel: {
                                        label: 'Vehicle Model',
                                        rule: (value) => {
                                            const isNoSpecialChars = string => {
                                                if (!string) return false
                                                const regex = /^\w+$/
                                                return regex.test(string)
                                            }

                                            if (!isNoSpecialChars(value)) {
                                                throw new Error(`Invalid Vehicle Model. (Cannot have any special characters)`)
                                            }
                                        },
                                    }
                                }
                            }
                            uploadLoop={async (excelData) => {
                                excelData.vehicleModel = excelData.vehicleModel.toString()
                                excelData.engineNumber = excelData.engineNumber.toString()
                                excelData.chassisNumber = excelData.chassisNumber.toString()

                                // console.log("Test:", excelData.imei, Object.keys(this.props.devices.byDVID).includes(excelData.imei));

                                const { uid } = this.props.user;
                                const newDVID = "GOS100#" + excelData.imei;
                                const newVehicle = {
                                    activeStatus: 1,
                                    dvid: (Object.keys(this.props.devices.byDVID).includes(newDVID) && newDVID) || '-',
                                    vehicleModel: excelData.vehicleModel,
                                    engineNumber: excelData.engineNumber,
                                    chassisNumber: excelData.chassisNumber,
                                };

                                // console.log({ excelData, newVehicle, uid });

                                // const data = await fakeAPI(uid, newVehicle)
                                const data = await API.addVehicle(uid, newVehicle);

                                // console.log(data);

                                switch (data.status) {
                                    case 200:
                                        this.props.dispatch(add_vehicle_success(data));
                                        return {
                                            uploadStatus: 'SUCCESS',
                                            message: data.message || ''
                                        }

                                    case 201:
                                        this.props.dispatch(add_vehicle_success(data));
                                        return {
                                            uploadStatus: 'WARNING',
                                            message: data.message
                                        }

                                    default:
                                        return {
                                            uploadStatus: 'FAILED',
                                            message: data.message
                                        }
                                }

                                // function fakeAPI(uid, newVehicle) {
                                //     return new Promise((resolve, reject) => {
                                //         setTimeout(() => {
                                //             resolve({
                                //                 status: 200,
                                //                 vehicle: {
                                //                     ...newVehicle,
                                //                     vid: Math.random().toString()
                                //                 }
                                //             })
                                //         }, 1 * 1000)
                                //     })
                                // }
                            }}
                        />
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-end",

                            padding: 5,
                            marginBottom: 10,
                        }}
                    >
                        <ExportExcelButton
                            filename={`Vehicles ${moment().format('DD-MM-YYYY')}`}
                            sheetData={dataSource}
                            sheetName={`Vehicles ${moment().format('DD-MM-YYYY')}`}
                            sheetRow={
                                columns
                                    .filter(col => col.title !== 'Actions')
                                    .map(col => {
                                        switch (col.dataIndex) {
                                            case 'duration':
                                            case 'lastStatusUpdated':
                                            case 'createdAt':
                                            case 'updatedAt':
                                                return {
                                                    label: col.title,
                                                    formatter: value => col.render(value[col.dataIndex])
                                                }
                                            default:
                                                return {
                                                    label: col.title,
                                                    formatter: value => value[col.dataIndex]
                                                }
                                        }
                                    })
                            }
                        />

                        <PrimaryButton onClick={() => this.props.dispatch(moveToPage("/VehicleManagement/AddVehicle"))}>
                            Add Vehicle
                        </PrimaryButton>
                    </div>


                    {
                        this.state.currPopUp === POP_UP_ALLOCATE &&
                        <PopUpAllocate onCloseModal={() => this.onCloseModal()} selectedVIDList={this.state.selectedVIDList} />
                    }

                    {
                        this.state.currPopUp === POP_UP_PAIR &&
                        <PopUpPair onCloseModal={() => this.onCloseModal()} selectedVID={this.state.selectedVID} />
                    }

                    {
                        this.state.currPopUp === POP_UP_UNPAIR &&
                        <PopUpUnpair onCloseModal={() => this.onCloseModal()} selectedVID={this.state.selectedVID} />
                    }
                    <Table
                        loading={this.props.style.isLoadingSubmit && Object.keys(this.props.vehicles.byVID).length === 0}
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{ pageSize: 20 }}
                        scroll={{
                            x: columns && columns.length * 150,
                            y: window.innerHeight
                        }}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user, // To create new geofences when uploading
    style: state.style,
    devices: state.devices,
    vehicles: state.vehicles,
    vehicleGroups: state.vehicleGroups,
    geofence: state.geofences,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(VehicleManagement));