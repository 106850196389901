import { config } from '../../config';

const api = config.api;
const headers = {
    'Accept': '*/*',
    'Content-Type': 'application/json; charset=utf-8'
}

export const getAssignmentLog = (vids, startTime, endTime) => {

    return fetch(`${api}/assignmentlog/get`, {
        method: 'POST',
        headers,
        body: JSON.stringify({
            "vids" : vids, // Filter returned data by DVID (Setting this to an empty array would return no device logs)
            "startTime": startTime, // Filter returned data by Start Time (Must also have endTime)
            "endTime": endTime, // Filter returned data by End Time (Must also have startTime)
        })
    })
    .then(res => res.json())
    .then(data => data)
}