import * as API from "../../api/geofences";
import * as ActionTypes from "../action-types/geofences";

import { message } from 'antd';
import { goBackToPrev } from "../../../navigation/navigationService";
import {
    setLoadingSubmit, 
    unsetLoadingSubmit,
} from "./style";

// Exported to user action
export const get_geofences_success = (geofences) => {
    return {
        type: ActionTypes.GET_GEOFENCES,
        geofences: geofences,
    };
}

export const add_geofence = ((uid, geofence, gtidList) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.addGeofence(uid, geofence, gtidList)
    .then(data => {
        // console.log("Add Geofence Data: ", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error(data.message);
            }
        }
        else {
            message.success("Successfully Added Geofence");

            dispatch(add_geofence_success(data.geofence));

            dispatch(goBackToPrev());
        }

        dispatch(unsetLoadingSubmit());
    })
})

const add_geofence_success = (newGeofence) => {
    return {
        type: ActionTypes.ADD_GEOFENCE,
        newGeofence: newGeofence,
    }
}

export const edit_geofence = ((editedGeofence) => (dispatch) => {
    dispatch(setLoadingSubmit());

    API.editGeofence(editedGeofence)
    .then(data => {
        // console.log("Edit Geofence Data: ", data);

        if (data.status !== 200) {
            if (data.message) {
                message.error(data.message);
            }
        }
        else {
            message.success("Successfully Edited Geofence");

            dispatch(edit_geofence_success(data.geofence));

            dispatch(goBackToPrev());
        }

        dispatch(unsetLoadingSubmit());
    })
})

const edit_geofence_success = (editedGeofence) => {
    return {
        type: ActionTypes.EDIT_GEOFENCE,
        editedGeofence: editedGeofence,
    }
}

export const set_selected_geoid = (selectedGEOID) => {
    return {
        type: ActionTypes.SET_SELECTED_GEOID,
        selectedGEOID: selectedGEOID,
    }
}

export const clear_selected_geoid = () => {
    return {
        type: ActionTypes.CLEAR_SELECTED_GEOID,
    }
}

export const set_selected_geofence_geoid = (geoid) => {
    return {
      type: ActionTypes.SET_SELECTED_GEOFENCE_GEOID,
      geoid: geoid
    }
  }

export const clear_selected_geofence_geoid = () => {
    return {
      type:  ActionTypes.CLEAR_SELECTED_GEOFENCE_GEOID,
    };
  }