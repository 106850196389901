import React from "react";
import { connect } from "react-redux";

import moment from "moment";
import Page from "../../components/Page";
import ExportExcelButton from '../../components/ExportExcelButton';
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { Chart } from "react-google-charts";
import { PROCESS_STATUS } from "../../constants";
import { PrimaryButton } from "../../components/PrimaryButton";
import { EmptyIndicator } from "../../components/EmptyIndicator";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import {
    Form,
    DatePicker,
    // AutoComplete,
    Select,
} from "antd";

// const defaultFilter = "ALL";

class StatusReport extends React.Component {
    state = {
        statusFilters: Object.values(PROCESS_STATUS),
        startTime: moment().startOf("month"),
        endTime: moment().add(1, 'day').startOf('day'),

        chartData: [],
        excelData: [],
    }

    formatVehicleData = (vehicles, filter) => {

        const statusDurationByVehicle =
            vehicles
                .filter(vehicle => vehicle.pipeLine)
                .map((vehicle) => {
                    vehicle = {
                        id: vehicle.engineNumber,
                        CREATED: this.timeframe(vehicle.pipeLine.CREATED_endTime - vehicle.pipeLine.CREATED_startTime),
                        INITIATED: this.timeframe(vehicle.pipeLine.INITIATED_endTime - vehicle.pipeLine.INITIATED_endTime),
                        ACC: this.timeframe(vehicle.pipeLine.ACC_endTime - vehicle.pipeLine.ACC_startTime),
                        YARD: this.timeframe(vehicle.pipeLine.YARD_endTime - vehicle.pipeLine.YARD_startTime),
                        // ALLOCATED: this.timeframe(vehicle.pipeLine.ALLOCATED_endTime - vehicle.pipeLine.ALLOCATED_startTime),
                        STAGED: this.timeframe(vehicle.pipeLine.STAGED_endTime - vehicle.pipeLine.STAGED_startTime),
                        LOADED: this.timeframe(vehicle.pipeLine.LOADED_endTime - vehicle.pipeLine.LOADED_startTime),
                        OUTLET: this.timeframe(vehicle.pipeLine.OUTLET_endTime - vehicle.pipeLine.OUTLET_startTime),
                        COMPLETED: this.timeframe(vehicle.pipeLine.COMPLETED_startTime - vehicle.pipeLine.CREATED_startTime),
                    };

                    return vehicle
                })
                .map(stat => {
                    return [stat.id, stat[filter]]
                })

        return statusDurationByVehicle;
    }

    timeframe = (ms) => {
        const MS_IN_HOUR = 60 * 60 * 1000;

        if (!ms) return 0
        // return Math.ceil(ms / MS_IN_HOUR)
        return (ms / MS_IN_HOUR).toFixed(2)
    }

    formatChartData = (stats, filter) => {
        let datas = [];

        const columns = ['Vehicle', `${filter} Duration (Hours)`];

        datas = [
            columns,
            ...stats
        ]

        // console.log("Chart Data:", datas);

        return datas;
    }

    formatExcelData = (chartData) => {

        let excelData = [...chartData]

        let columns = excelData.shift()

        excelData = excelData.map(data => {
            let object = {}
            data.map((d, i) => {
                object[i] = d
                return null;
            })
            return object
        })

        const excelColumns = columns.map((c, i) => {
            return {
                label: c,
                formatter: (v) => v[i]
            }
        })

        return {
            excelColumns,
            excelData
        }
    }

    onSubmit = () => {
        const {
            endTime,
            startTime,
            statusFilters,
        } = this.state;

        console.log({
            endTime,
            startTime,
            statusFilters,
        })

        const filteredVehicles = Object.values(this.props.vehicles.byVID)
            .filter(currVehicle => {
                try {
                    if (startTime && startTime.valueOf() > currVehicle.createdAt) throw new Error(`startTime`)
                    if (endTime && endTime.valueOf() < currVehicle.createdAt) throw new Error(`endTime`)
                    return true
                } catch (err) {
                    return false
                }
            })

        const chartDatas = statusFilters.map(statusFilter => {

            const stats = this.formatVehicleData(filteredVehicles, statusFilter);

            // console.log({ stats });

            const chartData = this.formatChartData(stats, statusFilter);

            // console.log("Chart Data", { chartData });

            return chartData
        })

        const { excelColumns, excelData } = this.formatExcelData(chartDatas.reduce((a, b) => [...a, ...b], []));

        this.setState({
            chartDatas,
            excelData,
            excelColumns,
        })
    }

    render() {
        const {
            endTime,
            startTime,
            statusFilters,

            chartDatas = [],
            excelData = [],
            excelColumns = [],
        } = this.state;

        return (
            <div className="page-container">
                <Page title="Vehicle Logistical Process Status Graph">
                    <Form layout='inline'>

                        <Form.Item
                            label={`Vehicle Created Start Time`}
                        >
                            <DatePicker
                                showTime
                                defaultValue={this.state.startTime}
                                onChange={value => this.setState({ startTime: value })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={`Vehicle Created End time`}
                        >
                            <DatePicker
                                showTime
                                defaultValue={this.state.endTime}
                                onChange={value => this.setState({ endTime: value })}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            label={`Status`}
                        >

                            <Select
                                mode="multiple"
                                style={{ width: 400 }}
                                placeholder="Select status"
                                defaultValue={this.state.statusFilters}
                                onChange={statusFilters => {
                                    this.setState({ statusFilters })
                                }}
                            >
                                {
                                    Object.values(PROCESS_STATUS).map(status => {
                                        return <Select.Option key={status}>{status}</Select.Option>
                                    })
                                }
                            </Select>

                        </Form.Item>

                        <Form.Item>
                            <PrimaryButton
                                loading={this.props.style.isLoadingSubmit || Object.keys(this.props.vehicles.byVID).length === 0}
                                onClick={this.onSubmit}
                                style={{ marginLeft: '15px' }}
                            >
                                Submit
                            </PrimaryButton>

                            <ExportExcelButton
                                filename={`Vehicle Logistical Process Status ${statusFilters} duration ${moment(startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(endTime).format('YYYY-MM-DD HH:mm:ss')}`}
                                sheetData={excelData}
                                sheetName={`Vehicle Logistical Process Status`}
                                sheetRow={excelColumns}
                            />
                        </Form.Item>
                    </Form>

                    <div
                        style={{
                            flex: 1,
                            display: "flex",
                        }}
                    >
                        <div
                            // className = "scroll-div"
                            style={{ width: "100%" }}
                        >
                            <div>
                                {
                                    chartDatas.length === 0 ? <EmptyIndicator />
                                        :

                                        chartDatas.map((chartData, i) => {
                                            return <Chart
                                                key={i}
                                                chartType="Histogram"
                                                data={chartData}
                                                width={window.innerWidth * 0.9}
                                                height={window.innerHeight * 0.3}
                                                loader={<LoadingIndicator />}
                                                options={{
                                                    backgroundColor: {
                                                        fill: 'transparent'
                                                    },
                                                    hAxis: {
                                                        title: `Lead Time (hours)`,
                                                        format: 0,
                                                    },
                                                    vAxis: {
                                                        title: 'Vehicles',
                                                        format: 0,
                                                    },
                                                    histogram: {
                                                        bucketSize: 1
                                                    }
                                                }}
                                            />

                                        })

                                }
                            </div>
                        </div>
                    </div>
                </Page>
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    style: state.style,
    user: state.user,
    vehicles: state.vehicles,
    vehicleGroups: state.vehicleGroups,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(StatusReport));