import React from "react";
import { useDispatch } from "react-redux";

import GeofenceList from "./GeofenceList";

import { moveToPage } from "../../navigation/navigationService";

import "../../App.css";

const GeofenceManagementPage = (props) => {

  const dispatch = useDispatch();

  const navigateToAddNewPOI = () => dispatch(moveToPage(`${props.match.url}/AddPOI`));
  const navigateToAddNewPolygon = () => dispatch(moveToPage(`${props.match.url}/AddPolygon`));
  const navigateToEditPOI = (geofence) => dispatch(moveToPage(`${props.match.url}/EditPOI`, geofence));
  const navigateToEditPolygon = (geofence) => dispatch(moveToPage(`${props.match.url}/EditPolygon`, geofence));

  return (
    <div className = "page-container">
      <div
        className = "scroll-div"
        style = {{ display: "flex", flex: 1 }}
      >
        <GeofenceList
          createPOI = {() => navigateToAddNewPOI(true)}
          editPOI = {(geofence) => navigateToEditPOI(geofence)}
          createPolygon = {() => navigateToAddNewPolygon(true)}
          editPolygon = {(geofence) => navigateToEditPolygon(geofence)}
        />
      </div>
    </div>
  );
}

export default GeofenceManagementPage;