import { combineReducers } from "redux";
import { connectRouter } from 'connected-react-router'

// Redux Stores
import { user } from './user';
import { style } from './style';
import { devices } from './devices';
import { deviceGroups } from './deviceGroups';
import { vehicles } from './vehicles';
import { vehicleGroups } from './vehicleGroups';
import { geofences } from './geofences';
import { geofenceTemplates } from './geofenceTemplates';
import { events } from './events';
import { deviceLogs } from './deviceLogs';
import { geofenceReports } from './geofenceReports';
import { statusLogs } from './statusLogs';
import { mapControl } from './mapControl';
import { mapDrawControl } from './mapDrawControl';
import { statusBarControl } from './statusBarControl';
import { tripRecordControl } from './tripRecordControl';
import { assignmentLogs } from './assignmentLogs';
import { transitRecords } from './transitRecords'
import { tripRecords } from './tripRecords'
import { vehicleLogs } from './vehicleLogs'

export const createRootReducer = (history) => combineReducers({
  user,
  style,
  router: connectRouter(history),
  // ... // rest of your reducers

  // Redux Stores
  devices,
  deviceGroups,
  vehicles,
  vehicleGroups,
  geofences,
  geofenceTemplates,
  events,
  deviceLogs,
  geofenceReports,
  statusLogs,
  mapControl,
  mapDrawControl,
  statusBarControl,
  tripRecordControl,
  assignmentLogs,
  transitRecords,
  tripRecords,
  vehicleLogs
})