import React from "react";
import { connect } from "react-redux";

import moment from "moment";

import { PROGRESS_TYPES } from "../../../constants";
import {
    Form,
    Modal,
    Table,
    Select,
    Button,
    message,
    DatePicker,
} from "antd";

// Redux Actions
import {
    allocate_vehicle,
    set_up_allocate_progress,
} from "../../../services/redux/actions/vehicles";

const modalHeight = 400;
class PopUpAllocate extends React.Component {
    state = {
        currArea: "NONE",
        currOutlet: "NONE",
        currVehicle: {},
        allocatedDate: moment.now(),

        isLoading: false,
    }

    componentDidMount = () => {
        const thisVid = this.props.selectedVIDList.pop()
        const vehicle = this.props.vehicles.byVID[thisVid]

        this.setState({
            currArea: vehicle.area,
            currOutlet: vehicle.outlet,
            currVehicle: vehicle,
        })
    }

    enableLoadingModal = () => {
        const {
            currArea,
            currOutlet,
            currVehicle,
            allocatedDate,
        } = this.state

        try {
            const noChange = currVehicle && currVehicle.area === currArea && currVehicle.outlet === currOutlet
            if (noChange) throw new Error(`No change in outlet or area`)

            this.setState({ isLoading: true })

            // Set up allocate progress first
            const vid = currVehicle && currVehicle.vid
            const vgidList = [currOutlet, currArea]
            this.props.dispatch(set_up_allocate_progress(vid, vgidList))

            // Then allocate vehicles
            const uid = this.props.user.uid;

            // console.log(uid, vid, currOutlet, currArea, allocatedDate);

            this.props.dispatch(allocate_vehicle(uid, vid, currOutlet, currArea, allocatedDate))

        } catch (err) {
            message.info(err.message)
        }

    }

    render() {
        const {
            vehicles,
            vehicleGroups,
        } = this.props;

        const {
            currArea,
            currOutlet,
            currVehicle,
        } = this.state

        return (
            <div>
                {
                    !this.state.isLoading ?
                        <Modal
                            title="Allocate Vehicle"
                            visible={true}
                            closable={false}
                            okButtonProps={{
                                loading: this.props.style.isLoadingSubmit,
                            }}
                            cancelButtonProps={{ loading: this.props.style.isLoadingSubmit }}
                            onOk={() => {
                                if (this.state.currArea && this.state.currOutlet) {
                                    this.enableLoadingModal()
                                }
                                else {
                                    message.info("You need to select both an area and an outlet.");
                                }
                            }}
                            onCancel={() => this.props.onCloseModal()}
                        >
                            <Form>
                                <Form.Item
                                    label="Engine Number"
                                    labelCol={{ span: 7 }}
                                    wrapperCol={{ span: 15 }}
                                >
                                    {/* <Input
                                        disabled = {true}
                                        placeholder = "Vehicle not selected"
                                        value = {this.props.vehicles.byVID[this.props.selectedVIDList].engineNumber}
                                    /> */}

                                    <Select
                                        disabled={true}
                                        placeholder="Vehicle(s) not selected"
                                        value={currVehicle && currVehicle.engineNumber}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label='Region'
                                    labelCol={{ span: 7 }}
                                    wrapperCol={{ span: 15 }}
                                >
                                    <Select
                                        value={currArea}
                                        disabled={vehicleGroups.areas.length < 1}
                                        placeholder={vehicleGroups.areas.length > 0 ? "Select an area" : "No areas found"}
                                        onChange={value =>
                                            this.setState({
                                                ...this.state,
                                                currArea: value,
                                            })
                                        }
                                    >
                                        {
                                            vehicleGroups.areas.map((currVGID) => {
                                                const area = vehicleGroups.byVGID[currVGID]
                                                return (
                                                    <Select.Option key={currVGID} value={currVGID}>
                                                        {area && area.groupName}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Outlet"
                                    labelCol={{ span: 7 }}
                                    wrapperCol={{ span: 15 }}
                                >
                                    <Select
                                        value={currOutlet}
                                        disabled={vehicleGroups.outlets.length < 1}
                                        placeholder={vehicleGroups.outlets.length > 0 ? "Select an outlet" : "No outlets found"}
                                        onChange={value =>
                                            this.setState({
                                                ...this.state,
                                                currOutlet: value,
                                            })
                                        }
                                    >
                                        {
                                            vehicleGroups.outlets.map((currVGID) => {
                                                const outlet = vehicleGroups.byVGID[currVGID]
                                                return (
                                                    <Select.Option key={currVGID} value={currVGID}>
                                                        {outlet && outlet.groupName}
                                                    </Select.Option>
                                                )
                                            })
                                        }
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    label="Allocated Date"
                                    labelCol={{ span: 7 }}
                                    wrapperCol={{ span: 15 }}
                                >
                                    <DatePicker
                                        showTime
                                        value={moment(this.state.allocatedDate)}
                                        onChange={value =>
                                            this.setState({
                                                ...this.state,
                                                allocatedDate: value,
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Form>
                        </Modal> :
                        <Modal
                            title="Allocate Progress"
                            visible={true}
                            closable={false}
                            width={800}
                            bodyStyle={{ height: modalHeight }}
                            footer={[
                                <Button key="submit" loading={this.props.style.isLoadingSubmit} onClick={() => this.props.onCloseModal()}>
                                    Finish
                                </Button>,
                            ]}
                        >
                            <div
                                style={{
                                    paddingLeft: 5,
                                    marginBottom: 5,
                                    
                                    overflowY: modalHeight - 48, // Default padding for modal is 24
                                }}
                            >
                                {`${Object.keys(vehicles.allocateProgress).filter((currVID) => vehicles.allocateProgress[currVID].status === PROGRESS_TYPES.SUCCESS).length} out of ${Object.keys(vehicles.allocateProgress).length} allocations are successful`}
                            </div>

                            <Table
                                columns={[
                                    {
                                        title: "Engine Number",
                                        dataIndex: "engineNumber",
                                    },

                                    {
                                        title: 'Region',
                                        dataIndex: "area",
                                    },

                                    {
                                        title: "Outlet",
                                        dataIndex: "outlet",
                                    },

                                    {
                                        title: "Allocated Time",
                                        dataIndex: "allocatedTime"
                                    },

                                    {
                                        title: "Status",
                                        dataIndex: "status",
                                        render: (text) =>
                                            <span
                                                style={{
                                                    color: text === PROGRESS_TYPES.PENDING ?
                                                        "blue" :
                                                        text === PROGRESS_TYPES.SUCCESS ?
                                                            "green" :
                                                            text === PROGRESS_TYPES.FAILED &&
                                                            "red"
                                                }}
                                            >
                                                {text}
                                            </span>,
                                    },

                                    {
                                        title: "Remarks",
                                        dataIndex: "remarks",
                                        width: 236
                                    },
                                ]}
                                dataSource={Object.keys(vehicles.allocateProgress).map((currVID) => vehicles.allocateProgress[currVID])}
                            />
                        </Modal>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    vehicles: state.vehicles,
    vehicleGroups: state.vehicleGroups,
});

export default connect(mapStateToProps)(PopUpAllocate);