import * as ActionTypes from "../action-types";

export const vehicleLogs = (
    state = {
        byVID: {},
        markerPlaybackIndex: 0,
        selectedTime: { 
            startTime: null, 
            endTime: null,
        },
        selectedPackageTime: ""
    },
    action
) => {
    let newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.SET_VEHICLE_LOG_SELECTED_TIME: {
            return {
                ...newState,
                selectedTime: {
                    startTime: action.startTime,
                    endTime: action.endTime
                }
            };
        }

        case ActionTypes.CLEAR_VEHICLE_LOG_SELECTED_TIME: {
            return {
                ...newState,
                selectedTime: {
                    startTime: null,
                    endTime: null
                }
            };
        }

        case ActionTypes.SET_VEHICLE_LOG_SELECTED_MARKER: {
            console.log('action.markerPlaybackIndex', action.markerPlaybackIndex)
            return {
                ...newState,
                markerPlaybackIndex: action.markerPlaybackIndex
            };
        }

        case ActionTypes.GET_VEHICLE_LOG: {
            // console.log("Get Vehicle Log Data:", action);

            newState = JSON.parse(JSON.stringify(state));

            if (action.vehicleLogs.length > 0) {
                action.vehicleLogs.forEach((currLog, index) => {
                    if(index === 0) {
                        newState.byVID[currLog.vid] = []
                    }
                        
                    if (!Object.keys(newState.byVID).includes(currLog.vid)) {
                        newState.byVID[currLog.vid] = [];
                    }

                    newState.byVID[currLog.vid].push(currLog);
                })
            }

            return newState;
        }

        case ActionTypes.SET_VEHICLE_LOG_SELECTED_PACKAGE_TIME: {
            return {
                ...newState,
                selectedPackageTime: action.selectedTime
            };
        }

        case ActionTypes.CLEAR_VEHICLE_LOG_SELECTED_PACKAGE_TIME: {
            return {
                ...newState,
                selectedPackageTime: ""
            };
        }

        default: {
            return state;
        }
    }
};
