import * as ActionTypes from "../action-types/deviceLogs";

const defaultState = {
    byDVID: {},
}

export const deviceLogs = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_DEVICE_LOG: {
            newState.byDVID = {}
            action.newDeviceLogs.forEach((currDeviceLog) => {
                if (!newState.byDVID[currDeviceLog.dvid]) {
                    newState.byDVID[currDeviceLog.dvid] = [];
                }

                newState.byDVID[currDeviceLog.dvid].push(currDeviceLog);
            })

            return newState;
        }

        case ActionTypes.GET_DEVICE_LOG_DEBUG: {
            newState.byDVID = JSON.parse(JSON.stringify(defaultState.byDVID)); // Clear newState byDVID

            action.newDeviceLogs.forEach((currDeviceLog) => {
                if (!newState.byDVID[currDeviceLog.dvid]) {
                    newState.byDVID[currDeviceLog.dvid] = [];
                }

                newState.byDVID[currDeviceLog.dvid].push(currDeviceLog);
            })

            return newState;
        }

        case ActionTypes.CLEAR_DEVICE_LOG: {
            // newState.byDVID = defaultState.byDVID;
            newState.byDVID = {}
            return newState;
        }

        case ActionTypes.CLEAR_DEVICE_LOG_DEBUG: {
            newState.byDVID = defaultState.byDVID;

            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Device Log Store:", defaultState);

            return defaultState;
        }

        default: {
            return state;
        }
    }
};
