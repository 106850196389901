export const GET_VEHICLES = "GET_VEHICLES";

export const ADD_VEHICLE = "ADD_VEHICLE";
export const EDIT_VEHICLE = "EDIT_VEHICLE";

export const ALLOCATE_VEHICLE = "ALLOCATE_VEHICLE";
export const ASSIGN_DEVICE_TO_VEHICLE = "ASSIGN_DEVICE_TO_VEHICLE";
export const UNASSIGN_DEVICES = "UNASSIGN_DEVICES";

export const SET_SELECTED_VID = "SET_SELECTED_VID";
export const CLEAR_SELECTED_VID = "CLEAR_SELECTED_VID";

// Allocate Progress Stuff
export const SET_UP_ALLOCATE_PROGRESS = "SET_UP_ALLOCATE_PROGRESS";
export const UPDATE_ALLOCATE_PROGRESS = "UPDATE_ALLOCATE_PROGRESS";
export const CLEAR_ALLOCATE_PROGRESS = "CLEAR_ALLOCATE_PROGRESS";

export const CLEAR_SELECTED_VEHICLE = "CLEAR_SELECTED_VEHICLE";