import React from 'react';
import { connect } from 'react-redux';

import moment from 'moment';
import Page from '../../components/Page';
import ParseTime from "../../components/ParseTime";
// import ParseDuration from "../../components/ParseDuration";
import ExportExcelButton from '../../components/ExportExcelButton';
import showSecondarySidebar from '../../components/Layout/helpers/showSecondarySidebar';

// import { VEHICLE_GROUP_TYPES } from "../../constants";
import { PrimaryButton } from '../../components/PrimaryButton';
// import { tableColumnSearchProps } from "../../components/TableColumnSearchProps";

import {
    Form,
    Table,
    DatePicker,
    // AutoComplete,
    Input,
    Button,
} from 'antd';
import {
    SearchOutlined
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import parseDuration from '../../components/ParseDuration';
import { get_geofence_record } from '../../services/redux/actions/geofenceReports';
import parseTime from '../../components/ParseTime';


class GeofenceRecordPage extends React.Component {
    state = {
        startTime: moment().startOf("month"),
        endTime: moment().add(1, 'day').startOf('day'),
    }


    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
        </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
        </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={(text && text.toString()) || ``}
                />
            ) : (
                    text
                ),
    });


    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };


    setUpDataSource = () => {
        const {
            vehicles,
            geofences,
            // vehicleGroups,
            geofenceReports,
            devices,
        } = this.props;

        const dataSource = Object.keys(geofenceReports.byGEOID)
            .sort((a, b) => b.createdAt - a.createdAt)
            .map((currGeoID) => {
                const currRecord = geofenceReports.byGEOID[currGeoID];
                const currVehicle = vehicles.byVID[currRecord.vid];
                const currDevice = (currVehicle && devices && devices.byDVID[currVehicle.dvid]) || null
                const currGeofence = geofences.byGEOID[currRecord.geoid]

                return {
                    key: currGeoID,
                    geofenceName: (currGeofence && currGeofence.geofenceName )|| "DELETED GEOFENCE",
                    engineNumber: (currVehicle && currVehicle.engineNumber) || "-",
                    pairedDevice: (currDevice && currDevice.dvid && currDevice.dvid.split('#')[1]) || '-',
                    inAt: currRecord.inAt,
                    outAt: currRecord.outAt,
                    duration: currRecord.duration,
                }
            })

        const columns = [
            {
                title: 'Geofence Name',
                dataIndex: 'geofenceName',
                ...this.getColumnSearchProps('geofenceName')
            },

            {
                title: 'Vehicle',
                dataIndex: 'engineNumber',
                ...this.getColumnSearchProps('engineNumber')
            },

            {
                title: "Device IMEI",
                dataIndex: "pairedDevice",
                ...this.getColumnSearchProps('pairedDevice'),
            },
            {
                title: 'In',
                dataIndex: 'inAt',
                key: 'inAt',
                sorter: (a, b) => b.inAt - a.inAt,
                render: time => ParseTime(time)
            },
            {
                title: 'Out',
                dataIndex: 'outAt',
                key: 'outAt',
                sorter: (a, b) => b.outAt - a.outAt,
                render: time => ParseTime(time)
            },
            {
                title: 'Duration',
                dataIndex: 'duration',
                key: 'duration',
                sorter: (a, b) => b.duration - a.duration,
                render: time => parseDuration(time)
            }
        ]

        this.setState({
            dataSource,
            columns,
        });
    }

    componentDidMount = () => {
        this.setUpDataSource();
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setUpDataSource();
        }
    }

    render() {
        const {
            endTime,
            startTime,
            dataSource = [],
            columns = [],
        } = this.state;

        return (
            <div className='page-container'>
                <Page title='Geofence Report'>
                    <div
                        style={{
                            display: "block",
                            minHeight: "95px",
                            padding: "10px 10px 10px 10px",
                        }}
                    >
                        <Form layout='inline'>
                            <Form.Item
                                label={`Start time`}
                            >
                                <DatePicker
                                    showTime
                                    value={startTime ? startTime : undefined}
                                    onChange={value => this.setState({ startTime: value })}
                                    style={{ width: '177px' }}
                                />
                            </Form.Item>
                            <Form.Item
                                label={`End time`}
                            >
                                <DatePicker
                                    showTime
                                    value={endTime ? endTime : undefined}
                                    onChange={value => this.setState({ endTime: value })}
                                    style={{ width: '177px' }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <PrimaryButton
                                    loading={this.props.styles.isLoadingSubmit}
                                    onClick={() => {
                                        const startTimeEpoch = startTime && startTime.valueOf()
                                        const endTimeEpoch = endTime && endTime.valueOf()

                                        this.props.dispatch(get_geofence_record(
                                            null,
                                            null,
                                            null,
                                            startTimeEpoch,
                                            endTimeEpoch
                                        ))
                                    }}
                                    style={{ marginLeft: '15px' }}
                                >
                                    Search
                                </PrimaryButton>

                                <ExportExcelButton
                                    disabled={dataSource.length < 1 || !startTime || !endTime}
                                    filename={`Geofence Reports ${parseTime(moment.now())}`}
                                    sheetData={dataSource}
                                    sheetName='Geofence Reports'
                                    sheetRow={
                                        columns.map(col => {
                                            switch (col.dataIndex) {
                                                case 'inAt':
                                                case 'outAt':
                                                case 'duration':
                                                    return {
                                                        label: col.title,
                                                        formatter: value => col.render(value[col.dataIndex])
                                                    }
                                                default:
                                                    return {
                                                        label: col.title,
                                                        formatter: value => value[col.dataIndex]
                                                    }
                                            }
                                        })
                                    }
                                />
                            </Form.Item>
                        </Form>
                    </div>

                    <Table
                        columns={columns}
                        dataSource={this.state.dataSource}
                        scroll={{
                            x: columns && columns.length * 150,
                            y: true
                        }}
                    />
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    styles: state.style,
    vehicles: state.vehicles,
    geofences: state.geofences,
    vehicleGroups: state.vehicleGroups,
    geofenceReports: state.geofenceReports,
});

const ConnectedGeofenceRecordPage = connect(mapStateToProps)(GeofenceRecordPage);

export default showSecondarySidebar(false)(ConnectedGeofenceRecordPage);