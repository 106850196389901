import React from "react";
import { connect } from "react-redux";

import moment from 'moment';
import Page from "../../../components/Page";
// import ReactExport from "react-data-export";
import ExportExcelButton from "../../../components/ExportExcelButton"
import showSecondarySidebar from "../../../components/Layout/helpers/showSecondarySidebar";

import { EmptyIndicator } from "../../../components/EmptyIndicator";
// import { goBackToPrev } from '../../../navigation/navigationService';
import {
    Form,
    Table,
    Button,
    DatePicker,
    AutoComplete,
} from 'antd';

// React Actions
import {
    // set_selected_dvid,
    clear_selected_dvid,
} from "../../../services/redux/actions/devices";
import {
    get_device_log,
    clear_device_log,
} from "../../../services/redux/actions/deviceLogs";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DeviceLogInspector extends React.Component {
    state = {
        selectedDVIDFilterString: "",
        selectedDVID: null,

        startTime: moment().startOf("month"),
        endTime: moment().add(1, "day").startOf("day"),

        dataSource: [],
    }

    onSubmit = () => {
        let {
            endTime,
            startTime,
            selectedDVID,
        } = this.state;

        let dvids = [selectedDVID];
        startTime = startTime.valueOf();
        endTime = endTime.valueOf();

        // console.log({
        //     dvids,
        //     startTime,
        //     endTime
        // })

        // this.props.dispatch(clear_device_log());

        this.props.dispatch(get_device_log(dvids, startTime, endTime));
    }

    setUpDatasource = () => {

        const {
            // devices,
            deviceLogs,
        } = this.props

        const newDataSource =
            deviceLogs.byDVID[this.state.selectedDVID] ?
                deviceLogs.byDVID[this.state.selectedDVID]
                .sort((a, b) => {
                    if (a.createdAt < b.createdAt) return -1;
                    if (a.createdAt > b.createdAt) return 1;
                    return 0;
                })
                .map((log, index) => (
                    {
                        key: index,
                        createdAt: moment(log.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                        deviceTime: moment(log.deviceTime).format("YYYY-MM-DD HH:mm:ss"),
                        package: log.devicePackage,
                        deviceMessage: log.alarmMessage ? log.alarmMessage : "-",
                        gpsStatus: log.gpsStatus ? log.gpsStatus : 0,
                        engineStatus: log.engineStatus ? log.engineStatus : 0,
                        location: log.location
                    }
                )) :
                []

        // console.log("New Data Source:", 
        //     newDataSource.map((currLog) => ({
        //         ...currLog,
        //         location: this.props.deviceLogs.byDVID[this.props.devices.selectedDVID][currLog.key].location,
        //     }))
        // );

        // console.log('newDataSource.length', newDataSource.length)

        this.setState({
            dataSource: newDataSource,
        })
    }

    componentDidMount = () => {
        const {
            devices
        } = this.props

        // console.log('selectedDVID', devices.selectedDVID);

        if (devices.selectedDVID) {
            this.setState({ selectedDVID: devices.selectedDVID })
        }

    }

    componentDidUpdate = (prevProps) => {
        const { deviceLogs } = this.props;

        if (prevProps.deviceLogs !== deviceLogs) {
            this.setUpDatasource();
        }

    }

    componentWillUnmount = () => {
        // Clearing device log because it's unlikely that user will be asking for the same device log within the same period
        this.props.dispatch(clear_device_log());
        this.props.dispatch(clear_selected_dvid());
    }

    render() {
        const {
            // endTime,
            // startTime,
            // selectedDVID,
            selectedDVIDFilterString,
        } = this.state;

        const {
            devices,
            // deviceLogs
        } = this.props;

        const columns = [
            {
                title: "Device Time",
                dataIndex: "deviceTime",
                key: 'deviceTime',
                sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
                // sortOrder: sortedInfo.columnKey === 'deviceTime' && sortedInfo.order,
            },

            {
                title: 'Server Time',
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
                // sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
            },

            {
                title: "Data Package",
                dataIndex: "package",
                filters: [
                    {
                        text: "auth",
                        value: "auth"
                    },
                    {
                        text: "heartbeat",
                        value: "heartbeat"
                    },
                    {
                        text: "gps#latest",
                        value: "gps#latest"
                    },
                    {
                        text: "gps#memory",
                        value: "gps#memory"
                    },
                    {
                        text: "event#session#error",
                        value: "event#session#error"
                    },
                    {
                        text: "event#session#close",
                        value: "event#session#close"
                    },
                    {
                        text: "event#session#timeout",
                        value: "event#session#timeout"
                    }
                ],
            },

            {
                title: "Message",
                dataIndex: "deviceMessage",
            },

            {
                title: "GPS Status",
                dataIndex: "gpsStatus",
                filters: [
                    {
                        text: "0",
                        value: 0
                    },
                    {
                        text: "1",
                        value: 1
                    },
                ],
            },

            {
                title: "ACC",
                dataIndex: "engineStatus",
                filters: [
                    {
                        text: "0",
                        value: 0
                    },
                    {
                        text: "1",
                        value: 1
                    },
                ],
            },

            {
                title: "Location",
                dataIndex: "location",
                render: location =>
                    <div>
                        {
                            location ?
                                <a
                                    href={`https://www.google.com.my/maps/place/${location.lat},${location.lng}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {location.lat},{location.lng}
                                </a> :
                                `N/A`
                        }
                    </div>
            },
        ]

        return (
            <div className="page-container">
                <Page title="Device Inspector Table">
                    <Form layout='inline' style={{ marginBottom: 15 }}>
                        <Form.Item>
                            <span style={{ marginLeft: '10px' }}>Device Imei: </span>

                            <AutoComplete
                                placeholder={'Device'}
                                value={this.state.selectedDVID}
                                // disabled={!this.state.selectedDVID}
                                onSearch={selectedDVIDFilterString => {
                                    this.setState({ selectedDVIDFilterString })
                                }}
                                onSelect={selectedDVID => {
                                    this.setState({ selectedDVID });
                                }}
                                style={{ width: 200 }}
                            >
                                {
                                    Object.values(devices.byDVID)
                                        .filter(device => {
                                            const { 
                                                dvid 
                                            } = device
                                            // return selectedDVIDFilterString ? imei.toLowerCase().includes(selectedDVIDFilterString.toLowerCase()) : true
                                            return selectedDVIDFilterString ? dvid.toLowerCase().includes(selectedDVIDFilterString.toLowerCase()) : true
                                        })
                                        .sort((a, b) => {
                                            const dA = a.imei
                                            const dB = b.imei

                                            if (dA < dB) return -1;
                                            if (dA > dB) return 1;
                                            return 0;
                                        })
                                        .map((device) => {
                                            const { 
                                                dvid 
                                            } = device
                                            return <AutoComplete.Option key={dvid}>{dvid}</AutoComplete.Option>
                                            // return <AutoComplete.Option key={dvid}>{imei}</AutoComplete.Option>
                                        })
                                }
                            </AutoComplete>
                        </Form.Item>

                        <Form.Item>
                            <span>Start time: </span>

                            <DatePicker
                                showTime
                                defaultValue={this.state.startTime}
                                onChange={value => this.setState({ startTime: value })}
                            />

                            <span style={{ marginLeft: '10px' }}>End time: </span>

                            <DatePicker
                                showTime
                                defaultValue={this.state.endTime}
                                onChange={value => this.setState({ endTime: value })}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                disabled={!this.state.selectedDVID}
                                onClick={this.onSubmit}
                                loading={this.props.style.isLoadingSubmit}
                                style={{
                                    marginLeft: '15px',
                                    marginRight: '10px'
                                }}
                            >
                                Submit
                            </Button>

                            <ExportExcelButton
                                disabled={this.state.dataSource.length === 0}
                                filename={`Device Inspector ${this.state.selectedDVID} ${moment().format('DD-MM-YYYY')}`}
                                sheetData={this.state.dataSource}
                                sheetName={`${moment().format('DD-MM-YYYY')}`}
                                sheetRow={
                                    columns
                                        .map(col => {

                                            switch (col.title) {
                                                case 'Location':
                                                    return {
                                                        label: col.title,
                                                        formatter: value => {
                                                            const location = value[col.dataIndex];
                                                            
                                                            return location ? `${location.lat}, ${location.lng}` : '';
                                                        }                                                        
                                                    }
                                                default:
                                                    return {
                                                        label: col.title,
                                                        formatter: value => value[col.dataIndex]
                                                    }
                                            }
                                        })
                                }
                            />

                        </Form.Item>
                    </Form>

                    <div >
                        {
                            this.state.dataSource.length ?
                                <Table
                                    columns={columns}
                                    dataSource={this.state.dataSource}
                                    loading={this.props.style.isLoadingSubmit}
                                    pagination={{ pageSize: 25 }}
                                    // onChange = {this.onChangeTable}
                                    scroll={{
                                        // x: "max-content",
                                        y: window.innerHeight - 20 - 49 - 25 - 40 - 150
                                    }}
                                /> :
                                <EmptyIndicator />
                        }
                    </div>
                </Page>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    style: state.style,
    devices: state.devices,
    deviceLogs: state.deviceLogs,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(DeviceLogInspector));