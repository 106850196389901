import * as ActionTypes from "../action-types/statusLogs";

const defaultState = {
    byVID: {},
    byDVID: {},
    byLRID: {},
}

export const statusLogs = (state = defaultState, action) => {
    const newState = JSON.parse(JSON.stringify(state));

    switch (action.type) {
        case ActionTypes.GET_STATUS_LOG: {
            newState.byVID = {}; // Clear newState byVID
            newState.byDVID = {}; // Clear newState byDVID
            newState.byLRID = {}; // Clear newState byLRID
            
            action.newLogs.forEach((currLP) => {
                newState.byLRID[currLP.lrid] = currLP;

                newState.byVID[currLP.vid] = currLP.lrid;
                newState.byDVID[currLP.dvid] = currLP.lrid;
            });

            return newState;
        }

        case ActionTypes.CLEAR_STATUS_LOG: {
            newState.byVID = {}; // Clear newState byVID
            newState.byDVID = {}; // Clear newState byDVID
            newState.byLRID = {}; // Clear newState byLRID
            
            return newState;
        }

        case "USER_SIGN_OUT": {
            // console.log("Clearing Status Log Store:", defaultState);

            return defaultState;
        }
        
        default: {
            return newState;
        }
    }
}