import React from "react";
import { connect } from "react-redux";

import moment from "moment";
import Page from "../../components/Page";
import ExportExcelButton from '../../components/ExportExcelButton';
import showSecondarySidebar from "../../components/Layout/helpers/showSecondarySidebar";

import { PrimaryButton } from '../../components/PrimaryButton';
import { EmptyIndicator } from "../../components/EmptyIndicator";

import {
    Form,
    Table,
    DatePicker,
    // AutoComplete,
    Input,
    Button
} from "antd";
import {
    SearchOutlined
} from '@ant-design/icons'
import Highlighter from 'react-highlight-words';
import parseTime from '../../components/ParseTime';

const defaultFilter = "ALL";



class StatusReport extends React.Component {
    state = {
        dataSource: [],

        // Filter Stuff
        areaFilterString: '',
        modelFilterString: '',
        statusFilterString: '',
        areaFilter: defaultFilter,
        modelFilter: defaultFilter,
        statusFilter: defaultFilter,
        startTime: moment().startOf("month"),
        endTime: moment().add(1, 'day').startOf('day'),
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
        </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
        </Button>
            </div>
        ),
        filterIcon: filtered => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex] && record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={(text && text.toString()) || ``}
                />
            ) : (
                    text
                ),
    });

    msToHours = (ms) => {
        if (!ms) return
        return Number((ms / 1000 / 60 / 60).toFixed(2))
    }

    setUpDataSource = () => {
        const {
            endTime,
            startTime,
        } = this.state;

        const {
            vehicles,
            // vehicleGroups,
        } = this.props;

        const dataSource = Object.values(vehicles.byVID)
            .filter(vehicle => {
                try {

                    if (startTime && startTime.valueOf() > vehicle.createdAt) throw new Error(`startTime`)
                    if (endTime && endTime.valueOf() < vehicle.createdAt) throw new Error(`endTime`)
                    return true
                } catch (err) {
                    return false
                }
            })
            .sort((a, b) => {
                a = a.engineNumber.toLowerCase();
                b = b.engineNumber.toLowerCase();
                return b - a
            })
            .map((vehicle) => {
                const area = this.props.vehicleGroups.byVGID[vehicle.area]
                const outlet = this.props.vehicleGroups.byVGID[vehicle.outlet]

                return {
                    key: vehicle.vid, // Used to identify which vehicle the action would work on
                    engineNumber: vehicle.engineNumber,
                    model: vehicle.vehicleModel,
                    area: area && area.groupName,
                    outlet: outlet && outlet.groupName,
                    accDate: vehicle.pipeLine && vehicle.pipeLine.ACC_startTime,
                    completedDate: vehicle.pipeLine && vehicle.pipeLine.COMPLETED_startTime,
                    allocated: vehicle.pipeLine && vehicle.pipeLine.ALLOCATED_startTime,

                    accToLoaded: vehicle.pipeLine && calculatePipelineDuration(vehicle.pipeLine.ACC_startTime, vehicle.pipeLine.LOADED_startTime),
                    allocatedToCompleted: vehicle.pipeLine && calculatePipelineDuration(vehicle.pipeLine.ALLOCATED_startTime, vehicle.pipeLine.COMPLETED_startTime),
                    allocatedToStaged: vehicle.pipeLine && calculatePipelineDuration(vehicle.pipeLine.ALLOCATED_startTime, vehicle.pipeLine.STAGED_startTime),
                    totalDuration: vehicle.pipeLine && calculatePipelineDuration(vehicle.pipeLine.ACC_startTime, vehicle.pipeLine.LOADED_startTime),

                    accStart: vehicle.pipeLine.ACC_startTime,
                    accEnd: vehicle.pipeLine.ACC_endTime,
                    accDuration: vehicle.pipeLine && calculatePipelineDuration(vehicle.pipeLine.ACC_startTime, vehicle.pipeLine.ACC_endTime),

                    yardStart: vehicle.pipeLine.YARD_startTime,
                    yardEnd: vehicle.pipeLine.YARD_endTime,
                    yardDuration: vehicle.pipeLine && calculatePipelineDuration(vehicle.pipeLine.YARD_startTime, vehicle.pipeLine.YARD_endTime),

                    stagedStart: vehicle.pipeLine.STAGED_startTime,
                    stagedEnd: vehicle.pipeLine.STAGED_endTime,
                    stagedDuration: vehicle.pipeLine && calculatePipelineDuration(vehicle.pipeLine.STAGED_startTime, vehicle.pipeLine.STAGED_endTime),

                    loadedStart: vehicle.pipeLine.LOADED_startTime,
                    // loadedEnd: vehicle.pipeLine.LOADED_endTime,
                    // loadedDuration: vehicle.pipeLine && calculatePipelineDuration(vehicle.pipeLine.LOADED_startTime, vehicle.pipeLine.LOADED_endTime),

                };

                function calculatePipelineDuration(start, end) {
                    if (!start || !end) return
                    return end - start
                }
            });

        const columns = [
            {
                title: "Vehicle",
                dataIndex: "engineNumber",
                fixed: "left",
                ...this.getColumnSearchProps('engineNumber')
            },
            {
                title: "Model",
                dataIndex: "model",
            },
            {
                title: "Area",
                dataIndex: "area",
            },
            {
                title: "Outlet",
                dataIndex: "outlet",
            },
            {
                title: "Allocated At",
                dataIndex: "allocated",
                sorter: (a, b) => b.allocated - a.allocated,
                render: time => parseTime(time)
            },
            {
                title: "ACC At",
                dataIndex: "accDate",
                sorter: (a, b) => a.accDate - b.accDate,
                render: time => parseTime(time),
            },

            {
                title: "COMPLETED At",
                dataIndex: "completedDate",
                sorter: (a, b) => a.completedDate - b.completedDate,
                render: time => parseTime(time),
            },
            {
                title: "Total Duration (ACC At - LOADED At) (hours)",
                dataIndex: "totalDuration",
                sorter: (a, b) => a.totalDuration - b.totalDuration,
                render: duration => this.msToHours(duration)
            },

            {
                title: "ALLOCATED Duration (Allocated At - COMPLETED At) (hours)",
                dataIndex: "allocatedToCompleted",
                sorter: (a, b) => a.allocatedToCompleted - b.allocatedToCompleted,
                render: duration => this.msToHours(duration)
            },

            {
                title: "ALLOCATED to STAGED Duration (Allocated At - STAGED Start At) (hours)",
                dataIndex: "allocatedToStaged",
                sorter: (a, b) => a.allocatedToStaged - b.allocatedToStaged,
                render: duration => this.msToHours(duration)
            },

            {
                title: 'ACC Start At',
                dataIndex: 'accStart',
                sorter: (a, b) => a.accStart - b.accStart,
                render: time => parseTime(time)
            },
            {
                title: 'ACC End At',
                dataIndex: 'accEnd',
                sorter: (a, b) => a.accEnd - b.accEnd,
                render: time => parseTime(time)
            },
            {
                title: 'ACC Duration (hours)',
                dataIndex: 'accDuration',
                sorter: (a, b) => a.accDuration - b.accDuration,
                render: duration => this.msToHours(duration)
            },

            {
                title: 'YARD Start At',
                dataIndex: 'yardStart',
                sorter: (a, b) => a.yardStart - b.yardStart,
                render: time => parseTime(time)
            },
            {
                title: 'YARD End At',
                dataIndex: 'yardEnd',
                sorter: (a, b) => a.yardEnd - b.yardEnd,
                render: time => parseTime(time)
            },
            {
                title: 'YARD Duration (hours)',
                dataIndex: 'yardDuration',
                sorter: (a, b) => a.yardDuration - b.yardDuration,
                render: duration => this.msToHours(duration)
            },

            {
                title: 'STAGED Start At',
                dataIndex: 'stagedStart',
                sorter: (a, b) => a.stagedStart - b.stagedStart,
                render: time => parseTime(time)
            },
            {
                title: 'STAGED End At',
                dataIndex: 'stagedEnd',
                sorter: (a, b) => a.stagedEnd - b.stagedEnd,
                render: time => parseTime(time)
            },
            {
                title: 'STAGED Duration (hours)',
                dataIndex: 'stagedDuration',
                sorter: (a, b) => a.stagedDuration - b.stagedDuration,
                render: duration => this.msToHours(duration)
            },

            {
                title: 'LOADED Start At',
                dataIndex: 'loadedStart',
                sorter: (a, b) => a.loadedStart - b.loadedStart,
                render: time => parseTime(time)
            }
            // {
            //     title: 'LOADED End At',
            //     dataIndex: 'loadedEnd',
            //     sorter: (a, b) => a.loadedEnd - b.loadedEnd,
            //     render: time => parseTime(time)
            // },
            // {
            //     title: 'LOADED Duration (hours)',
            //     dataIndex: 'loadedDuration',
            //     sorter: (a, b) => a.loadedDuration - b.loadedDuration,
            //     render: duration => this.msToHours(duration)
            // }


        ]

        this.setState({
            dataSource,
            columns,
        })
    }

    componentDidMount = () => {
        this.setUpDataSource()
    }

    componentDidUpdate = (prevProps) => {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.setUpDataSource()
        }
    }

    render() {
        const {
            dataSource = [],
            columns = [],
        } = this.state;

        return (
            <div className="page-container">
                <Page title="Vehicle Logistical Process Status Report">
                    <div style={{ display: "flex", padding: 5, marginBottom: 10 }}>
                        <Form layout='inline'>
                            <Form.Item
                                label={`Start time`}
                            >
                                <DatePicker
                                    showTime
                                    defaultValue={this.state.startTime}
                                    onChange={value => this.setState({ startTime: value })}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>

                            <Form.Item
                                label={`End time`}
                            >
                                <DatePicker
                                    showTime
                                    defaultValue={this.state.endTime}
                                    onChange={value => this.setState({ endTime: value })}
                                    style={{ width: 200 }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <PrimaryButton
                                    style={{ marginLeft: '15px' }}
                                    onClick={this.setUpDataSource}
                                    disabled={!((this.state.startTime && this.state.endTime) || this.state.areaFilter || this.state.modelFilter || this.state.statusFilter)}
                                >
                                    Submit
                                </PrimaryButton>

                                <ExportExcelButton
                                    disabled={this.state.dataSource.length === 0}
                                    filename={`Vehicle Logistical Process Status Report ${moment(this.state.startTime).format('YYYY-MM-DD HH:mm:ss')} to ${moment(this.state.endTime).format('YYYY-MM-DD HH:mm:ss')}`}
                                    sheetData={this.state.dataSource}
                                    sheetName='Status Report'
                                    sheetRow={
                                        columns.map(col => {
                                            switch (col.dataIndex) {
                                                case 'accDate':
                                                case 'completedDate':
                                                case 'allocated':
                                                case 'allocatedToCompleted':
                                                case 'allocatedToStaged':
                                                case 'totalDuration':
                                                case 'accStart':
                                                case 'accEnd':
                                                case 'accDuration':
                                                case 'yardStart':
                                                case 'yardEnd':
                                                case 'yardDuration':
                                                case 'stagedStart':
                                                case 'stagedEnd':
                                                case 'stagedDuration':
                                                case 'loadedStart':
                                                case 'loadedEnd':
                                                case 'loadedDuration':
                                                    return {
                                                        label: col.title,
                                                        formatter: value => col.render(value[col.dataIndex])
                                                    }
                                                default:
                                                    return {
                                                        label: col.title,
                                                        formatter: value => value[col.dataIndex]
                                                    }
                                            }
                                        })
                                    }
                                />

                            </Form.Item>
                        </Form>
                    </div>

                    {
                        dataSource.length > 0 ?
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination={true}
                                scroll={{
                                    x: columns && columns.length * 200,
                                    y: window.innerHeight
                                }}
                            /> :
                            <EmptyIndicator />
                    }
                </Page>
            </div>
        )
    };
}

const mapStateToProps = (state) => ({
    devices: state.devices,
    vehicles: state.vehicles,
    vehicleGroups: state.vehicleGroups,
});

export default showSecondarySidebar(false)(connect(mapStateToProps)(StatusReport));