import React, { Component } from "react";
import { connect } from "react-redux";

import { goBackToPrev, moveToPage } from "../../navigation/navigationService";
import {
    List,
    Input,
    Descriptions,
    Tabs,
} from "antd";
import {    
    LeftOutlined
} from '@ant-design/icons'

import "./index.css";
import parseTime from '../../components/ParseTime';
import { DEVICE_STATUS, PROCESS_STATUS } from '../../constants';

// Components
import { LoadingIndicator } from "../../components/custom-components/LoadingIndicator";

class MapDashboard extends Component {
    state = {};

    render() {

        window.props = this.props
        return (
            <div className="page-container">
                <div
                    className="page-header"
                    style={{
                        color: '#FFFFFF',
                        backgroundColor: this.props.style.pageHeaderColor,
                    }}
                >
                    <div className="page-header-contents">
                        {/* back to dashboard */}
                        <button className="transparent-button"
                            onClick={() => {
                                this.props.dispatch(goBackToPrev());
                            }}>
                            <LeftOutlined />
                        </button>

                        <div>Map Dashboard</div>
                    </div>
                </div>

                {/* Search Header */}
                <div
                    className="user-input"
                    style={{ padding: 20 }}
                >
                    <Input
                        placeholder="Search Vehicle by Chassis Number or Engine Number"
                        value={this.state.filterString}
                        onChange={(e) => {
                            this.setState({ filterString: e.target.value })
                        }}
                    />
                </div>

                <div
                    style={{
                        backgroundColor: this.props.style.pageHeaderColor,
                        color: '#FFFFFF',
                        padding: "5px 0px 5px 10px"
                    }}
                >
                    Vehicle List
                </div>

                <div style={{ height: window.innerHeight - 32.8 - 114 - 30.8, overflowY: "auto" }}>

                    <Tabs
                        defaultActiveKey="1"
                        size={'small'}
                        tabBarGutter={1}
                    >
                        {
                            Object.values({
                                ALL: 'ALL',
                                ...PROCESS_STATUS,
                            }).map(status => {

                                const filteredVehicles = 
                                    this.props.vehicles.byLogisticalStatus[status]
                                    .map(vid => this.props.vehicles.byVID[vid])
                                    .filter(vehicle => {
                                        if (!this.state.filterString) return true
                                        if (!vehicle.chassisNumber) return false
                                        
                                        const chassisNumber = (vehicle.chassisNumber && vehicle.chassisNumber.toLowerCase()) || ''
                                        const engineNumber = (vehicle.engineNumber && vehicle.engineNumber.toLowerCase()) || ''

                                        const searchableString = chassisNumber + engineNumber
                                        return searchableString
                                        .includes(this.state.filterString.toLowerCase())
                                    })

                                return (
                                    <Tabs.TabPane
                                        tab={`${status} (${filteredVehicles.length})`}
                                        key={status}
                                    >
                                        {
                                            this.props.style.isLoadingSubmit ?
                                                <LoadingIndicator />
                                            :
                                                (
                                                    <List
                                                        dataSource={
                                                            filteredVehicles
                                                                .sort((a, b) => b.updatedAt - a.updatedAt)
                                                        }
                                                        itemLayout="vertical"
                                                        style={{ backgroundColor: "white" }}
                                                        renderItem={vehicle => {
                                                            const device = this.props.devices.byDVID[vehicle.dvid]

                                                            return (
                                                                <List.Item
                                                                    className="geofence-list-item"
                                                                    style={{ padding: 10 }}
                                                                >
                                                                    <div
                                                                        className="geofence-list-item-content-container"
                                                                        onClick={() => {
                                                                            /**
                                                                            * Opens vehicle info window 
                                                                            * Set center & zoom to vehicle's location
                                                                            */
                                                                            const state = {
                                                                                vid: vehicle.vid,
                                                                                lat: vehicle.location.lat,
                                                                                lng: vehicle.location.lng,
                                                                                zoom: 25,
                                                                            }
                                                                            this.props.dispatch(moveToPage('/', state))
                                                                        }}
                                                                    >
                                                                        <List.Item.Meta
                                                                            className="list-item"
                                                                            description={
                                                                                <Descriptions size={`small`} column={1}>
                                                                                    <Descriptions.Item label="Chassis Number">
                                                                                        {vehicle.chassisNumber}
                                                                                    </Descriptions.Item>
                                                                                    <Descriptions.Item label="Engine Number">
                                                                                        {vehicle.engineNumber}
                                                                                    </Descriptions.Item>
                                                                                    {
                                                                                        device && <Descriptions.Item label="Device IMEI">
                                                                                            {device.dvid}
                                                                                        </Descriptions.Item>
                                                                                    }
                                                                                    {
                                                                                        device &&
                                                                                        <Descriptions.Item label="Device Status">
                                                                                            {
                                                                                                (device && device.deviceStatus === 0 && <b style={{ color: 'red' }}>{DEVICE_STATUS.DISCONNECTED}</b>)
                                                                                                ||
                                                                                                (device && device.deviceStatus === 1 && <b style={{ color: 'green' }}>{DEVICE_STATUS.CONNECTED}</b>)
                                                                                                ||
                                                                                                (device && device.deviceStatus === 2 && <b style={{ color: 'black' }}>{DEVICE_STATUS.TEMPERED}</b>)
                                                                                                || 'N/A'
                                                                                            }
                                                                                        </Descriptions.Item>
                                                                                    }
                                                                                    <Descriptions.Item label="LP Status ">
                                                                                        {vehicle.logisticalStatus}
                                                                                    </Descriptions.Item>
                                                                                    <Descriptions.Item>
                                                                                        Vehicle last updated At: {parseTime(vehicle.updatedAt)}
                                                                                    </Descriptions.Item>
                                                                                    {
                                                                                        device &&
                                                                                        <Descriptions.Item>
                                                                                            Device last updated At: {(device && parseTime(device.updatedAt)) || 'N/A'}
                                                                                        </Descriptions.Item>
                                                                                    }
                                                                                </Descriptions>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </List.Item>
                                                            );
                                                        }}
                                                    />
                                                )
                                        }
                                    </Tabs.TabPane>
                                )
                            })
                        }
                    </Tabs>
                </div>
            </div>
        )
    };
};

const mapStateToProps = (state) => ({
    router: state.router,
    user: state.user,
    style: state.style,
    vehicles: state.vehicles,
    devices: state.devices,
})

export default connect(mapStateToProps)(MapDashboard);
