const config = {
    project: process.env.REACT_APP_PROJECT_MODE,
    version: process.env.REACT_APP_PROJECT_VERSION,
    googleMapAPI: process.env.REACT_APP_GOOGLE_MAP_API,
    api: process.env.REACT_APP_FE_API_GATEWAY,
    wsURL: process.env.REACT_APP_FE_WS_GATEWAY,
    wsReconnectInterval: 3*1000, // millisecond
    firebaseConfig: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
}

module.exports = {
    config
};
