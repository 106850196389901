
import * as API from "../../api/user";
import * as ActionTypes from "../action-types/user";

import { message } from 'antd';

// Redux Actions
import { get_devices_success } from './devices';
import { get_device_groups_success } from './deviceGroups';
import { get_vehicles_success } from './vehicles';
import { get_vehicle_groups_success } from './vehicleGroups';
import { get_geofences_success } from './geofences';
import { get_geofence_templates_success } from './geofenceTemplates';
// import { get_status_log } from './statusLogs';
import { get_geofence_record } from './geofenceReports';
import { subscribeWS } from '../../websocket';

// import { moveToPage } from '../../../navigation/navigationService';
import { 
    setLoadingSubmit, 
    unsetLoadingSubmit 
} from './style';

export const user_sign_in_request = (uid) => (dispatch) => {

    dispatch(setLoadingSubmit())

    API.getUserInfoByUID(uid)
        .then(data => {
            // console.log("user_sign_in_request data:", data);

            if (data.status !== 200) {
                if (data.message) {
                    message.error('Failed To Retrieve User From Database: ' + data.message);
                }
            }
            else {
                // dispatch(get_status_log());
                dispatch(get_geofence_record());
                dispatch(get_user_devices_and_device_groups_by_dgids(data.deviceGroup));
                dispatch(get_user_vehicles_and_vehicle_groups_by_vgids(data.vehicleGroup, uid));
                dispatch(get_user_geofences_and_geofence_templates_by_gtids(data.geofenceTemplate));

                dispatch(user_sign_in_success(data.user));
            }
        })
}

const user_sign_in_success = (user) => {
    return {
        type: ActionTypes.USER_SIGN_IN,
        user
    };
}

export const user_sign_out = () => {
    return {
        type: ActionTypes.USER_SIGN_OUT
    };
}

const get_user_devices_and_device_groups_by_dgids = (dgids) => (dispatch) => {

    API.getUserDevicesAndDeviceGroupsByDGIDs(dgids)
        .then(data => {
            // console.log("get_user_devices_and_device_groups_by_dgids data:", data);

            if (data.status !== 200) {
                if (data.message) {
                    message.error('Failed To Retrieve User Devices From Database: ' + data.message);
                }
            }
            else {
                dispatch(get_devices_success(data.devices));
                dispatch(get_device_groups_success(data.deviceGroups));
            }
        })
}

const get_user_vehicles_and_vehicle_groups_by_vgids = (vgids, uid) => (dispatch) => {
    API.getUserVehiclesAndVehicleGroupsByVGIDs(vgids)
        .then(data => {

            if (data.status !== 200) {
                if (data.message) {
                    message.error('Failed To Retrieve User Vehicles From Database: ' + data.message);
                }
            }
            else {
                const ids = data.vehicles.map(vehicle => vehicle.vid)

                subscribeWS(ids, uid)
                dispatch(get_vehicles_success(data.vehicles));
                dispatch(get_vehicle_groups_success(data.vehicleGroups));
            }
        })
        .finally(() => {
            dispatch(unsetLoadingSubmit())
        })
}

const get_user_geofences_and_geofence_templates_by_gtids = (gtids) => (dispatch) => {
    API.getUserGeofencesAndGeofenceTemplatesByGTIDs(gtids)
        .then(data => {
            // console.log("get_user_geofences_and_geofence_templates_by_gtids data:", data);

            if (data.status !== 200) {
                if (data.message) {
                    message.error('Failed To Retrieve User Geofences From Database: ' + data.message);
                }
            }
            else {
                dispatch(get_geofences_success(data.geofences));
                dispatch(get_geofence_templates_success(data.geofenceTemplates));
            }
        })
}